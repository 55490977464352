@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;

html {
  --body-font-family: 'Roboto', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  --code-font-family: 'Roboto Mono', monospace;
  --gutter: 1rem;
  --sidenav-width: 15rem;
  --sidenav-collapsed-width: 4rem;
}

// Tokens for the app that are based on the current theme.
@mixin theme($theme) {
  $bg-color: mat.get-theme-color($theme,
    if(mat.get-theme-type($theme) == dark, surface-container-high, surface-container));

  --body-background-color: #{$bg-color};
  --link-color: #{mat.get-theme-color($theme, primary)};
  --link-hover-color: #{color.adjust(mat.get-theme-color($theme, primary), $lightness: -10%)};
  --code-border-color: #{rgba(mat.get-theme-color($theme, on-secondary-container), .08)};
  --code-background-color: #{rgba(mat.get-theme-color($theme, on-secondary-container), .04)};
  --header-background-color: #{rgba($bg-color, .8)};
  --user-panel-background-color: #{$bg-color};
  --user-panel-hover-background-color: #{mat.get-theme-color($theme, surface-variant)};
  --sidemenu-heading-hover-background-color: #{mat.get-theme-color($theme, surface-container-highest)};
  --sidemenu-active-heading-text-color: #{mat.get-theme-color($theme, primary)};
  --sidemenu-active-heading-background-color: #{mat.get-theme-color($theme, primary-container)};
  --sidemenu-active-heading-hover-background-color: #{mat.get-theme-color($theme, primary-container)};
  --sidemenu-expanded-background-color: #{$bg-color};
  --topmenu-text-color: #{mat.get-theme-color($theme, on-background)};
  --topmenu-background-color: #{rgba($bg-color, .8)};
  --topmenu-item-active-background-color: #{mat.get-theme-color($theme, primary-container)};
  --topmenu-dropdown-item-active-text-color: #{mat.get-theme-color($theme, primary)};
  --toast-info-background-color: #{map.get(mat.$azure-palette,
    if(mat.get-theme-type($theme) == dark, 30, 50))};
  --toast-success-background-color: #{map.get(mat.$green-palette,
    if(mat.get-theme-type($theme) == dark, 40, 60))};
  --toast-warning-background-color: #{map.get(mat.$orange-palette,
    if(mat.get-theme-type($theme) == dark, 40, 60))};
  --toast-error-background-color: #{map.get(mat.$red-palette,
    if(mat.get-theme-type($theme) == dark, 30, 50))};
  --mdc-icon-button-icon-color: #{rgb(255, 255, 255)};
  --mat-sidenav-container-width: 240px;
  --mat-card-title-text-weight: 500;
  --mat-mdc-focus-indicator-border-radius: 0;
}
