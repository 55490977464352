@use '@angular/material' as mat;
@use '../mixins/badge';
@use '../mixins/breakpoints';
@use '../variables';
// @use '../../../styles/themes.scss';

@include mat.core();
// ==================================================
// SIDENAV
// ==================================================

@mixin matero-sidenav-theme($theme) {
  $background: mat-color($theme, 'background');
  $foreground: mat-color($theme, 'foreground');

  .matero-sidenav {
    .matero-sidemenu {
      a {
        color: mat-color($foreground, text);
      }

      .matero-sidemenu-item {
        &:hover {
          background: mat-color($background, hover);
        }

        &.open {
          background: rgba(0, 0, 0, .02);
        }

        &.active {
          > a {
            color: mat-color(map-get($theme, accent));
          }
        }
      }
    }

    .matero-sidebar-header {
      color: mat-color($foreground, text);
      background: mat-color($background, app-bar);
      border-bottom: 1px solid variables.$boder-color;
    }
  }
}

.matero-sidenav {
  $sidenav-width: 240px !default;

  position: absolute;
  overflow-x: hidden;
  transition: swift-ease-out(width); // Only set width property

  @include mat.elevation(6);

  &.mat-drawer-side {
    border-width: 0;
  }

  .matero-sidemenu {
    width: $sidenav-width;
    margin: 0;
    padding: 0;
    list-style: none;

    .matero-sidemenu-item {
      display: block;
      height: auto;
      padding: 0;

      &.open {
        > .matero-sidemenu {
          max-height: 2000px;
        }

        > .matero-sidemenu-toggle > .menu-caret {
          transform: rotate(-180deg);
        }
      }
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      padding: 0 16px;
      text-decoration: none;
    }

    .menu-icon {
      width: 18px;
      height: 18px;
      margin-right: 16px;
      font-size: 18px;
      line-height: 18px;

      [dir='rtl'] & {
        margin-left: 16px;
        margin-right: auto;
      }
    }

    .menu-name,
    .menu-label,
    .menu-badge {
      transition: swift-ease-out(opacity);
    }

    .menu-label,
    .menu-badge {
      @include badge.badge();
    }

    .menu-badge {
      border-radius: 10px;
    }

    .menu-caret {
      display: block;
      text-align: center;
      transition: fast-out-slow(transform);
    }

    &.level-0 > li > a > .menu-name {
      margin-right: 5px;

      [dir='rtl'] & {
        margin-left: 5px;
        margin-right: auto;
      }
    }

    &.level-1 > li > a {
      padding-left: 50px;

      [dir='rtl'] & {
        padding-left: 16px;
        padding-right: 50px;
      }
    }

    &.level-2 > li > a {
      padding-left: 64px;

      [dir='rtl'] & {
        padding-left: 16px;
        padding-right: 64px;
      }
    }

    &.submenu {
      max-height: 0;
      padding-top: 0;
      overflow: hidden;
      transform: translateZ(0) !important;
      transition: fast-out-slow(max-height);

      .matero-sidemenu-item {
        border-left-width: 0;
      }
    }
  }

  .matero-sidebar-header {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: variables.$toolbar-height-desktop;
    padding: 0 10px;
    overflow: hidden;

    @include breakpoints.bp-lt(small) {
      & {
        height: variables.$toolbar-height-mobile;
      }
    }

    // Colorful
    .matero-header-white & {
      background-color: white;
    }

    mat-toolbar {
      width: var(--sidenav-width);
      padding: 0 0.5rem;
    }

    // Remove redundant padding in the slide toggle
    mat-slide-toggle .mdc-label {
      display: none;
    }
  }

  .matero-sidebar-main {
    height: calc(100% - var(--mat-toolbar-standard-height));
    padding: 0 0.5rem 0.5rem;
    overflow: auto;
    scrollbar-width: none;

    @include breakpoints.bp-lt(small) {
      & {
        height: calc(100% - var(--mat-toolbar-mobile-height));
      }
    }
  }
}

// User panel
.matero-user-panel {
  padding: 16px 0;
  border-bottom: 1px solid variables.$boder-color;
}

.matero-user-panel-avatar {
  margin-bottom: 8px;
  // Set default width and height can avoid flashing before avatar image loaded.
  width: 64px;
  height: 64px;
  border-radius: 999px;
  transition: swift-ease-out(transform);
}

.matero-user-panel-name,
.matero-user-panel-email {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: normal;
}

.matero-user-panel-name,
.matero-user-panel-email,
.matero-user-panel-icons {
  opacity: 1;
  transition: swift-ease-out(opacity);
}

.matero-user-panel-icons {
  white-space: nowrap;

  .mat-icon-button {
    width: 24px;
    height: 24px;
    line-height: 24px;

    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

// Layout control
.matero-sidenav-collapsed,
.matero-sidenav-collapsed-fix {
  .matero-sidenav {
    width: variables.$sidenav-collapsed-width;

    .matero-branding,
    .menu-name,
    .menu-label,
    .menu-badge,
    .menu-caret,
    .matero-user-panel-name,
    .matero-user-panel-email,
    .matero-user-panel-icons {
      opacity: 0;
    }

    .matero-user-panel-avatar {
      transform: scale(.5);
    }

    &:hover {
      width: variables.$sidenav-width;

      .matero-branding,
      .menu-name,
      .menu-label,
      .menu-badge,
      .menu-caret,
      .matero-user-panel-name,
      .matero-user-panel-email,
      .matero-user-panel-icons {
        opacity: 1;
      }

      .matero-user-panel-avatar {
        transform: scale(1);
      }
    }
  }
}

.matero-sidenav-collapsed {
  .matero-content-wrap {
    margin-left: variables.$sidenav-collapsed-width !important;

    [dir='rtl'] & {
      margin-left: auto !important;
      margin-right: variables.$sidenav-collapsed-width !important;
    }
  }

  &[dir='rtl'] .matero-content-wrap {
    margin-left: auto !important;
    margin-right: variables.$sidenav-collapsed-width !important;
  }
}
