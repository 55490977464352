@use '@angular/material' as mat;

$mat-colors: (
  red: mat.$m2-red-palette,
  pink: mat.$m2-pink-palette,
  purple: mat.$m2-purple-palette,
  deep-purple: mat.$m2-deep-purple-palette,
  indigo: mat.$m2-indigo-palette,
  blue: mat.$m2-blue-palette,
  light-blue: mat.$m2-light-blue-palette,
  cyan: mat.$m2-cyan-palette,
  teal: mat.$m2-teal-palette,
  green: mat.$m2-green-palette,
  light-green: mat.$m2-light-green-palette,
  lime: mat.$m2-lime-palette,
  yellow: mat.$m2-yellow-palette,
  amber: mat.$m2-amber-palette,
  orange: mat.$m2-orange-palette,
  deep-orange: mat.$m2-deep-orange-palette,
  brown: mat.$m2-brown-palette,
  gray: mat.$m2-gray-palette,
  blue-gray: mat.$m2-blue-gray-palette,
  white: white,
  black: black,
  light: rgba(white, .87),
  dark: rgba(black, .87),
);

@mixin generate-color($prefix, $property) {
  @each $name, $value in $mat-colors {
    // If the value is a map, continue to each
    @if type-of($value) == 'map' {
      @each $hue, $color in $value {
        @if ($hue != 'contrast') {
          .#{$prefix + '-' + $name + '-' + $hue} {
            #{$property}: $color !important;
          }
        }
        // alias
        @if ($name == 'gray') {
          .#{$prefix + '-grey-' + $hue} {
            @extend .#{$prefix + '-gray-' + $hue} !optional;
          }
        }

        @if ($name == 'blue-gray') {
          .#{$prefix + '-blue-grey-' + $hue} {
            @extend .#{$prefix + '-blue-gray-' + $hue} !optional;
          }
        }
      }
    }

    @if type-of($value) == 'color' {
      .#{$prefix + '-' + $name} {
        #{$property}: $value !important;
      }
    }
  }
}

@include generate-color('text', 'color');
@include generate-color('bg', 'background-color');
