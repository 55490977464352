@use '@angular/material' as mat;
@use '../mixins/badge';
@use '../mixins/breakpoints';
@use '../variables';

@include mat.core();
// ==================================================
// TOPNAV
// ==================================================

@mixin matero-topnav-theme($theme) {
  $background: mat-color($theme, 'background');
  $foreground: mat-color($theme, 'foreground');

  .matero-topmenu {
    background: mat-color($background, app-bar);
    color: mat-color($foreground, text);
  }
}

.matero-topmenu {
  position: sticky;
  z-index: 200;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include mat.elevation(2);

  .menu-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    font-size: 18px;
    line-height: 18px;

    [dir='rtl'] & {
      margin-left: 8px;
      margin-right: auto;
    }
  }

  .menu-label,
  .menu-badge {
    margin-left: 8px;
    font-size: 12px;

    [dir='rtl'] & {
      margin-left: auto;
      margin-right: 8px;
    }
  }

  .menu-badge {
    border-radius: 10px;
  }

  .menu-caret {
    margin-right: -8px;

    [dir='rtl'] & {
      margin-left: -8px;
      margin-right: auto;
    }
  }
}

.mat-menu-item {
  .menu-name {
    margin-right: 8px;
    vertical-align: middle;

    [dir='rtl'] & {
      margin-left: 8px;
      margin-right: auto;
    }
  }
}

.matero-topmenu-inner {
  padding: 8px;
  white-space: nowrap;
}

// Layout control
.matero-navbar-top {
  .matero-topmenu {
    top: 0;
  }

  .matero-branding {
    margin-left: 16px;

    [dir='rtl'] & {
      margin-left: auto;
      margin-right: 16px;
    }
  }
}

// Layout control
.matero-header-fixed {
  .matero-topmenu {
    top: variables.$topmenu-sticky-position-desktop;

    @include breakpoints.bp-lt(small) {
      & {
        top: variables.$topmenu-sticky-position-mobile;
      }
    }
  }
}
