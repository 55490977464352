@use '@angular/material' as mat;

@include mat.core();

// ==================================================
// HEADER
// ==================================================

.matero-header {
  position: relative;
  z-index: 201;
  display: block;

  .matero-toolbar {
    padding: 0 8px;
  }

  // Layout control
  .matero-header-fixed & {
    position: sticky;
    top: 0;
  }
}

.matero-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, .12);

  .matero-navbar-side.matero-header-fixed & {
    border-bottom: unset;

    @include mat.elevation(6);
  }

  .badge {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 12px;
    border-radius: 10px;
  }
}

// Colorful
.matero-header-white {
  .matero-toolbar,
  .matero-topmenu {
    background-color: white;
  }
}

// Colorful
.matero-header-primary {
  .matero-toolbar {
    background-color: #3f51b5;
    color: white;
  }
  .matero-topmenu {
    background-color: white;
  }
}

// Customize toolbar button
.matero-toolbar-button {
  color: inherit;

  &.mat-button,
  &.mat-icon-button {
    margin: 0 4px;
  }

  &.mat-button {
    min-width: unset;
    line-height: 40px;
    border-radius: 999px;
  }

  &.matero-avatar-button {
    display: block;
    padding: 0 4px;
    font-size: 0;

    .matero-avatar {
      width: 32px;
      height: 32px;
      border-radius: 999px;
    }

    .matero-username {
      margin: 0 8px;
      font-size: 14px;
      padding-top: 4px;
    }
  }
}

@mixin matero-toolbar-button-theme($theme) {
  $background: mat-color($theme, 'background');

  .matero-toolbar-button {
    &.mat-icon-button {
      &:hover {
        background-color: mat-color($background, hover);
      }
    }
  }
}
