*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  position: relative; // 1
  height: 100%;
  overflow: auto; // 2
  background-color: var(--body-background-color);
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

code,
kbd,
pre,
samp {
  font-family: var(--code-font-family);
}

code {
  padding: 0.125rem 0.25rem;
  font-size: 80%;
  word-break: break-word;
  background-color: var(--code-background-color);
  border: 1px solid var(--code-border-color);
  border-radius: 0.25rem;
}

a {
  color: var(--link-color);

  &:hover {
    color: var(--link-hover-color);
  }
}

@media (width <=720px) {
  .hide-small {
    display: none !important;
  }

  .show-small {
    display: block !important;
  }
}

app-user-signbox .mdc-button__label {
  color: white;
  display: inline-flex;
  vertical-align: middle;
}

app-user .mdc-button__label {
  color: white;
  display: inline-flex;
  vertical-align: middle;
}

app-anonymous .mdc-button__label {
  color: white;
  display: inline-flex;
  vertical-align: middle;
}

app-user-panel .mdc-button__label {
  color: black;
}

app-user-panel {
  --mdc-icon-button-icon-color: #44474E;
  --mdc-icon-button-state-layer-size: 28px;
}

.matero-sidenav .matero-sidemenu a {
  color: rgba(0, 0, 0, .87);
}

.matero-sidenav .matero-sidemenu .matero-sidemenu-item.active>a {
  color: #448aff;
}

.matero-sidebar-header {
  background-color: #f5f5f5;
}

.matero-sidenav .matero-sidebar-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

app-sidebar {
  --mat-sidenav-container-width: 240px;

  mat-slide-toggle {
    display: inline-block;
    height: 24px;
    max-width: 100%;
    line-height: 24px;
    white-space: nowrap;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  .mat-slide-toggle-label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    height: inherit;
    cursor: pointer;
  }
}

.mat-mdc-card-title {
  font-size: 24px;
  font-weight: 500;
  padding: 16px;
  padding-bottom: 0px;
}

.text-center {
  padding: 16px;
  padding-bottom: 0;
}

.mdc-list {
  margin: 0;
  padding: 8px 0 0 !important;
  list-style-type: none;
}

.mat-divider.mat-divider-inset {
  margin-left: 0px !important;
}

app-user-profile-layout {
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-tracking: 0;
  --mdc-elevated-card-container-shape: 0;

  .mat-divider {
    border-top-color: rgba(0, 0, 0, .25) !important;
  }

  .mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px !important;
  }

  .mdc-list-item__primary-text {
    display: flex;
  }

  .mdc-list-item__content {
    margin-top: 5px;
  }
}

.guest-login.mat-mdc-raised-button {
    --mdc-protected-button-container-color: #fff !important;
    --mdc-protected-button-label-text-color: #44474E !important;
}

.mat-mdc-raised-button {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #ffffff;
}

.mat-mdc-header-row {
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-color: #44474E;
  --mat-table-row-item-outline-color: #dddddd
}

mtx-grid-cell {
  .mat-primary.mat-mdc-button-base{
    --mdc-icon-button-icon-color: #3f51b5 !important;;
  }
}

lib-esign-user-sign-visualization{
  .mat-mdc-card {
    padding: 16px;
  }
}

.mat-mdc-card-content {
  font-size: 14px;
}

lib-esign-common-avatar-profile {
  --mdc-filled-text-field-label-text-size: 14px;

  .mdc-text-field--filled {
    height: 48px !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 48px !important;
  }
}

lib-esign-user-sign-visualization {
  --mdc-filled-text-field-label-text-size: 14px;

  .mdc-text-field--filled {
    height: 48px !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 48px !important;
  }
}

.mdc-text-field--filled.mdc-text-field--disabled {
  --mdc-filled-text-field-disabled-container-color: inherit;
  background-color: inherit !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  --mdc-filled-text-field-disabled-container-color: inherit;
  background-color: inherit !important;
}

lib-esign-user-otp-add {
  --mdc-outlined-button-label-text-color: #fff;
  --mdc-filled-text-field-container-color: inherit;
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-form-field-subscript-text-size: 11px;

  .mat-mdc-card-actions {
    display:grid;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 8px;
  }
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
  padding: 0px !important;
}

.mdc-text-field--filled {
  height: 48px !important;
}

.mat-mdc-text-field-wrapper {
  height: 48px !important;
}

.mat-mdc-snack-bar-label {
  font-weight: bold !important;
  text-align: center;
}

.mat-mdc-snack-bar-container {
  &.snack-success {
    --mdc-snackbar-container-color: #388638;
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;
  }
}

.mat-mdc-snack-bar-container {
  &.snack-error {
    --mdc-snackbar-container-color: #F76666;
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;
  }
}

.mat-mdc-dialog-surface{
  padding: 16px 16px 16px
}

lib-esign-table-search {
  --mdc-protected-button-container-color: inherit;
  --mat-dialog-container-max-width: 600px;

  .cdk-overlay-pane.mat-mdc-dialog-panel{
    max-width: 600px !important;
  }

  .mat-mdc-raised-button:not(:disabled) {
    color: inherit !important;
    background-color: inherit !important;
  }

  .mat-mdc-dialog-container{
    min-width: 600px;
  }

  .mdc-floating-label {
    font-size: 12px;
  }

  .mat-mdc-floating-label {
    font-size: 12px;
  }

  .cdk-overlay-pane.mat-mdc-dialog-panel {
    min-width: 565px !important;
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mat-mdc-form-field {
    font-size: 14px;
  }

  .mdc-text-field--filled {
    font-size: 12px;
  }
}

lib-esign-common-certificate-details {
  hr {
    width: 100%
  }

  .mat-mdc-card {
    padding: 16px;
  }
}

lib-esign-data-revoke-certificate {
  --mdc-outlined-button-label-text-color: #fff;
}

lib-esign-table {
  --mat-table-row-item-label-text-size: 12px;

  .mat-mdc-card{
    padding: 16px;
  }

  .mat-mdc-header-row {
    color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-cell {
    border-bottom-color: rgba(0, 0, 0, .2) !important;
  }
}

.mat-mdc-table.mat-table-striped .mat-row-odd {
  --mtx-grid-table-row-striped-background-color: #efedf0;
  background-color: #efedf0;
}

.odd-row {
  background-color: #efedf0 !important;
}

.mat-step-icon-selected {
  background-color: #3f51b5 !important;
}

lib-esign-csr-stepper {
  lib-esign-csr-step-first {
    p {
      margin-top: 0;
    }

    mat-tab-header {
      margin-bottom: 16px;
    }

    .mat-mdc-tab .mdc-tab__text-label {
      color: rgba(0,0,0,.50) !important;
    }

    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label{
      color: black !important;
    }

    .mat-mdc-tab-body-content {
      display: inline-grid;
      width: 100%;
    }
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  .mat-mdc-card-actions{
    display: block !important;
    text-align: end !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
    color: #005cbb !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-text-field--outlined .mdc-floating-label {
    font-size: 14px !important;
  }

  input {
    font-size: 14px !important;
  }

  .mat-mdc-tab-labels {
    min-width: 160px;
    max-width: 160px;
    display: flex;
  }

  --mat-form-field-container-text-size: 14px;

  .mat-mdc-tab-body {
    margin-top: 5px;
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    height: 25px;
  }

  lib-esign-csr-step-second {
    .mat-mdc-tab .mdc-tab__text-label {
      color: rgba(0,0,0,.50) !important;
    }

    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label{
      color: black !important;
    }

    .mat-mdc-form-field{
      padding: 5px 0;
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
      margin-left: 16px;
    }

    .mat-mdc-icon-button {
      color: black;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 2px 8px 2px;
    }
  }

  lib-esign-csr-step-fourth {
    .mat-mdc-form-field {
      width: 100%;
    }
  }

  lib-esign-csr-step-third {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }
  }
}

lib-esign-user-otp {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff
  }

  .mat-mdc-option .mdc-list-item__primary-text {
    font-size: 14px;
  }

  .mat-mdc-select-value-text {
    font-size: 14px;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    font-size: 14px !important;
  }
}

lib-esign-data-show-document {
  .mat-mdc-card-title {
    padding-left: 0;
    font-size: 20px;
  }

  .mat-mdc-card-subtitle{
    font-size: 14px;
    color: rgba(0,0,0,.50);
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-data-confirm-delete {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-common-verify-documents {
  --mdc-radio-selected-icon-color: #448aff;

  .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #448aff;
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

.mat-sidenav {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
}

lib-esign-common-sign-documents {
  --mdc-radio-selected-icon-color: #448aff;

  .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #448aff;
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }

  .mat-mdc-select-value {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-option .mdc-list-item__primary-text {
    font-size: 14px !important;
  }

  .mat-mdc-option.mdc-list-item {
    font-size: 14px;
  }
}

lib-esign-doc-config-visualization {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }

  .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #448aff;
  }
}

.add-documents {
  .mat-mdc-dialog-surface {
    overflow-y: inherit !important;
  }
}

.config-visualization {
  .mdc-text-field__input{
    font-size: 14px;
    text-align: center;
  }

  .mat-mdc-select-value {
    font-size: 14px;
  }

  .mat-mdc-input-element{
    font-size: 14px !important;
  }
}

lib-esign-doc-config-visualization-confirm-exit {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-doc-config-visualization-size {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-order-viewer {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }

  .mat-mdc-raised-button:not(:disabled){
    color: #fff;
  }
}

lib-esign-document-viewer {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }

  .mat-mdc-raised-button:not(:disabled){
    color: #fff;
  }
}

lib-esign-config-visualization-photo {
  .mat-mdc-outlined-button:not(:disabled){
    color: #fff;
  }
}

lib-signbox-order-details-data {
  .mat-mdc-card {
    padding: 16px !important;
  }
}

lib-esign-verify-stepper {
  .mat-mdc-card-content {
    width: 100% !important;
  }

  .mat-mdc-card-actions {
    justify-content: end !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  .mat-mdc-card {
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  lib-esign-verify-step-second {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }

    .sign-card {
      align-items: baseline !important;
    }
  }
}

lib-esign-verify-document-viewer {
  .mat-mdc-card-title {
    padding-left: 0;
    font-size: 20px;
  }

  .mat-mdc-card-subtitle{
    font-size: 14px;
    color: rgba(0,0,0,.50);
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-qualified-stepper {
  .mat-mdc-card-content {
    width: 100% !important;
  }

  .mat-mdc-card-actions {
    justify-content: end !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  .mat-mdc-card {
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  lib-esign-qualified-step-second {
    .btn-choose {
      border-radius: 4px !important;
    }
  }

  lib-esign-qualified-step-third {
    .mat-mdc-icon-button {
      color: black;
    }

    .mat-mdc-form-field {
      margin-bottom: 8px;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 2px 8px 2px;
    }
  }

  lib-esign-qualified-step-fourth {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }

    .sign-card {
      align-items: baseline !important;
    }
  }
}

lib-esign-qualified-sign-document-viewer {
  .mat-mdc-card-title {
    padding-left: 0;
    font-size: 20px;
  }

  .mat-mdc-card-subtitle{
    font-size: 14px;
    color: rgba(0,0,0,.50);
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-seal-document-viewer {
  .mat-mdc-card-title {
    padding-left: 0;
    font-size: 20px;
  }

  .mat-mdc-card-subtitle{
    font-size: 14px;
    color: rgba(0,0,0,.50);
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-nonqualified-seal-stepper {
  .mat-mdc-card-content {
    width: 100% !important;
  }

  .mat-mdc-card-actions {
    justify-content: end !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  .mat-mdc-card {
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  lib-esign-nonqualified-seal-step-second {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }

    .sign-card {
      align-items: baseline !important;
    }
  }
}

lib-esign-sign-trusted-inner-document-viewer {
  .mat-mdc-card-title {
    padding-left: 0;
    font-size: 20px;
  }

  .mat-mdc-card-subtitle{
    font-size: 14px;
    color: rgba(0,0,0,.50);
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-choose-seal-trusted-inner-stepper {
  .mat-mdc-card-content {
    width: 100% !important;
  }

  .mat-mdc-card-actions {
    justify-content: end !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  .mat-mdc-card {
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  lib-esign-choose-seal-trusted-inner-step-second {
    .btn-choose {
      border-radius: 8px;
    }
  }

  lib-esign-choose-seal-trusted-inner-step-fourth {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }

    .sign-card {
      align-items: baseline !important;
    }
  }
}

lib-esign-trusted-inner-stepper {
  .mat-mdc-card-content {
    width: 100% !important;
  }

  .mat-mdc-card-actions {
    justify-content: end !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  .mat-mdc-card {
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  lib-esign-trusted-inner-step-third {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }

    .sign-card {
      align-items: baseline !important;
    }
  }
}

lib-esign-sign-sign-document-viewer {
  .mat-mdc-card-title {
    padding-left: 0;
    font-size: 20px;
  }

  .mat-mdc-card-subtitle{
    font-size: 14px;
    color: rgba(0,0,0,.50);
  }

  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-data-show-visualization {
  .mat-mdc-outlined-button:not(:disabled) {
    color: #fff;
  }
}

lib-esign-sign-stepper {
  .mat-mdc-card-content {
    width: 100% !important;
  }

  .mat-mdc-card-actions {
    justify-content: end !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  .mat-mdc-card {
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  lib-esign-sign-step-second {
    .mat-mdc-icon-button {
      color: black;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 2px 8px 2px;
    }
  }

  lib-esign-sign-step-third {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }

    .sign-card {
      align-items: baseline !important;
    }
  }
}

lib-esign-data-confirm-create-new-seal {
  .mat-mdc-outlined-button:not(:disabled){
    color: #fff;
  }
}

lib-esign-settings-sign-visualization {
  .mat-mdc-card {
    padding: 16px !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    font-size: 14px;
  }
}

lib-esign-data-certificate-seal-data {
  .buttons {
    padding-bottom: 12px;
  }
}

lib-esign-qualified-seal-stepper {
  .mat-mdc-card-content {
    width: 100% !important;
  }

  .mat-mdc-card-actions {
    justify-content: end !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    font-size: 14px;
    letter-spacing: normal;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  .mat-mdc-card {
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    font-size: 14px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0,0,0,.50) !important;
  }

  .mdc-linear-progress__bar-inner{
    border-color: #3f51b5 !important;
  }

  lib-esign-qualified-seal-step-second {
    .mat-mdc-icon-button {
      color: black;
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 2px 8px 2px;
    }
  }

  lib-esign-qualified-seal-step-third {
    .mat-mdc-card-title {
      padding-left: 0;
      font-size: 20px;
    }

    .mat-mdc-card-subtitle{
      font-size: 14px;
      color: rgba(0,0,0,.50);
    }

    .sign-card {
      align-items: baseline !important;
    }
  }
}