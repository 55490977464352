@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use 'app-theme';
@use '../theme/style/theming.scss';

@include mat.core();

$light-config: (
  color: (
    theme-type: 'light',
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
);

$light-theme: mat.private-deep-merge-all(
  mat.define-theme($light-config),
  mtx.define-theme($light-config)
);

html {
  @include mat.all-component-themes($light-theme);
  @include mtx.all-component-themes($light-theme);
  @include app-theme.theme($light-theme);

  @include mat.color-variants-backwards-compatibility($light-theme);
  @include mtx.color-variants-backwards-compatibility($light-theme);
}

$dark-config: (
  color: (
    theme-type: 'dark',
    primary: mat.$cyan-palette,
    tertiary: mat.$orange-palette,
  ),
);

$dark-theme: mat.private-deep-merge-all(
  mat.define-theme($dark-config),
  mtx.define-theme($dark-config)
);

.theme-dark {
  color-scheme: dark;

  @include mat.all-component-colors($dark-theme);
  @include mtx.all-component-colors($dark-theme);
  @include app-theme.theme($dark-theme);

  @include mat.color-variants-backwards-compatibility($dark-theme);
  @include mtx.color-variants-backwards-compatibility($dark-theme);
}

//
// Light style
//
// Define your application's custom theme.
// $primary:  mat.m2-define-palette(mat.$m2-indigo-palette);
// $accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);
// $warn: mat.m2-define-palette(mat.$m2-red-palette);
// $theme: mat.m2-define-light-theme($primary, $accent, $warn);

// $config: mat.m2-define-typography-config();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($theme);

// @include theming.matero-admin-theme($theme);
