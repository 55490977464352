*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  height: 100%;
  overflow: auto;
  background-color: var(--body-background-color);
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

code,
kbd,
pre,
samp {
  font-family: var(--code-font-family);
}

code {
  padding: 0.125rem 0.25rem;
  font-size: 80%;
  word-break: break-word;
  background-color: var(--code-background-color);
  border: 1px solid var(--code-border-color);
  border-radius: 0.25rem;
}

a {
  color: var(--link-color);
}
a:hover {
  color: var(--link-hover-color);
}

@media (width <= 720px) {
  .hide-small {
    display: none !important;
  }
  .show-small {
    display: block !important;
  }
}
app-user-signbox .mdc-button__label {
  color: white;
  display: inline-flex;
  vertical-align: middle;
}

app-user .mdc-button__label {
  color: white;
  display: inline-flex;
  vertical-align: middle;
}

app-anonymous .mdc-button__label {
  color: white;
  display: inline-flex;
  vertical-align: middle;
}

app-user-panel .mdc-button__label {
  color: black;
}

app-user-panel {
  --mdc-icon-button-icon-color: #44474E;
  --mdc-icon-button-state-layer-size: 28px;
}

.matero-sidenav .matero-sidemenu a {
  color: rgba(0, 0, 0, 0.87);
}

.matero-sidenav .matero-sidemenu .matero-sidemenu-item.active > a {
  color: #448aff;
}

.matero-sidebar-header {
  background-color: #f5f5f5;
}

.matero-sidenav .matero-sidebar-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

app-sidebar {
  --mat-sidenav-container-width: 240px;
}
app-sidebar mat-slide-toggle {
  display: inline-block;
  height: 24px;
  max-width: 100%;
  line-height: 24px;
  white-space: nowrap;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
app-sidebar .mat-slide-toggle-label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: inherit;
  cursor: pointer;
}

.mat-mdc-card-title {
  font-size: 24px;
  font-weight: 500;
  padding: 16px;
  padding-bottom: 0px;
}

.text-center {
  padding: 16px;
  padding-bottom: 0;
}

.mdc-list {
  margin: 0;
  padding: 8px 0 0 !important;
  list-style-type: none;
}

.mat-divider.mat-divider-inset {
  margin-left: 0px !important;
}

app-user-profile-layout {
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-tracking: 0;
  --mdc-elevated-card-container-shape: 0;
}
app-user-profile-layout .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.25) !important;
}
app-user-profile-layout .mat-icon {
  width: 20px;
  height: 20px;
  font-size: 20px !important;
}
app-user-profile-layout .mdc-list-item__primary-text {
  display: flex;
}
app-user-profile-layout .mdc-list-item__content {
  margin-top: 5px;
}

.guest-login.mat-mdc-raised-button {
  --mdc-protected-button-container-color: #fff !important;
  --mdc-protected-button-label-text-color: #44474E !important;
}

.mat-mdc-raised-button {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #ffffff;
}

.mat-mdc-header-row {
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-color: #44474E;
  --mat-table-row-item-outline-color: #dddddd ;
}

mtx-grid-cell .mat-primary.mat-mdc-button-base {
  --mdc-icon-button-icon-color: #3f51b5 !important;
}

lib-esign-user-sign-visualization .mat-mdc-card {
  padding: 16px;
}

.mat-mdc-card-content {
  font-size: 14px;
}

lib-esign-common-avatar-profile {
  --mdc-filled-text-field-label-text-size: 14px;
}
lib-esign-common-avatar-profile .mdc-text-field--filled {
  height: 48px !important;
}
lib-esign-common-avatar-profile .mat-mdc-text-field-wrapper {
  height: 48px !important;
}

lib-esign-user-sign-visualization {
  --mdc-filled-text-field-label-text-size: 14px;
}
lib-esign-user-sign-visualization .mdc-text-field--filled {
  height: 48px !important;
}
lib-esign-user-sign-visualization .mat-mdc-text-field-wrapper {
  height: 48px !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  --mdc-filled-text-field-disabled-container-color: inherit;
  background-color: inherit !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-disabled-container-color: inherit;
  background-color: inherit !important;
}

lib-esign-user-otp-add {
  --mdc-outlined-button-label-text-color: #fff;
  --mdc-filled-text-field-container-color: inherit;
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-form-field-subscript-text-size: 11px;
}
lib-esign-user-otp-add .mat-mdc-card-actions {
  display: grid;
}
lib-esign-user-otp-add .mat-mdc-text-field-wrapper {
  padding: 0 8px;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.mdc-text-field--filled {
  height: 48px !important;
}

.mat-mdc-text-field-wrapper {
  height: 48px !important;
}

.mat-mdc-snack-bar-label {
  font-weight: bold !important;
  text-align: center;
}

.mat-mdc-snack-bar-container.snack-success {
  --mdc-snackbar-container-color: #388638;
  --mat-mdc-snack-bar-button-color: #000;
  --mdc-snackbar-supporting-text-color: #000;
}

.mat-mdc-snack-bar-container.snack-error {
  --mdc-snackbar-container-color: #F76666;
  --mat-mdc-snack-bar-button-color: #000;
  --mdc-snackbar-supporting-text-color: #000;
}

.mat-mdc-dialog-surface {
  padding: 16px 16px 16px;
}

lib-esign-table-search {
  --mdc-protected-button-container-color: inherit;
  --mat-dialog-container-max-width: 600px;
}
lib-esign-table-search .cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: 600px !important;
}
lib-esign-table-search .mat-mdc-raised-button:not(:disabled) {
  color: inherit !important;
  background-color: inherit !important;
}
lib-esign-table-search .mat-mdc-dialog-container {
  min-width: 600px;
}
lib-esign-table-search .mdc-floating-label {
  font-size: 12px;
}
lib-esign-table-search .mat-mdc-floating-label {
  font-size: 12px;
}
lib-esign-table-search .cdk-overlay-pane.mat-mdc-dialog-panel {
  min-width: 565px !important;
}
lib-esign-table-search .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-table-search .mat-mdc-form-field {
  font-size: 14px;
}
lib-esign-table-search .mdc-text-field--filled {
  font-size: 12px;
}

lib-esign-common-certificate-details hr {
  width: 100%;
}
lib-esign-common-certificate-details .mat-mdc-card {
  padding: 16px;
}

lib-esign-data-revoke-certificate {
  --mdc-outlined-button-label-text-color: #fff;
}

lib-esign-table {
  --mat-table-row-item-label-text-size: 12px;
}
lib-esign-table .mat-mdc-card {
  padding: 16px;
}
lib-esign-table .mat-mdc-header-row {
  color: rgba(0, 0, 0, 0.54);
}
lib-esign-table .mat-mdc-cell {
  border-bottom-color: rgba(0, 0, 0, 0.2) !important;
}

.mat-mdc-table.mat-table-striped .mat-row-odd {
  --mtx-grid-table-row-striped-background-color: #efedf0;
  background-color: #efedf0;
}

.odd-row {
  background-color: #efedf0 !important;
}

.mat-step-icon-selected {
  background-color: #3f51b5 !important;
}

lib-esign-csr-stepper {
  --mat-form-field-container-text-size: 14px;
}
lib-esign-csr-stepper lib-esign-csr-step-first p {
  margin-top: 0;
}
lib-esign-csr-stepper lib-esign-csr-step-first mat-tab-header {
  margin-bottom: 16px;
}
lib-esign-csr-stepper lib-esign-csr-step-first .mat-mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-csr-stepper lib-esign-csr-step-first .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: black !important;
}
lib-esign-csr-stepper lib-esign-csr-step-first .mat-mdc-tab-body-content {
  display: inline-grid;
  width: 100%;
}
lib-esign-csr-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-csr-stepper .mat-mdc-card-actions {
  display: block !important;
  text-align: end !important;
}
lib-esign-csr-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
  color: #005cbb !important;
}
lib-esign-csr-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-csr-stepper .mdc-text-field--outlined .mdc-floating-label {
  font-size: 14px !important;
}
lib-esign-csr-stepper input {
  font-size: 14px !important;
}
lib-esign-csr-stepper .mat-mdc-tab-labels {
  min-width: 160px;
  max-width: 160px;
  display: flex;
}
lib-esign-csr-stepper .mat-mdc-tab-body {
  margin-top: 5px;
}
lib-esign-csr-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  height: 25px;
}
lib-esign-csr-stepper lib-esign-csr-step-second .mat-mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-csr-stepper lib-esign-csr-step-second .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: black !important;
}
lib-esign-csr-stepper lib-esign-csr-step-second .mat-mdc-form-field {
  padding: 5px 0;
}
lib-esign-csr-stepper lib-esign-csr-step-second .mat-mdc-form-field-hint-wrapper, lib-esign-csr-stepper lib-esign-csr-step-second .mat-mdc-form-field-error-wrapper {
  margin-left: 16px;
}
lib-esign-csr-stepper lib-esign-csr-step-second .mat-mdc-icon-button {
  color: black;
}
lib-esign-csr-stepper lib-esign-csr-step-second .mat-mdc-form-field-icon-suffix {
  padding: 0 2px 8px 2px;
}
lib-esign-csr-stepper lib-esign-csr-step-fourth .mat-mdc-form-field {
  width: 100%;
}
lib-esign-csr-stepper lib-esign-csr-step-third .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-csr-stepper lib-esign-csr-step-third .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

lib-esign-user-otp .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}
lib-esign-user-otp .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px;
}
lib-esign-user-otp .mat-mdc-select-value-text {
  font-size: 14px;
}
lib-esign-user-otp .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  font-size: 14px !important;
}

lib-esign-data-show-document .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-data-show-document .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-data-show-document .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-data-confirm-delete .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-common-verify-documents {
  --mdc-radio-selected-icon-color: #448aff;
}
lib-esign-common-verify-documents .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle, lib-esign-common-verify-documents .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #448aff;
}
lib-esign-common-verify-documents .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

.mat-sidenav {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
}

lib-esign-common-sign-documents {
  --mdc-radio-selected-icon-color: #448aff;
}
lib-esign-common-sign-documents .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle, lib-esign-common-sign-documents .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #448aff;
}
lib-esign-common-sign-documents .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}
lib-esign-common-sign-documents .mat-mdc-select-value {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-common-sign-documents .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}
lib-esign-common-sign-documents .mat-mdc-option.mdc-list-item {
  font-size: 14px;
}

lib-esign-doc-config-visualization .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}
lib-esign-doc-config-visualization .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle, lib-esign-doc-config-visualization .mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #448aff;
}

.add-documents .mat-mdc-dialog-surface {
  overflow-y: inherit !important;
}

.config-visualization .mdc-text-field__input {
  font-size: 14px;
  text-align: center;
}
.config-visualization .mat-mdc-select-value {
  font-size: 14px;
}
.config-visualization .mat-mdc-input-element {
  font-size: 14px !important;
}

lib-esign-doc-config-visualization-confirm-exit .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-doc-config-visualization-size .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-order-viewer .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}
lib-esign-order-viewer .mat-mdc-raised-button:not(:disabled) {
  color: #fff;
}

lib-esign-document-viewer .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}
lib-esign-document-viewer .mat-mdc-raised-button:not(:disabled) {
  color: #fff;
}

lib-esign-config-visualization-photo .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-signbox-order-details-data .mat-mdc-card {
  padding: 16px !important;
}

lib-esign-verify-stepper .mat-mdc-card-content {
  width: 100% !important;
}
lib-esign-verify-stepper .mat-mdc-card-actions {
  justify-content: end !important;
}
lib-esign-verify-stepper .mat-mdc-form-field {
  width: 100%;
}
lib-esign-verify-stepper .mat-mdc-form-field-infix {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-verify-stepper .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}
lib-esign-verify-stepper .mat-mdc-card {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
}
lib-esign-verify-stepper .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
}
lib-esign-verify-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-verify-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-verify-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-verify-stepper lib-esign-verify-step-second .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-verify-stepper lib-esign-verify-step-second .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-verify-stepper lib-esign-verify-step-second .sign-card {
  align-items: baseline !important;
}

lib-esign-verify-document-viewer .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-verify-document-viewer .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-verify-document-viewer .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-qualified-stepper .mat-mdc-card-content {
  width: 100% !important;
}
lib-esign-qualified-stepper .mat-mdc-card-actions {
  justify-content: end !important;
}
lib-esign-qualified-stepper .mat-mdc-form-field {
  width: 100%;
}
lib-esign-qualified-stepper .mat-mdc-form-field-infix {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-qualified-stepper .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}
lib-esign-qualified-stepper .mat-mdc-card {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
}
lib-esign-qualified-stepper .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
}
lib-esign-qualified-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-qualified-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-qualified-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-qualified-stepper lib-esign-qualified-step-second .btn-choose {
  border-radius: 4px !important;
}
lib-esign-qualified-stepper lib-esign-qualified-step-third .mat-mdc-icon-button {
  color: black;
}
lib-esign-qualified-stepper lib-esign-qualified-step-third .mat-mdc-form-field {
  margin-bottom: 8px;
}
lib-esign-qualified-stepper lib-esign-qualified-step-third .mat-mdc-form-field-icon-suffix {
  padding: 0 2px 8px 2px;
}
lib-esign-qualified-stepper lib-esign-qualified-step-fourth .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-qualified-stepper lib-esign-qualified-step-fourth .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-qualified-stepper lib-esign-qualified-step-fourth .sign-card {
  align-items: baseline !important;
}

lib-esign-qualified-sign-document-viewer .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-qualified-sign-document-viewer .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-qualified-sign-document-viewer .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-seal-document-viewer .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-seal-document-viewer .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-seal-document-viewer .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-nonqualified-seal-stepper .mat-mdc-card-content {
  width: 100% !important;
}
lib-esign-nonqualified-seal-stepper .mat-mdc-card-actions {
  justify-content: end !important;
}
lib-esign-nonqualified-seal-stepper .mat-mdc-form-field {
  width: 100%;
}
lib-esign-nonqualified-seal-stepper .mat-mdc-form-field-infix {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-nonqualified-seal-stepper .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}
lib-esign-nonqualified-seal-stepper .mat-mdc-card {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
}
lib-esign-nonqualified-seal-stepper .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
}
lib-esign-nonqualified-seal-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-nonqualified-seal-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-nonqualified-seal-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-nonqualified-seal-stepper lib-esign-nonqualified-seal-step-second .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-nonqualified-seal-stepper lib-esign-nonqualified-seal-step-second .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-nonqualified-seal-stepper lib-esign-nonqualified-seal-step-second .sign-card {
  align-items: baseline !important;
}

lib-esign-sign-trusted-inner-document-viewer .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-sign-trusted-inner-document-viewer .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-sign-trusted-inner-document-viewer .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-choose-seal-trusted-inner-stepper .mat-mdc-card-content {
  width: 100% !important;
}
lib-esign-choose-seal-trusted-inner-stepper .mat-mdc-card-actions {
  justify-content: end !important;
}
lib-esign-choose-seal-trusted-inner-stepper .mat-mdc-form-field {
  width: 100%;
}
lib-esign-choose-seal-trusted-inner-stepper .mat-mdc-form-field-infix {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-choose-seal-trusted-inner-stepper .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}
lib-esign-choose-seal-trusted-inner-stepper .mat-mdc-card {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
}
lib-esign-choose-seal-trusted-inner-stepper .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
}
lib-esign-choose-seal-trusted-inner-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-choose-seal-trusted-inner-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-choose-seal-trusted-inner-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-choose-seal-trusted-inner-stepper lib-esign-choose-seal-trusted-inner-step-second .btn-choose {
  border-radius: 8px;
}
lib-esign-choose-seal-trusted-inner-stepper lib-esign-choose-seal-trusted-inner-step-fourth .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-choose-seal-trusted-inner-stepper lib-esign-choose-seal-trusted-inner-step-fourth .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-choose-seal-trusted-inner-stepper lib-esign-choose-seal-trusted-inner-step-fourth .sign-card {
  align-items: baseline !important;
}

lib-esign-trusted-inner-stepper .mat-mdc-card-content {
  width: 100% !important;
}
lib-esign-trusted-inner-stepper .mat-mdc-card-actions {
  justify-content: end !important;
}
lib-esign-trusted-inner-stepper .mat-mdc-form-field {
  width: 100%;
}
lib-esign-trusted-inner-stepper .mat-mdc-form-field-infix {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-trusted-inner-stepper .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}
lib-esign-trusted-inner-stepper .mat-mdc-card {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
}
lib-esign-trusted-inner-stepper .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
}
lib-esign-trusted-inner-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-trusted-inner-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-trusted-inner-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-trusted-inner-stepper lib-esign-trusted-inner-step-third .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-trusted-inner-stepper lib-esign-trusted-inner-step-third .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-trusted-inner-stepper lib-esign-trusted-inner-step-third .sign-card {
  align-items: baseline !important;
}

lib-esign-sign-sign-document-viewer .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-sign-sign-document-viewer .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-sign-sign-document-viewer .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-data-show-visualization .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-sign-stepper .mat-mdc-card-content {
  width: 100% !important;
}
lib-esign-sign-stepper .mat-mdc-card-actions {
  justify-content: end !important;
}
lib-esign-sign-stepper .mat-mdc-form-field {
  width: 100%;
}
lib-esign-sign-stepper .mat-mdc-form-field-infix {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-sign-stepper .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}
lib-esign-sign-stepper .mat-mdc-card {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
}
lib-esign-sign-stepper .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
}
lib-esign-sign-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-sign-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-sign-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-sign-stepper lib-esign-sign-step-second .mat-mdc-icon-button {
  color: black;
}
lib-esign-sign-stepper lib-esign-sign-step-second .mat-mdc-form-field-icon-suffix {
  padding: 0 2px 8px 2px;
}
lib-esign-sign-stepper lib-esign-sign-step-third .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-sign-stepper lib-esign-sign-step-third .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-sign-stepper lib-esign-sign-step-third .sign-card {
  align-items: baseline !important;
}

lib-esign-data-confirm-create-new-seal .mat-mdc-outlined-button:not(:disabled) {
  color: #fff;
}

lib-esign-settings-sign-visualization .mat-mdc-card {
  padding: 16px !important;
}
lib-esign-settings-sign-visualization .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  font-size: 14px;
}

lib-esign-data-certificate-seal-data .buttons {
  padding-bottom: 12px;
}

lib-esign-qualified-seal-stepper .mat-mdc-card-content {
  width: 100% !important;
}
lib-esign-qualified-seal-stepper .mat-mdc-card-actions {
  justify-content: end !important;
}
lib-esign-qualified-seal-stepper .mat-mdc-form-field {
  width: 100%;
}
lib-esign-qualified-seal-stepper .mat-mdc-form-field-infix {
  font-size: 14px;
  letter-spacing: normal;
}
lib-esign-qualified-seal-stepper .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 14px;
}
lib-esign-qualified-seal-stepper .mat-mdc-card {
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3) !important;
}
lib-esign-qualified-seal-stepper .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
}
lib-esign-qualified-seal-stepper .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  font-size: 14px;
}
lib-esign-qualified-seal-stepper .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.5) !important;
}
lib-esign-qualified-seal-stepper .mdc-linear-progress__bar-inner {
  border-color: #3f51b5 !important;
}
lib-esign-qualified-seal-stepper lib-esign-qualified-seal-step-second .mat-mdc-icon-button {
  color: black;
}
lib-esign-qualified-seal-stepper lib-esign-qualified-seal-step-second .mat-mdc-form-field-icon-suffix {
  padding: 0 2px 8px 2px;
}
lib-esign-qualified-seal-stepper lib-esign-qualified-seal-step-third .mat-mdc-card-title {
  padding-left: 0;
  font-size: 20px;
}
lib-esign-qualified-seal-stepper lib-esign-qualified-seal-step-third .mat-mdc-card-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
lib-esign-qualified-seal-stepper lib-esign-qualified-seal-step-third .sign-card {
  align-items: baseline !important;
}

/* stylelint-disable-next-line scss/no-global-function-names */
.mat-button-loading .mdc-button__label,
.mat-button-loading .mat-icon {
  visibility: hidden;
}
.mat-button-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.mat-column-resize-table.cdk-column-resize-with-resized-column {
  table-layout: fixed;
}

.mat-column-resize-flex .mat-mdc-header-cell,
.mat-column-resize-flex .mat-mdc-cell {
  box-sizing: border-box;
  min-width: 32px;
}

.mat-mdc-header-cell {
  position: relative;
}

.mat-resizable {
  box-sizing: border-box;
}

.mat-mdc-header-cell:not(.mat-resizable)::after,
.mat-resizable-handle {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 1px;
  background: transparent;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
}

.mat-mdc-header-cell:not(.mat-resizable)::after {
  content: "";
}

[dir=rtl] .mat-mdc-header-cell:not(.mat-resizable)::after,
[dir=rtl] .mat-resizable-handle {
  left: 0;
  right: auto;
}
[dir=rtl] .mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-header-cell,
[dir=rtl] .mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell {
  border-left: none;
}

.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell {
  border-right: none;
}
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell:not(.mat-resizable)::after {
  background-color: var(--mtx-grid-resizable-handle-disabled-background-color, var(--mat-app-outline-variant));
}
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle {
  background-color: var(--mtx-grid-resizable-handle-hover-background-color, var(--mat-app-primary));
}

.mat-resizable.cdk-resizable-overlay-thumb-active > .mat-resizable-handle {
  opacity: 0;
  transition: none;
}

.mat-resizable-handle:focus,
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle:focus {
  outline: none;
  background-color: var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary));
}

.mat-column-resize-overlay-thumb {
  background: transparent;
  cursor: col-resize;
  width: 100%;
  height: 100%;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-user-select: none;
  user-select: none;
}
.mat-column-resize-overlay-thumb:active {
  will-change: transform;
  background: linear-gradient(90deg, transparent, transparent 7px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 7px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 9px, transparent 9px, transparent);
}
.mat-column-resize-overlay-thumb:active .mat-column-resize-overlay-thumb-top {
  background: linear-gradient(90deg, transparent, transparent 4px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 4px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 12px, transparent 12px, transparent);
}

.mat-column-resize-overlay-thumb-top {
  width: 100%;
}

html {
  --body-font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  --code-font-family: "Roboto Mono", monospace;
  --gutter: 1rem;
  --sidenav-width: 15rem;
  --sidenav-collapsed-width: 4rem;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.matero-sidenav {
  position: absolute;
  overflow-x: hidden;
  transition: swift-ease-out(width);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.matero-sidenav.mat-drawer-side {
  border-width: 0;
}
.matero-sidenav .matero-sidemenu {
  width: 240px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.matero-sidenav .matero-sidemenu .matero-sidemenu-item {
  display: block;
  height: auto;
  padding: 0;
}
.matero-sidenav .matero-sidemenu .matero-sidemenu-item.open > .matero-sidemenu {
  max-height: 2000px;
}
.matero-sidenav .matero-sidemenu .matero-sidemenu-item.open > .matero-sidemenu-toggle > .menu-caret {
  transform: rotate(-180deg);
}
.matero-sidenav .matero-sidemenu a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  text-decoration: none;
}
.matero-sidenav .matero-sidemenu .menu-icon {
  width: 18px;
  height: 18px;
  margin-right: 16px;
  font-size: 18px;
  line-height: 18px;
}
[dir=rtl] .matero-sidenav .matero-sidemenu .menu-icon {
  margin-left: 16px;
  margin-right: auto;
}
.matero-sidenav .matero-sidemenu .menu-name,
.matero-sidenav .matero-sidemenu .menu-label,
.matero-sidenav .matero-sidemenu .menu-badge {
  transition: swift-ease-out(opacity);
}
.matero-sidenav .matero-sidemenu .menu-label,
.matero-sidenav .matero-sidemenu .menu-badge {
  display: inline-block;
  min-width: 18px;
  padding: 3px 5px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: #757575;
  border-radius: 2px;
}
.matero-sidenav .matero-sidemenu .menu-badge {
  border-radius: 10px;
}
.matero-sidenav .matero-sidemenu .menu-caret {
  display: block;
  text-align: center;
  transition: fast-out-slow(transform);
}
.matero-sidenav .matero-sidemenu.level-0 > li > a > .menu-name {
  margin-right: 5px;
}
[dir=rtl] .matero-sidenav .matero-sidemenu.level-0 > li > a > .menu-name {
  margin-left: 5px;
  margin-right: auto;
}
.matero-sidenav .matero-sidemenu.level-1 > li > a {
  padding-left: 50px;
}
[dir=rtl] .matero-sidenav .matero-sidemenu.level-1 > li > a {
  padding-left: 16px;
  padding-right: 50px;
}
.matero-sidenav .matero-sidemenu.level-2 > li > a {
  padding-left: 64px;
}
[dir=rtl] .matero-sidenav .matero-sidemenu.level-2 > li > a {
  padding-left: 16px;
  padding-right: 64px;
}
.matero-sidenav .matero-sidemenu.submenu {
  max-height: 0;
  padding-top: 0;
  overflow: hidden;
  transform: translateZ(0) !important;
  transition: fast-out-slow(max-height);
}
.matero-sidenav .matero-sidemenu.submenu .matero-sidemenu-item {
  border-left-width: 0;
}
.matero-sidenav .matero-sidebar-header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 10px;
  overflow: hidden;
}
@media (max-width: 599px) {
  .matero-sidenav .matero-sidebar-header {
    height: 56px;
  }
}
.matero-header-white .matero-sidenav .matero-sidebar-header {
  background-color: white;
}
.matero-sidenav .matero-sidebar-header mat-toolbar {
  width: var(--sidenav-width);
  padding: 0 0.5rem;
}
.matero-sidenav .matero-sidebar-header mat-slide-toggle .mdc-label {
  display: none;
}
.matero-sidenav .matero-sidebar-main {
  height: calc(100% - var(--mat-toolbar-standard-height));
  padding: 0 0.5rem 0.5rem;
  overflow: auto;
  scrollbar-width: none;
}
@media (max-width: 599px) {
  .matero-sidenav .matero-sidebar-main {
    height: calc(100% - var(--mat-toolbar-mobile-height));
  }
}

.matero-user-panel {
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.matero-user-panel-avatar {
  margin-bottom: 8px;
  width: 64px;
  height: 64px;
  border-radius: 999px;
  transition: swift-ease-out(transform);
}

.matero-user-panel-name,
.matero-user-panel-email {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: normal;
}

.matero-user-panel-name,
.matero-user-panel-email,
.matero-user-panel-icons {
  opacity: 1;
  transition: swift-ease-out(opacity);
}

.matero-user-panel-icons {
  white-space: nowrap;
}
.matero-user-panel-icons .mat-icon-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.matero-user-panel-icons .mat-icon-button .mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}

.matero-sidenav-collapsed .matero-sidenav,
.matero-sidenav-collapsed-fix .matero-sidenav {
  width: 50px;
}
.matero-sidenav-collapsed .matero-sidenav .matero-branding,
.matero-sidenav-collapsed .matero-sidenav .menu-name,
.matero-sidenav-collapsed .matero-sidenav .menu-label,
.matero-sidenav-collapsed .matero-sidenav .menu-badge,
.matero-sidenav-collapsed .matero-sidenav .menu-caret,
.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-name,
.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-email,
.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-icons,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-branding,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-name,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-label,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-badge,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-caret,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-name,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-email,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-icons {
  opacity: 0;
}
.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-avatar,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-avatar {
  transform: scale(0.5);
}
.matero-sidenav-collapsed .matero-sidenav:hover,
.matero-sidenav-collapsed-fix .matero-sidenav:hover {
  width: 240px;
}
.matero-sidenav-collapsed .matero-sidenav:hover .matero-branding,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-name,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-label,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-badge,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-caret,
.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-name,
.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-email,
.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-icons,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-branding,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-name,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-label,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-badge,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-caret,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-name,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-email,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-icons {
  opacity: 1;
}
.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-avatar,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-avatar {
  transform: scale(1);
}

.matero-sidenav-collapsed .matero-content-wrap {
  margin-left: 50px !important;
}
[dir=rtl] .matero-sidenav-collapsed .matero-content-wrap {
  margin-left: auto !important;
  margin-right: 50px !important;
}
.matero-sidenav-collapsed[dir=rtl] .matero-content-wrap {
  margin-left: auto !important;
  margin-right: 50px !important;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.matero-topmenu {
  position: sticky;
  z-index: 200;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.matero-topmenu .menu-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  font-size: 18px;
  line-height: 18px;
}
[dir=rtl] .matero-topmenu .menu-icon {
  margin-left: 8px;
  margin-right: auto;
}
.matero-topmenu .menu-label,
.matero-topmenu .menu-badge {
  margin-left: 8px;
  font-size: 12px;
}
[dir=rtl] .matero-topmenu .menu-label,
[dir=rtl] .matero-topmenu .menu-badge {
  margin-left: auto;
  margin-right: 8px;
}
.matero-topmenu .menu-badge {
  border-radius: 10px;
}
.matero-topmenu .menu-caret {
  margin-right: -8px;
}
[dir=rtl] .matero-topmenu .menu-caret {
  margin-left: -8px;
  margin-right: auto;
}

.mat-menu-item .menu-name {
  margin-right: 8px;
  vertical-align: middle;
}
[dir=rtl] .mat-menu-item .menu-name {
  margin-left: 8px;
  margin-right: auto;
}

.matero-topmenu-inner {
  padding: 8px;
  white-space: nowrap;
}

.matero-navbar-top .matero-topmenu {
  top: 0;
}
.matero-navbar-top .matero-branding {
  margin-left: 16px;
}
[dir=rtl] .matero-navbar-top .matero-branding {
  margin-left: auto;
  margin-right: 16px;
}

.matero-header-fixed .matero-topmenu {
  top: 64px;
}
@media (max-width: 599px) {
  .matero-header-fixed .matero-topmenu {
    top: 56px;
  }
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html,
body {
  height: 100%;
  min-height: 100%;
  line-height: normal;
  font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
}

body {
  position: relative;
  margin: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #2979ff;
  text-decoration: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

code,
kbd,
pre,
samp {
  font-family: "Roboto Mono", monospace;
}

code {
  font-size: 0.875em;
  color: #ff1744;
  word-break: break-word;
}

.matero-branding {
  display: inline-block;
  color: inherit;
  font-size: 16px;
  white-space: nowrap;
}

.matero-branding-logo-expanded {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
}

[dir=rtl] .matero-branding-logo-expanded {
  margin-left: 10px;
  margin-right: auto;
}

.matero-branding-name {
  font-weight: normal;
  vertical-align: middle;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.matero-header {
  position: relative;
  z-index: 201;
  display: block;
}

.matero-header .matero-toolbar {
  padding: 0 8px;
}

.matero-header-fixed .matero-header {
  position: sticky;
  top: 0;
}

.matero-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.matero-navbar-side.matero-header-fixed .matero-toolbar {
  border-bottom: unset;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.matero-toolbar .badge {
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 12px;
  border-radius: 10px;
}

.matero-header-white .matero-toolbar,
.matero-header-white .matero-topmenu {
  background-color: white;
}

.matero-header-primary .matero-toolbar {
  background-color: #3f51b5;
  color: white;
}

.matero-header-primary .matero-topmenu {
  background-color: white;
}

.matero-toolbar-button {
  color: inherit;
}

.matero-toolbar-button.mat-button, .matero-toolbar-button.mat-icon-button {
  margin: 0 4px;
}

.matero-toolbar-button.mat-button {
  min-width: unset;
  line-height: 40px;
  border-radius: 999px;
}

.matero-toolbar-button.matero-avatar-button {
  display: block;
  padding: 0 4px;
  font-size: 0;
}

.matero-toolbar-button.matero-avatar-button .matero-avatar {
  width: 32px;
  height: 32px;
  border-radius: 999px;
}

.matero-toolbar-button.matero-avatar-button .matero-username {
  margin: 0 8px;
  font-size: 14px;
  padding-top: 4px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.matero-container-wrap,
.matero-container {
  height: 100%;
}

.matero-content {
  position: relative;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 64px);
}

@media (max-width: 599px) {
  .matero-content {
    max-height: calc(100vh - 56px);
  }
}
.mat-drawer-content {
  overflow: hidden;
}

.matero-no-padding-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 64px);
}

@media (max-width: 599px) {
  .matero-no-padding-content {
    max-height: calc(100vh - 56px);
  }
}
.matero-row {
  margin-right: -8px;
  margin-left: -8px;
}

.matero-col {
  padding-right: 8px;
  padding-left: 8px;
}

.matero-header-above .matero-container {
  height: calc(100% - 64px) !important;
}

.matero-header-above .matero-sidebar-main {
  height: 100% !important;
}

.matero-content-width-fix .matero-content-wrap {
  margin-left: 240px !important;
}

[dir=rtl] .matero-content-width-fix .matero-content-wrap {
  margin-left: auto !important;
  margin-right: 240px !important;
}

.matero-auth-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #212121;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%, transparent 75%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05)), linear-gradient(-45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%, transparent 75%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05));
  background-size: 60px 60px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.matero-sidenav {
  position: absolute;
  overflow-x: hidden;
  transition: swift-ease-out(width);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.matero-sidenav.mat-drawer-side {
  border-width: 0;
}

.matero-sidenav .matero-sidemenu {
  width: 240px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.matero-sidenav .matero-sidemenu .matero-sidemenu-item {
  display: block;
  height: auto;
  padding: 0;
}

.matero-sidenav .matero-sidemenu .matero-sidemenu-item.open > .matero-sidemenu {
  max-height: 2000px;
}

.matero-sidenav .matero-sidemenu .matero-sidemenu-item.open > .matero-sidemenu-toggle > .menu-caret {
  transform: rotate(-180deg);
}

.matero-sidenav .matero-sidemenu a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  text-decoration: none;
}

.matero-sidenav .matero-sidemenu .menu-icon {
  width: 18px;
  height: 18px;
  margin-right: 16px;
  font-size: 18px;
  line-height: 18px;
}

[dir=rtl] .matero-sidenav .matero-sidemenu .menu-icon {
  margin-left: 16px;
  margin-right: auto;
}

.matero-sidenav .matero-sidemenu .menu-name,
.matero-sidenav .matero-sidemenu .menu-label,
.matero-sidenav .matero-sidemenu .menu-badge {
  transition: swift-ease-out(opacity);
}

.matero-sidenav .matero-sidemenu .menu-label,
.matero-sidenav .matero-sidemenu .menu-badge {
  display: inline-block;
  min-width: 18px;
  padding: 3px 5px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: #757575;
  border-radius: 2px;
}

.matero-sidenav .matero-sidemenu .menu-badge {
  border-radius: 10px;
}

.matero-sidenav .matero-sidemenu .menu-caret {
  display: block;
  text-align: center;
  transition: fast-out-slow(transform);
}

.matero-sidenav .matero-sidemenu.level-0 > li > a > .menu-name {
  margin-right: 5px;
}

[dir=rtl] .matero-sidenav .matero-sidemenu.level-0 > li > a > .menu-name {
  margin-left: 5px;
  margin-right: auto;
}

.matero-sidenav .matero-sidemenu.level-1 > li > a {
  padding-left: 50px;
}

[dir=rtl] .matero-sidenav .matero-sidemenu.level-1 > li > a {
  padding-left: 16px;
  padding-right: 50px;
}

.matero-sidenav .matero-sidemenu.level-2 > li > a {
  padding-left: 64px;
}

[dir=rtl] .matero-sidenav .matero-sidemenu.level-2 > li > a {
  padding-left: 16px;
  padding-right: 64px;
}

.matero-sidenav .matero-sidemenu.submenu {
  max-height: 0;
  padding-top: 0;
  overflow: hidden;
  transform: translateZ(0) !important;
  transition: fast-out-slow(max-height);
}

.matero-sidenav .matero-sidemenu.submenu .matero-sidemenu-item {
  border-left-width: 0;
}

.matero-sidenav .matero-sidebar-header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 10px;
  overflow: hidden;
}

@media (max-width: 599px) {
  .matero-sidenav .matero-sidebar-header {
    height: 56px;
  }
}
.matero-header-white .matero-sidenav .matero-sidebar-header {
  background-color: white;
}

.matero-sidenav .matero-sidebar-header mat-toolbar {
  width: var(--sidenav-width);
  padding: 0 0.5rem;
}

.matero-sidenav .matero-sidebar-header mat-slide-toggle .mdc-label {
  display: none;
}

.matero-sidenav .matero-sidebar-main {
  height: calc(100% - var(--mat-toolbar-standard-height));
  padding: 0 0.5rem 0.5rem;
  overflow: auto;
  scrollbar-width: none;
}

@media (max-width: 599px) {
  .matero-sidenav .matero-sidebar-main {
    height: calc(100% - var(--mat-toolbar-mobile-height));
  }
}
.matero-user-panel {
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.matero-user-panel-avatar {
  margin-bottom: 8px;
  width: 64px;
  height: 64px;
  border-radius: 999px;
  transition: swift-ease-out(transform);
}

.matero-user-panel-name,
.matero-user-panel-email {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: normal;
}

.matero-user-panel-name,
.matero-user-panel-email,
.matero-user-panel-icons {
  opacity: 1;
  transition: swift-ease-out(opacity);
}

.matero-user-panel-icons {
  white-space: nowrap;
}

.matero-user-panel-icons .mat-icon-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.matero-user-panel-icons .mat-icon-button .mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}

.matero-sidenav-collapsed .matero-sidenav,
.matero-sidenav-collapsed-fix .matero-sidenav {
  width: 50px;
}

.matero-sidenav-collapsed .matero-sidenav .matero-branding,
.matero-sidenav-collapsed .matero-sidenav .menu-name,
.matero-sidenav-collapsed .matero-sidenav .menu-label,
.matero-sidenav-collapsed .matero-sidenav .menu-badge,
.matero-sidenav-collapsed .matero-sidenav .menu-caret,
.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-name,
.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-email,
.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-icons,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-branding,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-name,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-label,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-badge,
.matero-sidenav-collapsed-fix .matero-sidenav .menu-caret,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-name,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-email,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-icons {
  opacity: 0;
}

.matero-sidenav-collapsed .matero-sidenav .matero-user-panel-avatar,
.matero-sidenav-collapsed-fix .matero-sidenav .matero-user-panel-avatar {
  transform: scale(0.5);
}

.matero-sidenav-collapsed .matero-sidenav:hover,
.matero-sidenav-collapsed-fix .matero-sidenav:hover {
  width: 240px;
}

.matero-sidenav-collapsed .matero-sidenav:hover .matero-branding,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-name,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-label,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-badge,
.matero-sidenav-collapsed .matero-sidenav:hover .menu-caret,
.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-name,
.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-email,
.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-icons,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-branding,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-name,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-label,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-badge,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .menu-caret,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-name,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-email,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-icons {
  opacity: 1;
}

.matero-sidenav-collapsed .matero-sidenav:hover .matero-user-panel-avatar,
.matero-sidenav-collapsed-fix .matero-sidenav:hover .matero-user-panel-avatar {
  transform: scale(1);
}

.matero-sidenav-collapsed .matero-content-wrap {
  margin-left: 50px !important;
}

[dir=rtl] .matero-sidenav-collapsed .matero-content-wrap {
  margin-left: auto !important;
  margin-right: 50px !important;
}

.matero-sidenav-collapsed[dir=rtl] .matero-content-wrap {
  margin-left: auto !important;
  margin-right: 50px !important;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.matero-topmenu {
  position: sticky;
  z-index: 200;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.matero-topmenu .menu-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  font-size: 18px;
  line-height: 18px;
}

[dir=rtl] .matero-topmenu .menu-icon {
  margin-left: 8px;
  margin-right: auto;
}

.matero-topmenu .menu-label,
.matero-topmenu .menu-badge {
  margin-left: 8px;
  font-size: 12px;
}

[dir=rtl] .matero-topmenu .menu-label,
[dir=rtl] .matero-topmenu .menu-badge {
  margin-left: auto;
  margin-right: 8px;
}

.matero-topmenu .menu-badge {
  border-radius: 10px;
}

.matero-topmenu .menu-caret {
  margin-right: -8px;
}

[dir=rtl] .matero-topmenu .menu-caret {
  margin-left: -8px;
  margin-right: auto;
}

.mat-menu-item .menu-name {
  margin-right: 8px;
  vertical-align: middle;
}

[dir=rtl] .mat-menu-item .menu-name {
  margin-left: 8px;
  margin-right: auto;
}

.matero-topmenu-inner {
  padding: 8px;
  white-space: nowrap;
}

.matero-navbar-top .matero-topmenu {
  top: 0;
}

.matero-navbar-top .matero-branding {
  margin-left: 16px;
}

[dir=rtl] .matero-navbar-top .matero-branding {
  margin-left: auto;
  margin-right: 16px;
}

.matero-header-fixed .matero-topmenu {
  top: 64px;
}

@media (max-width: 599px) {
  .matero-header-fixed .matero-topmenu {
    top: 56px;
  }
}
.text-red-50 {
  color: #ffebee !important;
}

.text-red-100 {
  color: #ffcdd2 !important;
}

.text-red-200 {
  color: #ef9a9a !important;
}

.text-red-300 {
  color: #e57373 !important;
}

.text-red-400 {
  color: #ef5350 !important;
}

.text-red-500 {
  color: #f44336 !important;
}

.text-red-600 {
  color: #e53935 !important;
}

.text-red-700 {
  color: #d32f2f !important;
}

.text-red-800 {
  color: #c62828 !important;
}

.text-red-900 {
  color: #b71c1c !important;
}

.text-red-A100 {
  color: #ff8a80 !important;
}

.text-red-A200 {
  color: #ff5252 !important;
}

.text-red-A400 {
  color: #ff1744 !important;
}

.text-red-A700 {
  color: #d50000 !important;
}

.text-pink-50 {
  color: #fce4ec !important;
}

.text-pink-100 {
  color: #f8bbd0 !important;
}

.text-pink-200 {
  color: #f48fb1 !important;
}

.text-pink-300 {
  color: #f06292 !important;
}

.text-pink-400 {
  color: #ec407a !important;
}

.text-pink-500 {
  color: #e91e63 !important;
}

.text-pink-600 {
  color: #d81b60 !important;
}

.text-pink-700 {
  color: #c2185b !important;
}

.text-pink-800 {
  color: #ad1457 !important;
}

.text-pink-900 {
  color: #880e4f !important;
}

.text-pink-A100 {
  color: #ff80ab !important;
}

.text-pink-A200 {
  color: #ff4081 !important;
}

.text-pink-A400 {
  color: #f50057 !important;
}

.text-pink-A700 {
  color: #c51162 !important;
}

.text-purple-50 {
  color: #f3e5f5 !important;
}

.text-purple-100 {
  color: #e1bee7 !important;
}

.text-purple-200 {
  color: #ce93d8 !important;
}

.text-purple-300 {
  color: #ba68c8 !important;
}

.text-purple-400 {
  color: #ab47bc !important;
}

.text-purple-500 {
  color: #9c27b0 !important;
}

.text-purple-600 {
  color: #8e24aa !important;
}

.text-purple-700 {
  color: #7b1fa2 !important;
}

.text-purple-800 {
  color: #6a1b9a !important;
}

.text-purple-900 {
  color: #4a148c !important;
}

.text-purple-A100 {
  color: #ea80fc !important;
}

.text-purple-A200 {
  color: #e040fb !important;
}

.text-purple-A400 {
  color: #d500f9 !important;
}

.text-purple-A700 {
  color: #aa00ff !important;
}

.text-deep-purple-50 {
  color: #ede7f6 !important;
}

.text-deep-purple-100 {
  color: #d1c4e9 !important;
}

.text-deep-purple-200 {
  color: #b39ddb !important;
}

.text-deep-purple-300 {
  color: #9575cd !important;
}

.text-deep-purple-400 {
  color: #7e57c2 !important;
}

.text-deep-purple-500 {
  color: #673ab7 !important;
}

.text-deep-purple-600 {
  color: #5e35b1 !important;
}

.text-deep-purple-700 {
  color: #512da8 !important;
}

.text-deep-purple-800 {
  color: #4527a0 !important;
}

.text-deep-purple-900 {
  color: #311b92 !important;
}

.text-deep-purple-A100 {
  color: #b388ff !important;
}

.text-deep-purple-A200 {
  color: #7c4dff !important;
}

.text-deep-purple-A400 {
  color: #651fff !important;
}

.text-deep-purple-A700 {
  color: #6200ea !important;
}

.text-indigo-50 {
  color: #e8eaf6 !important;
}

.text-indigo-100 {
  color: #c5cae9 !important;
}

.text-indigo-200 {
  color: #9fa8da !important;
}

.text-indigo-300 {
  color: #7986cb !important;
}

.text-indigo-400 {
  color: #5c6bc0 !important;
}

.text-indigo-500 {
  color: #3f51b5 !important;
}

.text-indigo-600 {
  color: #3949ab !important;
}

.text-indigo-700 {
  color: #303f9f !important;
}

.text-indigo-800 {
  color: #283593 !important;
}

.text-indigo-900 {
  color: #1a237e !important;
}

.text-indigo-A100 {
  color: #8c9eff !important;
}

.text-indigo-A200 {
  color: #536dfe !important;
}

.text-indigo-A400 {
  color: #3d5afe !important;
}

.text-indigo-A700 {
  color: #304ffe !important;
}

.text-blue-50 {
  color: #e3f2fd !important;
}

.text-blue-100 {
  color: #bbdefb !important;
}

.text-blue-200 {
  color: #90caf9 !important;
}

.text-blue-300 {
  color: #64b5f6 !important;
}

.text-blue-400 {
  color: #42a5f5 !important;
}

.text-blue-500 {
  color: #2196f3 !important;
}

.text-blue-600 {
  color: #1e88e5 !important;
}

.text-blue-700 {
  color: #1976d2 !important;
}

.text-blue-800 {
  color: #1565c0 !important;
}

.text-blue-900 {
  color: #0d47a1 !important;
}

.text-blue-A100 {
  color: #82b1ff !important;
}

.text-blue-A200 {
  color: #448aff !important;
}

.text-blue-A400 {
  color: #2979ff !important;
}

.text-blue-A700 {
  color: #2962ff !important;
}

.text-light-blue-50 {
  color: #e1f5fe !important;
}

.text-light-blue-100 {
  color: #b3e5fc !important;
}

.text-light-blue-200 {
  color: #81d4fa !important;
}

.text-light-blue-300 {
  color: #4fc3f7 !important;
}

.text-light-blue-400 {
  color: #29b6f6 !important;
}

.text-light-blue-500 {
  color: #03a9f4 !important;
}

.text-light-blue-600 {
  color: #039be5 !important;
}

.text-light-blue-700 {
  color: #0288d1 !important;
}

.text-light-blue-800 {
  color: #0277bd !important;
}

.text-light-blue-900 {
  color: #01579b !important;
}

.text-light-blue-A100 {
  color: #80d8ff !important;
}

.text-light-blue-A200 {
  color: #40c4ff !important;
}

.text-light-blue-A400 {
  color: #00b0ff !important;
}

.text-light-blue-A700 {
  color: #0091ea !important;
}

.text-cyan-50 {
  color: #e0f7fa !important;
}

.text-cyan-100 {
  color: #b2ebf2 !important;
}

.text-cyan-200 {
  color: #80deea !important;
}

.text-cyan-300 {
  color: #4dd0e1 !important;
}

.text-cyan-400 {
  color: #26c6da !important;
}

.text-cyan-500 {
  color: #00bcd4 !important;
}

.text-cyan-600 {
  color: #00acc1 !important;
}

.text-cyan-700 {
  color: #0097a7 !important;
}

.text-cyan-800 {
  color: #00838f !important;
}

.text-cyan-900 {
  color: #006064 !important;
}

.text-cyan-A100 {
  color: #84ffff !important;
}

.text-cyan-A200 {
  color: #18ffff !important;
}

.text-cyan-A400 {
  color: #00e5ff !important;
}

.text-cyan-A700 {
  color: #00b8d4 !important;
}

.text-teal-50 {
  color: #e0f2f1 !important;
}

.text-teal-100 {
  color: #b2dfdb !important;
}

.text-teal-200 {
  color: #80cbc4 !important;
}

.text-teal-300 {
  color: #4db6ac !important;
}

.text-teal-400 {
  color: #26a69a !important;
}

.text-teal-500 {
  color: #009688 !important;
}

.text-teal-600 {
  color: #00897b !important;
}

.text-teal-700 {
  color: #00796b !important;
}

.text-teal-800 {
  color: #00695c !important;
}

.text-teal-900 {
  color: #004d40 !important;
}

.text-teal-A100 {
  color: #a7ffeb !important;
}

.text-teal-A200 {
  color: #64ffda !important;
}

.text-teal-A400 {
  color: #1de9b6 !important;
}

.text-teal-A700 {
  color: #00bfa5 !important;
}

.text-green-50 {
  color: #e8f5e9 !important;
}

.text-green-100 {
  color: #c8e6c9 !important;
}

.text-green-200 {
  color: #a5d6a7 !important;
}

.text-green-300 {
  color: #81c784 !important;
}

.text-green-400 {
  color: #66bb6a !important;
}

.text-green-500 {
  color: #4caf50 !important;
}

.text-green-600 {
  color: #43a047 !important;
}

.text-green-700 {
  color: #388e3c !important;
}

.text-green-800 {
  color: #2e7d32 !important;
}

.text-green-900 {
  color: #1b5e20 !important;
}

.text-green-A100 {
  color: #b9f6ca !important;
}

.text-green-A200 {
  color: #69f0ae !important;
}

.text-green-A400 {
  color: #00e676 !important;
}

.text-green-A700 {
  color: #00c853 !important;
}

.text-light-green-50 {
  color: #f1f8e9 !important;
}

.text-light-green-100 {
  color: #dcedc8 !important;
}

.text-light-green-200 {
  color: #c5e1a5 !important;
}

.text-light-green-300 {
  color: #aed581 !important;
}

.text-light-green-400 {
  color: #9ccc65 !important;
}

.text-light-green-500 {
  color: #8bc34a !important;
}

.text-light-green-600 {
  color: #7cb342 !important;
}

.text-light-green-700 {
  color: #689f38 !important;
}

.text-light-green-800 {
  color: #558b2f !important;
}

.text-light-green-900 {
  color: #33691e !important;
}

.text-light-green-A100 {
  color: #ccff90 !important;
}

.text-light-green-A200 {
  color: #b2ff59 !important;
}

.text-light-green-A400 {
  color: #76ff03 !important;
}

.text-light-green-A700 {
  color: #64dd17 !important;
}

.text-lime-50 {
  color: #f9fbe7 !important;
}

.text-lime-100 {
  color: #f0f4c3 !important;
}

.text-lime-200 {
  color: #e6ee9c !important;
}

.text-lime-300 {
  color: #dce775 !important;
}

.text-lime-400 {
  color: #d4e157 !important;
}

.text-lime-500 {
  color: #cddc39 !important;
}

.text-lime-600 {
  color: #c0ca33 !important;
}

.text-lime-700 {
  color: #afb42b !important;
}

.text-lime-800 {
  color: #9e9d24 !important;
}

.text-lime-900 {
  color: #827717 !important;
}

.text-lime-A100 {
  color: #f4ff81 !important;
}

.text-lime-A200 {
  color: #eeff41 !important;
}

.text-lime-A400 {
  color: #c6ff00 !important;
}

.text-lime-A700 {
  color: #aeea00 !important;
}

.text-yellow-50 {
  color: #fffde7 !important;
}

.text-yellow-100 {
  color: #fff9c4 !important;
}

.text-yellow-200 {
  color: #fff59d !important;
}

.text-yellow-300 {
  color: #fff176 !important;
}

.text-yellow-400 {
  color: #ffee58 !important;
}

.text-yellow-500 {
  color: #ffeb3b !important;
}

.text-yellow-600 {
  color: #fdd835 !important;
}

.text-yellow-700 {
  color: #fbc02d !important;
}

.text-yellow-800 {
  color: #f9a825 !important;
}

.text-yellow-900 {
  color: #f57f17 !important;
}

.text-yellow-A100 {
  color: #ffff8d !important;
}

.text-yellow-A200 {
  color: #ffff00 !important;
}

.text-yellow-A400 {
  color: #ffea00 !important;
}

.text-yellow-A700 {
  color: #ffd600 !important;
}

.text-amber-50 {
  color: #fff8e1 !important;
}

.text-amber-100 {
  color: #ffecb3 !important;
}

.text-amber-200 {
  color: #ffe082 !important;
}

.text-amber-300 {
  color: #ffd54f !important;
}

.text-amber-400 {
  color: #ffca28 !important;
}

.text-amber-500 {
  color: #ffc107 !important;
}

.text-amber-600 {
  color: #ffb300 !important;
}

.text-amber-700 {
  color: #ffa000 !important;
}

.text-amber-800 {
  color: #ff8f00 !important;
}

.text-amber-900 {
  color: #ff6f00 !important;
}

.text-amber-A100 {
  color: #ffe57f !important;
}

.text-amber-A200 {
  color: #ffd740 !important;
}

.text-amber-A400 {
  color: #ffc400 !important;
}

.text-amber-A700 {
  color: #ffab00 !important;
}

.text-orange-50 {
  color: #fff3e0 !important;
}

.text-orange-100 {
  color: #ffe0b2 !important;
}

.text-orange-200 {
  color: #ffcc80 !important;
}

.text-orange-300 {
  color: #ffb74d !important;
}

.text-orange-400 {
  color: #ffa726 !important;
}

.text-orange-500 {
  color: #ff9800 !important;
}

.text-orange-600 {
  color: #fb8c00 !important;
}

.text-orange-700 {
  color: #f57c00 !important;
}

.text-orange-800 {
  color: #ef6c00 !important;
}

.text-orange-900 {
  color: #e65100 !important;
}

.text-orange-A100 {
  color: #ffd180 !important;
}

.text-orange-A200 {
  color: #ffab40 !important;
}

.text-orange-A400 {
  color: #ff9100 !important;
}

.text-orange-A700 {
  color: #ff6d00 !important;
}

.text-deep-orange-50 {
  color: #fbe9e7 !important;
}

.text-deep-orange-100 {
  color: #ffccbc !important;
}

.text-deep-orange-200 {
  color: #ffab91 !important;
}

.text-deep-orange-300 {
  color: #ff8a65 !important;
}

.text-deep-orange-400 {
  color: #ff7043 !important;
}

.text-deep-orange-500 {
  color: #ff5722 !important;
}

.text-deep-orange-600 {
  color: #f4511e !important;
}

.text-deep-orange-700 {
  color: #e64a19 !important;
}

.text-deep-orange-800 {
  color: #d84315 !important;
}

.text-deep-orange-900 {
  color: #bf360c !important;
}

.text-deep-orange-A100 {
  color: #ff9e80 !important;
}

.text-deep-orange-A200 {
  color: #ff6e40 !important;
}

.text-deep-orange-A400 {
  color: #ff3d00 !important;
}

.text-deep-orange-A700 {
  color: #dd2c00 !important;
}

.text-brown-50 {
  color: #efebe9 !important;
}

.text-brown-100 {
  color: #d7ccc8 !important;
}

.text-brown-200 {
  color: #bcaaa4 !important;
}

.text-brown-300 {
  color: #a1887f !important;
}

.text-brown-400 {
  color: #8d6e63 !important;
}

.text-brown-500 {
  color: #795548 !important;
}

.text-brown-600 {
  color: #6d4c41 !important;
}

.text-brown-700 {
  color: #5d4037 !important;
}

.text-brown-800 {
  color: #4e342e !important;
}

.text-brown-900 {
  color: #3e2723 !important;
}

.text-brown-A100 {
  color: #d7ccc8 !important;
}

.text-brown-A200 {
  color: #bcaaa4 !important;
}

.text-brown-A400 {
  color: #8d6e63 !important;
}

.text-brown-A700 {
  color: #5d4037 !important;
}

.text-gray-50 {
  color: #fafafa !important;
}

.text-gray-100 {
  color: #f5f5f5 !important;
}

.text-gray-200 {
  color: #eeeeee !important;
}

.text-gray-300 {
  color: #e0e0e0 !important;
}

.text-gray-400 {
  color: #bdbdbd !important;
}

.text-gray-500 {
  color: #9e9e9e !important;
}

.text-gray-600 {
  color: #757575 !important;
}

.text-gray-700 {
  color: #616161 !important;
}

.text-gray-800 {
  color: #424242 !important;
}

.text-gray-900 {
  color: #212121 !important;
}

.text-gray-A100 {
  color: #ffffff !important;
}

.text-gray-A200 {
  color: #eeeeee !important;
}

.text-gray-A400 {
  color: #bdbdbd !important;
}

.text-gray-A700 {
  color: #616161 !important;
}

.text-blue-gray-50, .text-blue-grey-50 {
  color: #eceff1 !important;
}

.text-blue-gray-100, .text-blue-grey-100 {
  color: #cfd8dc !important;
}

.text-blue-gray-200, .text-blue-grey-200 {
  color: #b0bec5 !important;
}

.text-blue-gray-300, .text-blue-grey-300 {
  color: #90a4ae !important;
}

.text-blue-gray-400, .text-blue-grey-400 {
  color: #78909c !important;
}

.text-blue-gray-500, .text-blue-grey-500 {
  color: #607d8b !important;
}

.text-blue-gray-600, .text-blue-grey-600 {
  color: #546e7a !important;
}

.text-blue-gray-700, .text-blue-grey-700 {
  color: #455a64 !important;
}

.text-blue-gray-800, .text-blue-grey-800 {
  color: #37474f !important;
}

.text-blue-gray-900, .text-blue-grey-900 {
  color: #263238 !important;
}

.text-blue-gray-A100, .text-blue-grey-A100 {
  color: #cfd8dc !important;
}

.text-blue-gray-A200, .text-blue-grey-A200 {
  color: #b0bec5 !important;
}

.text-blue-gray-A400, .text-blue-grey-A400 {
  color: #78909c !important;
}

.text-blue-gray-A700, .text-blue-grey-A700 {
  color: #455a64 !important;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}

.text-light {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark {
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-50 {
  background-color: #ffebee !important;
}

.bg-red-100 {
  background-color: #ffcdd2 !important;
}

.bg-red-200 {
  background-color: #ef9a9a !important;
}

.bg-red-300 {
  background-color: #e57373 !important;
}

.bg-red-400 {
  background-color: #ef5350 !important;
}

.bg-red-500 {
  background-color: #f44336 !important;
}

.bg-red-600 {
  background-color: #e53935 !important;
}

.bg-red-700 {
  background-color: #d32f2f !important;
}

.bg-red-800 {
  background-color: #c62828 !important;
}

.bg-red-900 {
  background-color: #b71c1c !important;
}

.bg-red-A100 {
  background-color: #ff8a80 !important;
}

.bg-red-A200 {
  background-color: #ff5252 !important;
}

.bg-red-A400 {
  background-color: #ff1744 !important;
}

.bg-red-A700 {
  background-color: #d50000 !important;
}

.bg-pink-50 {
  background-color: #fce4ec !important;
}

.bg-pink-100 {
  background-color: #f8bbd0 !important;
}

.bg-pink-200 {
  background-color: #f48fb1 !important;
}

.bg-pink-300 {
  background-color: #f06292 !important;
}

.bg-pink-400 {
  background-color: #ec407a !important;
}

.bg-pink-500 {
  background-color: #e91e63 !important;
}

.bg-pink-600 {
  background-color: #d81b60 !important;
}

.bg-pink-700 {
  background-color: #c2185b !important;
}

.bg-pink-800 {
  background-color: #ad1457 !important;
}

.bg-pink-900 {
  background-color: #880e4f !important;
}

.bg-pink-A100 {
  background-color: #ff80ab !important;
}

.bg-pink-A200 {
  background-color: #ff4081 !important;
}

.bg-pink-A400 {
  background-color: #f50057 !important;
}

.bg-pink-A700 {
  background-color: #c51162 !important;
}

.bg-purple-50 {
  background-color: #f3e5f5 !important;
}

.bg-purple-100 {
  background-color: #e1bee7 !important;
}

.bg-purple-200 {
  background-color: #ce93d8 !important;
}

.bg-purple-300 {
  background-color: #ba68c8 !important;
}

.bg-purple-400 {
  background-color: #ab47bc !important;
}

.bg-purple-500 {
  background-color: #9c27b0 !important;
}

.bg-purple-600 {
  background-color: #8e24aa !important;
}

.bg-purple-700 {
  background-color: #7b1fa2 !important;
}

.bg-purple-800 {
  background-color: #6a1b9a !important;
}

.bg-purple-900 {
  background-color: #4a148c !important;
}

.bg-purple-A100 {
  background-color: #ea80fc !important;
}

.bg-purple-A200 {
  background-color: #e040fb !important;
}

.bg-purple-A400 {
  background-color: #d500f9 !important;
}

.bg-purple-A700 {
  background-color: #aa00ff !important;
}

.bg-deep-purple-50 {
  background-color: #ede7f6 !important;
}

.bg-deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.bg-deep-purple-200 {
  background-color: #b39ddb !important;
}

.bg-deep-purple-300 {
  background-color: #9575cd !important;
}

.bg-deep-purple-400 {
  background-color: #7e57c2 !important;
}

.bg-deep-purple-500 {
  background-color: #673ab7 !important;
}

.bg-deep-purple-600 {
  background-color: #5e35b1 !important;
}

.bg-deep-purple-700 {
  background-color: #512da8 !important;
}

.bg-deep-purple-800 {
  background-color: #4527a0 !important;
}

.bg-deep-purple-900 {
  background-color: #311b92 !important;
}

.bg-deep-purple-A100 {
  background-color: #b388ff !important;
}

.bg-deep-purple-A200 {
  background-color: #7c4dff !important;
}

.bg-deep-purple-A400 {
  background-color: #651fff !important;
}

.bg-deep-purple-A700 {
  background-color: #6200ea !important;
}

.bg-indigo-50 {
  background-color: #e8eaf6 !important;
}

.bg-indigo-100 {
  background-color: #c5cae9 !important;
}

.bg-indigo-200 {
  background-color: #9fa8da !important;
}

.bg-indigo-300 {
  background-color: #7986cb !important;
}

.bg-indigo-400 {
  background-color: #5c6bc0 !important;
}

.bg-indigo-500 {
  background-color: #3f51b5 !important;
}

.bg-indigo-600 {
  background-color: #3949ab !important;
}

.bg-indigo-700 {
  background-color: #303f9f !important;
}

.bg-indigo-800 {
  background-color: #283593 !important;
}

.bg-indigo-900 {
  background-color: #1a237e !important;
}

.bg-indigo-A100 {
  background-color: #8c9eff !important;
}

.bg-indigo-A200 {
  background-color: #536dfe !important;
}

.bg-indigo-A400 {
  background-color: #3d5afe !important;
}

.bg-indigo-A700 {
  background-color: #304ffe !important;
}

.bg-blue-50 {
  background-color: #e3f2fd !important;
}

.bg-blue-100 {
  background-color: #bbdefb !important;
}

.bg-blue-200 {
  background-color: #90caf9 !important;
}

.bg-blue-300 {
  background-color: #64b5f6 !important;
}

.bg-blue-400 {
  background-color: #42a5f5 !important;
}

.bg-blue-500 {
  background-color: #2196f3 !important;
}

.bg-blue-600 {
  background-color: #1e88e5 !important;
}

.bg-blue-700 {
  background-color: #1976d2 !important;
}

.bg-blue-800 {
  background-color: #1565c0 !important;
}

.bg-blue-900 {
  background-color: #0d47a1 !important;
}

.bg-blue-A100 {
  background-color: #82b1ff !important;
}

.bg-blue-A200 {
  background-color: #448aff !important;
}

.bg-blue-A400 {
  background-color: #2979ff !important;
}

.bg-blue-A700 {
  background-color: #2962ff !important;
}

.bg-light-blue-50 {
  background-color: #e1f5fe !important;
}

.bg-light-blue-100 {
  background-color: #b3e5fc !important;
}

.bg-light-blue-200 {
  background-color: #81d4fa !important;
}

.bg-light-blue-300 {
  background-color: #4fc3f7 !important;
}

.bg-light-blue-400 {
  background-color: #29b6f6 !important;
}

.bg-light-blue-500 {
  background-color: #03a9f4 !important;
}

.bg-light-blue-600 {
  background-color: #039be5 !important;
}

.bg-light-blue-700 {
  background-color: #0288d1 !important;
}

.bg-light-blue-800 {
  background-color: #0277bd !important;
}

.bg-light-blue-900 {
  background-color: #01579b !important;
}

.bg-light-blue-A100 {
  background-color: #80d8ff !important;
}

.bg-light-blue-A200 {
  background-color: #40c4ff !important;
}

.bg-light-blue-A400 {
  background-color: #00b0ff !important;
}

.bg-light-blue-A700 {
  background-color: #0091ea !important;
}

.bg-cyan-50 {
  background-color: #e0f7fa !important;
}

.bg-cyan-100 {
  background-color: #b2ebf2 !important;
}

.bg-cyan-200 {
  background-color: #80deea !important;
}

.bg-cyan-300 {
  background-color: #4dd0e1 !important;
}

.bg-cyan-400 {
  background-color: #26c6da !important;
}

.bg-cyan-500 {
  background-color: #00bcd4 !important;
}

.bg-cyan-600 {
  background-color: #00acc1 !important;
}

.bg-cyan-700 {
  background-color: #0097a7 !important;
}

.bg-cyan-800 {
  background-color: #00838f !important;
}

.bg-cyan-900 {
  background-color: #006064 !important;
}

.bg-cyan-A100 {
  background-color: #84ffff !important;
}

.bg-cyan-A200 {
  background-color: #18ffff !important;
}

.bg-cyan-A400 {
  background-color: #00e5ff !important;
}

.bg-cyan-A700 {
  background-color: #00b8d4 !important;
}

.bg-teal-50 {
  background-color: #e0f2f1 !important;
}

.bg-teal-100 {
  background-color: #b2dfdb !important;
}

.bg-teal-200 {
  background-color: #80cbc4 !important;
}

.bg-teal-300 {
  background-color: #4db6ac !important;
}

.bg-teal-400 {
  background-color: #26a69a !important;
}

.bg-teal-500 {
  background-color: #009688 !important;
}

.bg-teal-600 {
  background-color: #00897b !important;
}

.bg-teal-700 {
  background-color: #00796b !important;
}

.bg-teal-800 {
  background-color: #00695c !important;
}

.bg-teal-900 {
  background-color: #004d40 !important;
}

.bg-teal-A100 {
  background-color: #a7ffeb !important;
}

.bg-teal-A200 {
  background-color: #64ffda !important;
}

.bg-teal-A400 {
  background-color: #1de9b6 !important;
}

.bg-teal-A700 {
  background-color: #00bfa5 !important;
}

.bg-green-50 {
  background-color: #e8f5e9 !important;
}

.bg-green-100 {
  background-color: #c8e6c9 !important;
}

.bg-green-200 {
  background-color: #a5d6a7 !important;
}

.bg-green-300 {
  background-color: #81c784 !important;
}

.bg-green-400 {
  background-color: #66bb6a !important;
}

.bg-green-500 {
  background-color: #4caf50 !important;
}

.bg-green-600 {
  background-color: #43a047 !important;
}

.bg-green-700 {
  background-color: #388e3c !important;
}

.bg-green-800 {
  background-color: #2e7d32 !important;
}

.bg-green-900 {
  background-color: #1b5e20 !important;
}

.bg-green-A100 {
  background-color: #b9f6ca !important;
}

.bg-green-A200 {
  background-color: #69f0ae !important;
}

.bg-green-A400 {
  background-color: #00e676 !important;
}

.bg-green-A700 {
  background-color: #00c853 !important;
}

.bg-light-green-50 {
  background-color: #f1f8e9 !important;
}

.bg-light-green-100 {
  background-color: #dcedc8 !important;
}

.bg-light-green-200 {
  background-color: #c5e1a5 !important;
}

.bg-light-green-300 {
  background-color: #aed581 !important;
}

.bg-light-green-400 {
  background-color: #9ccc65 !important;
}

.bg-light-green-500 {
  background-color: #8bc34a !important;
}

.bg-light-green-600 {
  background-color: #7cb342 !important;
}

.bg-light-green-700 {
  background-color: #689f38 !important;
}

.bg-light-green-800 {
  background-color: #558b2f !important;
}

.bg-light-green-900 {
  background-color: #33691e !important;
}

.bg-light-green-A100 {
  background-color: #ccff90 !important;
}

.bg-light-green-A200 {
  background-color: #b2ff59 !important;
}

.bg-light-green-A400 {
  background-color: #76ff03 !important;
}

.bg-light-green-A700 {
  background-color: #64dd17 !important;
}

.bg-lime-50 {
  background-color: #f9fbe7 !important;
}

.bg-lime-100 {
  background-color: #f0f4c3 !important;
}

.bg-lime-200 {
  background-color: #e6ee9c !important;
}

.bg-lime-300 {
  background-color: #dce775 !important;
}

.bg-lime-400 {
  background-color: #d4e157 !important;
}

.bg-lime-500 {
  background-color: #cddc39 !important;
}

.bg-lime-600 {
  background-color: #c0ca33 !important;
}

.bg-lime-700 {
  background-color: #afb42b !important;
}

.bg-lime-800 {
  background-color: #9e9d24 !important;
}

.bg-lime-900 {
  background-color: #827717 !important;
}

.bg-lime-A100 {
  background-color: #f4ff81 !important;
}

.bg-lime-A200 {
  background-color: #eeff41 !important;
}

.bg-lime-A400 {
  background-color: #c6ff00 !important;
}

.bg-lime-A700 {
  background-color: #aeea00 !important;
}

.bg-yellow-50 {
  background-color: #fffde7 !important;
}

.bg-yellow-100 {
  background-color: #fff9c4 !important;
}

.bg-yellow-200 {
  background-color: #fff59d !important;
}

.bg-yellow-300 {
  background-color: #fff176 !important;
}

.bg-yellow-400 {
  background-color: #ffee58 !important;
}

.bg-yellow-500 {
  background-color: #ffeb3b !important;
}

.bg-yellow-600 {
  background-color: #fdd835 !important;
}

.bg-yellow-700 {
  background-color: #fbc02d !important;
}

.bg-yellow-800 {
  background-color: #f9a825 !important;
}

.bg-yellow-900 {
  background-color: #f57f17 !important;
}

.bg-yellow-A100 {
  background-color: #ffff8d !important;
}

.bg-yellow-A200 {
  background-color: #ffff00 !important;
}

.bg-yellow-A400 {
  background-color: #ffea00 !important;
}

.bg-yellow-A700 {
  background-color: #ffd600 !important;
}

.bg-amber-50 {
  background-color: #fff8e1 !important;
}

.bg-amber-100 {
  background-color: #ffecb3 !important;
}

.bg-amber-200 {
  background-color: #ffe082 !important;
}

.bg-amber-300 {
  background-color: #ffd54f !important;
}

.bg-amber-400 {
  background-color: #ffca28 !important;
}

.bg-amber-500 {
  background-color: #ffc107 !important;
}

.bg-amber-600 {
  background-color: #ffb300 !important;
}

.bg-amber-700 {
  background-color: #ffa000 !important;
}

.bg-amber-800 {
  background-color: #ff8f00 !important;
}

.bg-amber-900 {
  background-color: #ff6f00 !important;
}

.bg-amber-A100 {
  background-color: #ffe57f !important;
}

.bg-amber-A200 {
  background-color: #ffd740 !important;
}

.bg-amber-A400 {
  background-color: #ffc400 !important;
}

.bg-amber-A700 {
  background-color: #ffab00 !important;
}

.bg-orange-50 {
  background-color: #fff3e0 !important;
}

.bg-orange-100 {
  background-color: #ffe0b2 !important;
}

.bg-orange-200 {
  background-color: #ffcc80 !important;
}

.bg-orange-300 {
  background-color: #ffb74d !important;
}

.bg-orange-400 {
  background-color: #ffa726 !important;
}

.bg-orange-500 {
  background-color: #ff9800 !important;
}

.bg-orange-600 {
  background-color: #fb8c00 !important;
}

.bg-orange-700 {
  background-color: #f57c00 !important;
}

.bg-orange-800 {
  background-color: #ef6c00 !important;
}

.bg-orange-900 {
  background-color: #e65100 !important;
}

.bg-orange-A100 {
  background-color: #ffd180 !important;
}

.bg-orange-A200 {
  background-color: #ffab40 !important;
}

.bg-orange-A400 {
  background-color: #ff9100 !important;
}

.bg-orange-A700 {
  background-color: #ff6d00 !important;
}

.bg-deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.bg-deep-orange-100 {
  background-color: #ffccbc !important;
}

.bg-deep-orange-200 {
  background-color: #ffab91 !important;
}

.bg-deep-orange-300 {
  background-color: #ff8a65 !important;
}

.bg-deep-orange-400 {
  background-color: #ff7043 !important;
}

.bg-deep-orange-500 {
  background-color: #ff5722 !important;
}

.bg-deep-orange-600 {
  background-color: #f4511e !important;
}

.bg-deep-orange-700 {
  background-color: #e64a19 !important;
}

.bg-deep-orange-800 {
  background-color: #d84315 !important;
}

.bg-deep-orange-900 {
  background-color: #bf360c !important;
}

.bg-deep-orange-A100 {
  background-color: #ff9e80 !important;
}

.bg-deep-orange-A200 {
  background-color: #ff6e40 !important;
}

.bg-deep-orange-A400 {
  background-color: #ff3d00 !important;
}

.bg-deep-orange-A700 {
  background-color: #dd2c00 !important;
}

.bg-brown-50 {
  background-color: #efebe9 !important;
}

.bg-brown-100 {
  background-color: #d7ccc8 !important;
}

.bg-brown-200 {
  background-color: #bcaaa4 !important;
}

.bg-brown-300 {
  background-color: #a1887f !important;
}

.bg-brown-400 {
  background-color: #8d6e63 !important;
}

.bg-brown-500 {
  background-color: #795548 !important;
}

.bg-brown-600 {
  background-color: #6d4c41 !important;
}

.bg-brown-700 {
  background-color: #5d4037 !important;
}

.bg-brown-800 {
  background-color: #4e342e !important;
}

.bg-brown-900 {
  background-color: #3e2723 !important;
}

.bg-brown-A100 {
  background-color: #d7ccc8 !important;
}

.bg-brown-A200 {
  background-color: #bcaaa4 !important;
}

.bg-brown-A400 {
  background-color: #8d6e63 !important;
}

.bg-brown-A700 {
  background-color: #5d4037 !important;
}

.bg-gray-50 {
  background-color: #fafafa !important;
}

.bg-gray-100 {
  background-color: #f5f5f5 !important;
}

.bg-gray-200 {
  background-color: #eeeeee !important;
}

.bg-gray-300 {
  background-color: #e0e0e0 !important;
}

.bg-gray-400 {
  background-color: #bdbdbd !important;
}

.bg-gray-500 {
  background-color: #9e9e9e !important;
}

.bg-gray-600 {
  background-color: #757575 !important;
}

.bg-gray-700 {
  background-color: #616161 !important;
}

.bg-gray-800 {
  background-color: #424242 !important;
}

.bg-gray-900 {
  background-color: #212121 !important;
}

.bg-gray-A100 {
  background-color: #ffffff !important;
}

.bg-gray-A200 {
  background-color: #eeeeee !important;
}

.bg-gray-A400 {
  background-color: #bdbdbd !important;
}

.bg-gray-A700 {
  background-color: #616161 !important;
}

.bg-blue-gray-50, .bg-blue-grey-50 {
  background-color: #eceff1 !important;
}

.bg-blue-gray-100, .bg-blue-grey-100 {
  background-color: #cfd8dc !important;
}

.bg-blue-gray-200, .bg-blue-grey-200 {
  background-color: #b0bec5 !important;
}

.bg-blue-gray-300, .bg-blue-grey-300 {
  background-color: #90a4ae !important;
}

.bg-blue-gray-400, .bg-blue-grey-400 {
  background-color: #78909c !important;
}

.bg-blue-gray-500, .bg-blue-grey-500 {
  background-color: #607d8b !important;
}

.bg-blue-gray-600, .bg-blue-grey-600 {
  background-color: #546e7a !important;
}

.bg-blue-gray-700, .bg-blue-grey-700 {
  background-color: #455a64 !important;
}

.bg-blue-gray-800, .bg-blue-grey-800 {
  background-color: #37474f !important;
}

.bg-blue-gray-900, .bg-blue-grey-900 {
  background-color: #263238 !important;
}

.bg-blue-gray-A100, .bg-blue-grey-A100 {
  background-color: #cfd8dc !important;
}

.bg-blue-gray-A200, .bg-blue-grey-A200 {
  background-color: #b0bec5 !important;
}

.bg-blue-gray-A400, .bg-blue-grey-A400 {
  background-color: #78909c !important;
}

.bg-blue-gray-A700, .bg-blue-grey-A700 {
  background-color: #455a64 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-black {
  background-color: black !important;
}

.bg-light {
  background-color: rgba(255, 255, 255, 0.87) !important;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.badge {
  display: inline-block;
  min-width: 18px;
  padding: 3px 5px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: #757575;
  border-radius: 2px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mtx-data-grid-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.mtx-data-grid-content::-webkit-scrollbar-track, .mtx-data-grid-content::-webkit-scrollbar-thumb {
  border-radius: 999px;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
}
.mtx-data-grid-content::-webkit-scrollbar-track {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px inset;
}
.mtx-data-grid-content::-webkit-scrollbar-thumb {
  min-height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
}
.mtx-data-grid-content::-webkit-scrollbar-corner {
  background: transparent;
}
.mtx-data-grid-content {
  scrollbar-width: thin;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.scrollbar-thin::-webkit-scrollbar-track, .scrollbar-thin::-webkit-scrollbar-thumb {
  border-radius: 999px;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
}
.scrollbar-thin::-webkit-scrollbar-track {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px inset;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
  min-height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
}
.scrollbar-thin::-webkit-scrollbar-corner {
  background: transparent;
}
.scrollbar-thin {
  scrollbar-width: thin;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-app-background-color: #faf9fd;
  --mat-app-text-color: #1a1b1f;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-ripple-color: rgba(26, 27, 31, 0.1);
  --mat-option-selected-state-label-text-color: #131c2b;
  --mat-option-label-text-color: #1a1b1f;
  --mat-option-hover-state-layer-color: rgba(26, 27, 31, 0.08);
  --mat-option-focus-state-layer-color: rgba(26, 27, 31, 0.12);
  --mat-option-selected-state-layer-color: #dae2f9;
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 1.25rem;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: 0.006rem;
  --mat-option-label-text-weight: 400;
  --mat-optgroup-label-text-color: #44474e;
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 1.25rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: 0.006rem;
  --mat-optgroup-label-text-weight: 500;
  --mat-full-pseudo-checkbox-selected-icon-color: #005cbb;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
  --mat-full-pseudo-checkbox-unselected-icon-color: #44474e;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #faf9fd;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(26, 27, 31, 0.38);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(26, 27, 31, 0.38);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005cbb;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(26, 27, 31, 0.38);
  --mdc-elevated-card-container-color: #faf9fd;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
  --mdc-outlined-card-container-color: #faf9fd;
  --mdc-outlined-card-outline-color: #c4c6d0;
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
  --mat-card-subtitle-text-color: #1a1b1f;
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 1.75rem;
  --mat-card-title-text-size: 1.375rem;
  --mat-card-title-text-tracking: 0;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 1.5rem;
  --mat-card-subtitle-text-size: 1rem;
  --mat-card-subtitle-text-tracking: 0.009rem;
  --mat-card-subtitle-text-weight: 500;
  --mdc-linear-progress-active-indicator-color: #005cbb;
  --mdc-linear-progress-track-color: #e0e2ec;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-color: #2f3033;
  --mdc-plain-tooltip-supporting-text-color: #f2f0f4;
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #005cbb;
  --mdc-filled-text-field-focus-active-indicator-color: #005cbb;
  --mdc-filled-text-field-focus-label-text-color: #005cbb;
  --mdc-filled-text-field-container-color: #e0e2ec;
  --mdc-filled-text-field-disabled-container-color: rgba(26, 27, 31, 0.04);
  --mdc-filled-text-field-label-text-color: #44474e;
  --mdc-filled-text-field-hover-label-text-color: #44474e;
  --mdc-filled-text-field-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-text-field-input-text-color: #1a1b1f;
  --mdc-filled-text-field-disabled-input-text-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: #44474e;
  --mdc-filled-text-field-error-hover-label-text-color: #410002;
  --mdc-filled-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-filled-text-field-error-label-text-color: #ba1a1a;
  --mdc-filled-text-field-active-indicator-color: #44474e;
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-text-field-hover-active-indicator-color: #1a1b1f;
  --mdc-filled-text-field-error-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-hover-active-indicator-color: #410002;
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: 0.031rem;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-caret-color: #005cbb;
  --mdc-outlined-text-field-focus-outline-color: #005cbb;
  --mdc-outlined-text-field-focus-label-text-color: #005cbb;
  --mdc-outlined-text-field-label-text-color: #44474e;
  --mdc-outlined-text-field-hover-label-text-color: #1a1b1f;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-outlined-text-field-input-text-color: #1a1b1f;
  --mdc-outlined-text-field-disabled-input-text-color: rgba(26, 27, 31, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: #44474e;
  --mdc-outlined-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-label-text-color: #410002;
  --mdc-outlined-text-field-outline-color: #74777f;
  --mdc-outlined-text-field-disabled-outline-color: rgba(26, 27, 31, 0.12);
  --mdc-outlined-text-field-hover-outline-color: #1a1b1f;
  --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-outline-color: #410002;
  --mdc-outlined-text-field-error-outline-color: #ba1a1a;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: 0.031rem;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mat-form-field-focus-select-arrow-color: #005cbb;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-state-layer-color: #1a1b1f;
  --mat-form-field-error-text-color: #ba1a1a;
  --mat-form-field-select-option-text-color: #1a1b1f;
  --mat-form-field-select-disabled-option-text-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-leading-icon-color: #44474e;
  --mat-form-field-disabled-leading-icon-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-trailing-icon-color: #44474e;
  --mat-form-field-disabled-trailing-icon-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-error-focus-trailing-icon-color: #ba1a1a;
  --mat-form-field-error-hover-trailing-icon-color: #410002;
  --mat-form-field-error-trailing-icon-color: #ba1a1a;
  --mat-form-field-enabled-select-arrow-color: #44474e;
  --mat-form-field-disabled-select-arrow-color: rgba(26, 27, 31, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: 0.031rem;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.025rem;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-select-panel-background-color: #efedf0;
  --mat-select-enabled-trigger-text-color: #1a1b1f;
  --mat-select-disabled-trigger-text-color: rgba(26, 27, 31, 0.38);
  --mat-select-placeholder-text-color: #44474e;
  --mat-select-enabled-arrow-color: #44474e;
  --mat-select-disabled-arrow-color: rgba(26, 27, 31, 0.38);
  --mat-select-focused-arrow-color: #005cbb;
  --mat-select-invalid-arrow-color: #ba1a1a;
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 1.5rem;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: 0.031rem;
  --mat-select-trigger-text-weight: 400;
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #efedf0;
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-color: #faf9fd;
  --mdc-dialog-subhead-color: #1a1b1f;
  --mdc-dialog-supporting-text-color: #44474e;
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 2rem;
  --mdc-dialog-subhead-size: 1.5rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.016rem;
  --mdc-dialog-container-shape: 28px;
  --mat-dialog-container-elevation-shadow: none;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-headline-padding: 6px 24px 13px;
  --mdc-chip-outline-color: #74777f;
  --mdc-chip-disabled-outline-color: rgba(26, 27, 31, 0.12);
  --mdc-chip-focus-outline-color: #44474e;
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-chip-elevated-selected-container-color: #dae2f9;
  --mdc-chip-flat-disabled-selected-container-color: rgba(26, 27, 31, 0.12);
  --mdc-chip-focus-state-layer-color: #44474e;
  --mdc-chip-hover-state-layer-color: #44474e;
  --mdc-chip-selected-hover-state-layer-color: #131c2b;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: #131c2b;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #44474e;
  --mdc-chip-selected-label-text-color: #131c2b;
  --mdc-chip-with-icon-icon-color: #44474e;
  --mdc-chip-with-icon-disabled-icon-color: #1a1b1f;
  --mdc-chip-with-icon-selected-icon-color: #131c2b;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1a1b1f;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #44474e;
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 1.25rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: 0.006rem;
  --mdc-chip-label-text-weight: 500;
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-shape-radius: 24px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mdc-chip-elevated-container-color: transparent;
  --mat-chip-trailing-action-state-layer-color: #44474e;
  --mat-chip-selected-trailing-action-state-layer-color: #131c2b;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: #1a1b1f;
  --mat-chip-selected-trailing-icon-color: #131c2b;
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: #005cbb;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #005cbb;
  --mdc-switch-selected-pressed-state-layer-color: #005cbb;
  --mdc-switch-selected-focus-handle-color: #d7e3ff;
  --mdc-switch-selected-hover-handle-color: #d7e3ff;
  --mdc-switch-selected-pressed-handle-color: #d7e3ff;
  --mdc-switch-selected-focus-track-color: #005cbb;
  --mdc-switch-selected-hover-track-color: #005cbb;
  --mdc-switch-selected-pressed-track-color: #005cbb;
  --mdc-switch-selected-track-color: #005cbb;
  --mdc-switch-disabled-selected-handle-color: #faf9fd;
  --mdc-switch-disabled-selected-icon-color: #1a1b1f;
  --mdc-switch-disabled-selected-track-color: #1a1b1f;
  --mdc-switch-disabled-unselected-handle-color: #1a1b1f;
  --mdc-switch-disabled-unselected-icon-color: #e0e2ec;
  --mdc-switch-disabled-unselected-track-color: #e0e2ec;
  --mdc-switch-selected-icon-color: #001b3f;
  --mdc-switch-unselected-focus-handle-color: #44474e;
  --mdc-switch-unselected-focus-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-focus-track-color: #e0e2ec;
  --mdc-switch-unselected-handle-color: #74777f;
  --mdc-switch-unselected-hover-handle-color: #44474e;
  --mdc-switch-unselected-hover-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-hover-track-color: #e0e2ec;
  --mdc-switch-unselected-icon-color: #e0e2ec;
  --mdc-switch-unselected-pressed-handle-color: #44474e;
  --mdc-switch-unselected-pressed-state-layer-color: #1a1b1f;
  --mdc-switch-unselected-pressed-track-color: #e0e2ec;
  --mdc-switch-unselected-track-color: #e0e2ec;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
  --mat-switch-track-outline-color: #74777f;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
  --mat-switch-label-text-color: #1a1b1f;
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 1.25rem;
  --mat-switch-label-text-size: 0.875rem;
  --mat-switch-label-text-tracking: 0.016rem;
  --mat-switch-label-text-weight: 400;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
  --mdc-radio-disabled-selected-icon-color: #1a1b1f;
  --mdc-radio-disabled-unselected-icon-color: #1a1b1f;
  --mdc-radio-unselected-hover-icon-color: #1a1b1f;
  --mdc-radio-unselected-icon-color: #44474e;
  --mdc-radio-unselected-pressed-icon-color: #1a1b1f;
  --mdc-radio-selected-focus-icon-color: #005cbb;
  --mdc-radio-selected-hover-icon-color: #005cbb;
  --mdc-radio-selected-icon-color: #005cbb;
  --mdc-radio-selected-pressed-icon-color: #005cbb;
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mat-radio-ripple-color: #1a1b1f;
  --mat-radio-checked-ripple-color: #005cbb;
  --mat-radio-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-radio-label-text-color: #1a1b1f;
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 1.25rem;
  --mat-radio-label-text-size: 0.875rem;
  --mat-radio-label-text-tracking: 0.016rem;
  --mat-radio-label-text-weight: 400;
  --mat-radio-touch-target-display: block;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: #005cbb;
  --mdc-slider-focus-handle-color: #005cbb;
  --mdc-slider-hover-handle-color: #005cbb;
  --mdc-slider-active-track-color: #005cbb;
  --mdc-slider-inactive-track-color: #e0e2ec;
  --mdc-slider-with-tick-marks-inactive-container-color: #44474e;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-disabled-active-track-color: #1a1b1f;
  --mdc-slider-disabled-handle-color: #1a1b1f;
  --mdc-slider-disabled-inactive-track-color: #1a1b1f;
  --mdc-slider-label-container-color: #005cbb;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mdc-slider-with-tick-marks-disabled-container-color: #1a1b1f;
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 0.75rem;
  --mdc-slider-label-label-text-line-height: 1rem;
  --mdc-slider-label-label-text-tracking: 0.031rem;
  --mdc-slider-label-label-text-weight: 500;
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
  --mat-slider-ripple-color: #005cbb;
  --mat-slider-hover-state-layer-color: rgba(0, 92, 187, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 92, 187, 0.2);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
  --mat-menu-item-label-text-color: #1a1b1f;
  --mat-menu-item-icon-color: #44474e;
  --mat-menu-item-hover-state-layer-color: rgba(26, 27, 31, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(26, 27, 31, 0.12);
  --mat-menu-container-color: #efedf0;
  --mat-menu-divider-color: #e0e2ec;
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0.006rem;
  --mat-menu-item-label-text-line-height: 1.25rem;
  --mat-menu-item-label-text-weight: 500;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mat-menu-base-elevation-level: 2;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: #d7e3ff;
  --mdc-list-list-item-disabled-state-layer-color: #1a1b1f;
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: #1a1b1f;
  --mdc-list-list-item-supporting-text-color: #44474e;
  --mdc-list-list-item-leading-icon-color: #44474e;
  --mdc-list-list-item-trailing-supporting-text-color: #44474e;
  --mdc-list-list-item-trailing-icon-color: #44474e;
  --mdc-list-list-item-selected-trailing-icon-color: #005cbb;
  --mdc-list-list-item-disabled-label-text-color: #1a1b1f;
  --mdc-list-list-item-disabled-leading-icon-color: #1a1b1f;
  --mdc-list-list-item-disabled-trailing-icon-color: #1a1b1f;
  --mdc-list-list-item-hover-label-text-color: #1a1b1f;
  --mdc-list-list-item-focus-label-text-color: #1a1b1f;
  --mdc-list-list-item-hover-state-layer-color: #1a1b1f;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: #1a1b1f;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: 0.031rem;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.016rem;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.688rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.031rem;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: #dae2f9;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
  --mat-paginator-container-text-color: #1a1b1f;
  --mat-paginator-container-background-color: #faf9fd;
  --mat-paginator-enabled-icon-color: #44474e;
  --mat-paginator-disabled-icon-color: rgba(26, 27, 31, 0.38);
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: 0.025rem;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-color: #005cbb;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: #e0e2ec;
  --mat-tab-header-pagination-icon-color: #1a1b1f;
  --mat-tab-header-inactive-label-text-color: #1a1b1f;
  --mat-tab-header-active-label-text-color: #1a1b1f;
  --mat-tab-header-active-ripple-color: #1a1b1f;
  --mat-tab-header-inactive-ripple-color: #1a1b1f;
  --mat-tab-header-inactive-focus-label-text-color: #1a1b1f;
  --mat-tab-header-inactive-hover-label-text-color: #1a1b1f;
  --mat-tab-header-active-focus-label-text-color: #1a1b1f;
  --mat-tab-header-active-hover-label-text-color: #1a1b1f;
  --mat-tab-header-active-focus-indicator-color: #005cbb;
  --mat-tab-header-active-hover-indicator-color: #005cbb;
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: 0.006rem;
  --mat-tab-header-label-text-line-height: 1.25rem;
  --mat-tab-header-label-text-weight: 500;
  --mat-tab-header-divider-height: 1px;
  --mdc-checkbox-disabled-selected-checkmark-color: #faf9fd;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: #005cbb;
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 27, 31, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 27, 31, 0.38);
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #005cbb;
  --mdc-checkbox-selected-hover-icon-color: #005cbb;
  --mdc-checkbox-selected-icon-color: #005cbb;
  --mdc-checkbox-unselected-focus-icon-color: #1a1b1f;
  --mdc-checkbox-unselected-hover-icon-color: #1a1b1f;
  --mdc-checkbox-unselected-icon-color: #44474e;
  --mdc-checkbox-selected-focus-state-layer-color: #005cbb;
  --mdc-checkbox-selected-hover-state-layer-color: #005cbb;
  --mdc-checkbox-selected-pressed-state-layer-color: #1a1b1f;
  --mdc-checkbox-unselected-focus-state-layer-color: #1a1b1f;
  --mdc-checkbox-unselected-hover-state-layer-color: #1a1b1f;
  --mdc-checkbox-unselected-pressed-state-layer-color: #005cbb;
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 1.25rem;
  --mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-tracking: 0.016rem;
  --mat-checkbox-label-text-weight: 400;
  --mat-checkbox-touch-target-display: block;
  --mdc-text-button-label-text-color: #005cbb;
  --mdc-text-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 0.875rem;
  --mdc-text-button-label-text-tracking: 0.006rem;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: #faf9fd;
  --mdc-protected-button-label-text-color: #005cbb;
  --mdc-protected-button-disabled-container-color: rgba(26, 27, 31, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 0.875rem;
  --mdc-protected-button-label-text-tracking: 0.006rem;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
  --mdc-filled-button-container-color: #005cbb;
  --mdc-filled-button-label-text-color: #ffffff;
  --mdc-filled-button-disabled-container-color: rgba(26, 27, 31, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 0.875rem;
  --mdc-filled-button-label-text-tracking: 0.006rem;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
  --mdc-outlined-button-disabled-outline-color: rgba(26, 27, 31, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
  --mdc-outlined-button-label-text-color: #005cbb;
  --mdc-outlined-button-outline-color: #74777f;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 0.875rem;
  --mdc-outlined-button-label-text-tracking: 0.006rem;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 9999px;
  --mat-text-button-state-layer-color: #005cbb;
  --mat-text-button-disabled-state-layer-color: #44474e;
  --mat-text-button-ripple-color: rgba(0, 92, 187, 0.12);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
  --mat-protected-button-state-layer-color: #005cbb;
  --mat-protected-button-disabled-state-layer-color: #44474e;
  --mat-protected-button-ripple-color: rgba(0, 92, 187, 0.12);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  --mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
  --mat-filled-button-state-layer-color: #ffffff;
  --mat-filled-button-disabled-state-layer-color: #44474e;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
  --mat-outlined-button-state-layer-color: #005cbb;
  --mat-outlined-button-disabled-state-layer-color: #44474e;
  --mat-outlined-button-ripple-color: rgba(0, 92, 187, 0.12);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
  --mdc-icon-button-icon-color: #44474e;
  --mdc-icon-button-disabled-icon-color: rgba(26, 27, 31, 0.38);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
  --mat-icon-button-state-layer-color: #44474e;
  --mat-icon-button-disabled-state-layer-color: #44474e;
  --mat-icon-button-ripple-color: rgba(68, 71, 78, 0.12);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: 0.006rem;
  --mdc-extended-fab-label-text-weight: 500;
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: #d7e3ff;
  --mdc-fab-container-shape: 16px;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: #d7e3ff;
  --mdc-fab-small-container-shape: 12px;
  --mat-fab-foreground-color: #001b3f;
  --mat-fab-state-layer-color: #001b3f;
  --mat-fab-ripple-color: rgba(0, 27, 63, 0.12);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(26, 27, 31, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(26, 27, 31, 0.38);
  --mat-fab-touch-target-display: block;
  --mat-fab-small-foreground-color: #001b3f;
  --mat-fab-small-state-layer-color: #001b3f;
  --mat-fab-small-ripple-color: rgba(0, 27, 63, 0.12);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(26, 27, 31, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(26, 27, 31, 0.38);
  --mdc-snackbar-container-color: #2f3033;
  --mdc-snackbar-supporting-text-color: #f2f0f4;
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
  --mdc-snackbar-container-shape: 4px;
  --mat-snack-bar-button-color: #abc7ff;
  --mat-table-background-color: #faf9fd;
  --mat-table-header-headline-color: #1a1b1f;
  --mat-table-row-item-label-text-color: #1a1b1f;
  --mat-table-row-item-outline-color: #74777f;
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.006rem;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.016rem;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.016rem;
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
  --mdc-circular-progress-active-indicator-color: #005cbb;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mat-badge-background-color: #ba1a1a;
  --mat-badge-text-color: #ffffff;
  --mat-badge-disabled-state-background-color: rgba(186, 26, 26, 0.38);
  --mat-badge-disabled-state-text-color: #ffffff;
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 0.688rem;
  --mat-badge-text-weight: 500;
  --mat-badge-large-size-text-size: 0.688rem;
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: 16px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 16px;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-small-size-text-size: 0;
  --mat-bottom-sheet-container-text-color: #1a1b1f;
  --mat-bottom-sheet-container-background-color: #f4f3f6;
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 1.5rem;
  --mat-bottom-sheet-container-text-size: 1rem;
  --mat-bottom-sheet-container-text-tracking: 0.031rem;
  --mat-bottom-sheet-container-text-weight: 400;
  --mat-bottom-sheet-container-shape: 28px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: #1a1b1f;
  --mat-standard-button-toggle-state-layer-color: #1a1b1f;
  --mat-standard-button-toggle-selected-state-background-color: #dae2f9;
  --mat-standard-button-toggle-selected-state-text-color: #131c2b;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(26, 27, 31, 0.12);
  --mat-standard-button-toggle-divider-color: #74777f;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.006rem;
  --mat-standard-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
  --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
  --mat-datepicker-calendar-date-selected-state-background-color: #005cbb;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #005cbb;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(26, 27, 31, 0.12);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(26, 27, 31, 0.08);
  --mat-datepicker-toggle-active-state-icon-color: #44474e;
  --mat-datepicker-calendar-date-in-range-state-background-color: #d7e3ff;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #e0e0ff;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #dae2f9;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #565e71;
  --mat-datepicker-toggle-icon-color: #44474e;
  --mat-datepicker-calendar-body-label-text-color: #1a1b1f;
  --mat-datepicker-calendar-period-button-text-color: #44474e;
  --mat-datepicker-calendar-period-button-icon-color: #44474e;
  --mat-datepicker-calendar-navigation-button-icon-color: #44474e;
  --mat-datepicker-calendar-header-text-color: #44474e;
  --mat-datepicker-calendar-date-today-outline-color: #005cbb;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-date-text-color: #1a1b1f;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: #005cbb;
  --mat-datepicker-range-input-separator-color: #1a1b1f;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-datepicker-calendar-container-background-color: #e9e7eb;
  --mat-datepicker-calendar-container-text-color: #1a1b1f;
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 0.875rem;
  --mat-datepicker-calendar-header-text-weight: 500;
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-divider-color: #74777f;
  --mat-divider-width: 1px;
  --mat-expansion-container-background-color: #faf9fd;
  --mat-expansion-container-text-color: #1a1b1f;
  --mat-expansion-actions-divider-color: #74777f;
  --mat-expansion-header-hover-state-layer-color: rgba(26, 27, 31, 0.08);
  --mat-expansion-header-focus-state-layer-color: rgba(26, 27, 31, 0.12);
  --mat-expansion-header-disabled-state-text-color: rgba(26, 27, 31, 0.38);
  --mat-expansion-header-text-color: #1a1b1f;
  --mat-expansion-header-description-color: #44474e;
  --mat-expansion-header-indicator-color: #44474e;
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 1rem;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: 1.5rem;
  --mat-expansion-header-text-tracking: 0.009rem;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 1.5rem;
  --mat-expansion-container-text-size: 1rem;
  --mat-expansion-container-text-tracking: 0.031rem;
  --mat-expansion-container-text-weight: 400;
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-container-shape: 12px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
  --mat-grid-list-tile-header-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
  --mat-grid-list-tile-header-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
  --mat-grid-list-tile-footer-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
  --mat-grid-list-tile-footer-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
  --mat-icon-color: inherit;
  --mat-sidenav-container-background-color: #faf9fd;
  --mat-sidenav-container-text-color: #44474e;
  --mat-sidenav-content-background-color: #faf9fd;
  --mat-sidenav-content-text-color: #1a1b1f;
  --mat-sidenav-scrim-color: rgba(45, 48, 56, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
  --mat-stepper-header-icon-foreground-color: #faf9fd;
  --mat-stepper-header-selected-state-icon-background-color: #005cbb;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  --mat-stepper-header-edit-state-icon-background-color: #005cbb;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
  --mat-stepper-container-color: #faf9fd;
  --mat-stepper-line-color: #74777f;
  --mat-stepper-header-hover-state-layer-color: rgba(47, 48, 51, 0.08);
  --mat-stepper-header-focus-state-layer-color: rgba(47, 48, 51, 0.12);
  --mat-stepper-header-label-text-color: #44474e;
  --mat-stepper-header-optional-label-text-color: #44474e;
  --mat-stepper-header-selected-state-label-text-color: #44474e;
  --mat-stepper-header-error-state-label-text-color: #ba1a1a;
  --mat-stepper-header-icon-background-color: #44474e;
  --mat-stepper-header-error-state-icon-foreground-color: #ba1a1a;
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #1a1b1f;
  --mat-toolbar-container-background-color: #faf9fd;
  --mat-toolbar-container-text-color: #1a1b1f;
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 1.75rem;
  --mat-toolbar-title-text-size: 1.375rem;
  --mat-toolbar-title-text-tracking: 0;
  --mat-toolbar-title-text-weight: 400;
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
  --mat-tree-container-background-color: #faf9fd;
  --mat-tree-node-text-color: #1a1b1f;
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 1rem;
  --mat-tree-node-text-weight: 400;
  --mat-tree-node-min-height: 48px;
  --body-background-color: #efedf0;
  --link-color: #005cbb;
  --link-hover-color: #004388;
  --code-border-color: rgba(19, 28, 43, 0.08);
  --code-background-color: rgba(19, 28, 43, 0.04);
  --header-background-color: rgba(239, 237, 240, 0.8);
  --user-panel-background-color: #efedf0;
  --user-panel-hover-background-color: #e0e2ec;
  --sidemenu-heading-hover-background-color: #e3e2e6;
  --sidemenu-active-heading-text-color: #005cbb;
  --sidemenu-active-heading-background-color: #d7e3ff;
  --sidemenu-active-heading-hover-background-color: #d7e3ff;
  --sidemenu-expanded-background-color: #efedf0;
  --topmenu-text-color: #1a1b1f;
  --topmenu-background-color: rgba(239, 237, 240, 0.8);
  --topmenu-item-active-background-color: #d7e3ff;
  --topmenu-dropdown-item-active-text-color: #005cbb;
  --toast-info-background-color: #0074e9;
  --toast-success-background-color: #03a800;
  --toast-warning-background-color: #e37100;
  --toast-error-background-color: #ef0000;
  --mdc-icon-button-icon-color: rgb(255, 255, 255);
  --mat-sidenav-container-width: 240px;
  --mat-card-title-text-weight: 500;
  --mat-mdc-focus-indicator-border-radius: 0;
}
.mat-theme-loaded-marker {
  display: none;
}

html {
  --mtx-alert-outline-color: #c4c6d0;
}
html {
  --mtx-alert-background-color: #efedf0;
}
html {
  --mtx-alert-text-color: #1a1b1f;
}
html {
  --mtx-alert-info-outline-color: #abc7ff;
}
html {
  --mtx-alert-info-background-color: #d7e3ff;
}
html {
  --mtx-alert-info-text-color: #002f65;
}
html {
  --mtx-alert-success-outline-color: #02e600;
}
html {
  --mtx-alert-success-background-color: #cbffb8;
}
html {
  --mtx-alert-success-text-color: #013a00;
}
html {
  --mtx-alert-warning-outline-color: #cdcd00;
}
html {
  --mtx-alert-warning-background-color: #fffeac;
}
html {
  --mtx-alert-warning-text-color: #323200;
}
html {
  --mtx-alert-danger-outline-color: #ffb4a8;
}
html {
  --mtx-alert-danger-background-color: #ffdad4;
}
html {
  --mtx-alert-danger-text-color: #690100;
}
html {
  --mtx-alert-container-shape: 8px;
}
html {
  --mtx-colorpicker-toggle-active-state-icon-color: #44474e;
}
html {
  --mtx-colorpicker-toggle-icon-color: #44474e;
}
html {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: #ffffff;
}
html {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #005cbb;
}
html {
  --mtx-datetimepicker-calendar-date-selected-disabled-state-background-color: rgba(26, 27, 31, 0.38);
}
html {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: #005cbb;
}
html {
  --mtx-datetimepicker-calendar-date-focus-state-background-color: rgba(26, 27, 31, 0.12);
}
html {
  --mtx-datetimepicker-calendar-date-hover-state-background-color: rgba(26, 27, 31, 0.08);
}
html {
  --mtx-datetimepicker-clock-hand-background-color: #005cbb;
}
html {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #005cbb;
}
html {
  --mtx-datetimepicker-time-input-active-state-text-color: #001b3f;
}
html {
  --mtx-datetimepicker-time-input-active-state-background-color: #d7e3ff;
}
html {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #005cbb;
}
html {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: #001b3f;
}
html {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: #e0e0ff;
}
html {
  --mtx-datetimepicker-toggle-active-state-icon-color: #44474e;
}
html {
  --mtx-datetimepicker-toggle-icon-color: #44474e;
}
html {
  --mtx-datetimepicker-calendar-body-label-text-color: #1a1b1f;
}
html {
  --mtx-datetimepicker-calendar-header-text-color: #44474e;
}
html {
  --mtx-datetimepicker-calendar-header-divider-color: #c4c6d0;
}
html {
  --mtx-datetimepicker-calendar-table-header-text-color: #1a1b1f;
}
html {
  --mtx-datetimepicker-calendar-date-today-outline-color: #005cbb;
}
html {
  --mtx-datetimepicker-calendar-date-text-color: #1a1b1f;
}
html {
  --mtx-datetimepicker-calendar-date-disabled-state-text-color: rgba(26, 27, 31, 0.38);
}
html {
  --mtx-datetimepicker-container-background-color: #e9e7eb;
}
html {
  --mtx-datetimepicker-container-text-color: #1a1b1f;
}
html {
  --mtx-datetimepicker-clock-dial-background-color: #e3e2e6;
}
html {
  --mtx-datetimepicker-clock-cell-text-color: #1a1b1f;
}
html {
  --mtx-datetimepicker-clock-cell-hover-state-background-color: rgba(26, 27, 31, 0.08);
}
html {
  --mtx-datetimepicker-clock-cell-disabled-state-text-color: rgba(26, 27, 31, 0.38);
}
html {
  --mtx-datetimepicker-time-input-text-color: #1a1b1f;
}
html {
  --mtx-datetimepicker-time-input-background-color: #e3e2e6;
}
html {
  --mtx-datetimepicker-time-input-focus-state-background-color: #d7e3ff;
}
html {
  --mtx-datetimepicker-time-input-warn-state-outline-color: #ba1a1a;
}
html {
  --mtx-datetimepicker-time-ampm-text-color: #1a1b1f;
}
html {
  --mtx-datetimepicker-time-ampm-outline-color: #74777f;
}
html {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: #00006e;
}
html {
  --mtx-datetimepicker-calendar-text-font: Roboto, sans-serif;
}
html {
  --mtx-datetimepicker-calendar-text-size: 1rem;
}
html {
  --mtx-datetimepicker-calendar-body-label-text-size: 0.875rem;
}
html {
  --mtx-datetimepicker-calendar-body-label-text-weight: 500;
}
html {
  --mtx-datetimepicker-calendar-period-button-text-size: 0.875rem;
}
html {
  --mtx-datetimepicker-calendar-period-button-text-weight: 500;
}
html {
  --mtx-datetimepicker-clock-text-size: 0.875rem;
}
html {
  --mtx-datetimepicker-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-container-shape: 16px;
}
html {
  --mtx-datetimepicker-container-touch-shape: 28px;
}
html {
  --mtx-datetimepicker-selector-container-shape: 8px;
}
html {
  --mtx-datetimepicker-calendar-header-background-color: transparent;
}
html {
  --mtx-datetimepicker-calendar-date-outline-color: transparent;
}
html {
  --mtx-drawer-container-background-color: #fdfbff;
}
html {
  --mtx-drawer-container-text-color: #44474e;
}
html {
  --mtx-drawer-container-shape: 16px;
}
html {
  --mtx-drawer-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-grid-outline-color: #c4c6d0;
}
html {
  --mtx-grid-column-menu-text-color: #44474e;
}
html {
  --mtx-grid-column-menu-divider-color: #c4c6d0;
}
html {
  --mtx-grid-table-footer-background-color: #efedf0;
}
html {
  --mtx-grid-table-row-striped-background-color: #efedf0;
}
html {
  --mtx-grid-table-row-hover-background-color: #dae2f9;
}
html {
  --mtx-grid-table-row-selected-background-color: #dae2f9;
}
html {
  --mtx-grid-table-row-selected-hover-background-color: #d7e3ff;
}
html {
  --mtx-grid-table-cell-selected-outline-color: #005cbb;
}
html {
  --mtx-grid-resizable-handle-active-background-color: #005cbb;
}
html {
  --mtx-grid-resizable-handle-hover-background-color: #005cbb;
}
html {
  --mtx-grid-resizable-handle-disabled-background-color: #c4c6d0;
}
html {
  --mtx-grid-container-shape: 12px;
}
html {
  --mtx-grid-table-cell-min-width: 80px;
}
html {
  --mtx-loader-backdrop-background-color: rgba(253, 251, 255, 0.75);
}
html {
  --mtx-popover-outline-color: #efedf0;
}
html {
  --mtx-popover-background-color: #efedf0;
}
html {
  --mtx-popover-text-color: #1a1b1f;
}
html {
  --mtx-popover-container-shape: 4px;
}
html {
  --mtx-popover-base-elevation-level: 2;
}
html {
  --mtx-progress-track-color: #efedf0;
}
html {
  --mtx-progress-indicator-color: #c4c6d0;
}
html {
  --mtx-progress-text-color: #1a1b1f;
}
html {
  --mtx-progress-info-indicator-color: #5a64ff;
}
html {
  --mtx-progress-success-indicator-color: #038b00;
}
html {
  --mtx-progress-warning-indicator-color: #bc5d00;
}
html {
  --mtx-progress-danger-indicator-color: #ef0000;
}
html {
  --mtx-progress-text-size: 0.75rem;
}
html {
  --mtx-progress-container-shape: 4px;
}
html {
  --mtx-progress-info-text-color: white;
}
html {
  --mtx-progress-success-text-color: white;
}
html {
  --mtx-progress-warning-text-color: white;
}
html {
  --mtx-progress-danger-text-color: white;
}
html {
  --mtx-select-option-selected-state-text-color: #1a1b1f;
}
html {
  --mtx-select-container-text-color: #1a1b1f;
}
html {
  --mtx-select-placeholder-text-color: rgba(26, 27, 31, 0.38);
}
html {
  --mtx-select-disabled-text-color: rgba(26, 27, 31, 0.38);
}
html {
  --mtx-select-multiple-value-outline-color: #74777f;
}
html {
  --mtx-select-multiple-value-icon-hover-background-color: #c4c6d0;
}
html {
  --mtx-select-clear-icon-color: #1a1b1f;
}
html {
  --mtx-select-clear-icon-hover-color: #ba1a1a;
}
html {
  --mtx-select-enabled-arrow-color: #1a1b1f;
}
html {
  --mtx-select-disabled-arrow-color: rgba(26, 27, 31, 0.38);
}
html {
  --mtx-select-invalid-arrow-color: #ba1a1a;
}
html {
  --mtx-select-panel-background-color: #efedf0;
}
html {
  --mtx-select-panel-divider-color: #74777f;
}
html {
  --mtx-select-optgroup-label-text-color: #1a1b1f;
}
html {
  --mtx-select-option-label-text-color: #1a1b1f;
}
html {
  --mtx-select-option-selected-state-background-color: #dae2f9;
}
html {
  --mtx-select-option-hover-state-background-color: rgba(26, 27, 31, 0.08);
}
html {
  --mtx-select-option-disabled-state-text-color: rgba(26, 27, 31, 0.38);
}
html {
  --mtx-select-container-shape: 4px;
}
html {
  --mtx-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-select-multiple-value-background-color: transparent;
}
html {
  --mtx-split-gutter-hover-state-background-color: #005cbb;
}
html {
  --mtx-split-gutter-background-color: #c4c6d0;
}
html {
  --mdc-plain-tooltip-container-color: #2f3033;
}
html {
  --mdc-plain-tooltip-supporting-text-color: #f2f0f4;
}
html {
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
}
html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
}
html {
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
}
html {
  --mdc-plain-tooltip-supporting-text-weight: 400;
}
html {
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
}
html {
  --mdc-plain-tooltip-container-shape: 4px;
}
html .mat-primary {
  --mat-stepper-header-selected-state-icon-background-color: #005cbb;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  --mat-stepper-header-edit-state-icon-background-color: #005cbb;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
}
html .mat-primary.mat-icon {
  --mat-icon-color: #005cbb;
}
html .mat-primary.mat-mdc-checkbox {
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
}
html .mat-primary.mat-mdc-slide-toggle {
  --mat-switch-track-outline-color: #74777f;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
  --mat-switch-label-text-color: #1a1b1f;
}
html .mat-primary.mat-mdc-button-base {
  --mdc-icon-button-icon-color: #005cbb;
  --mat-icon-button-state-layer-color: #005cbb;
  --mat-icon-button-ripple-color: rgba(0, 92, 187, 0.12);
}
html .mat-primary .mdc-list-item__start,
html .mat-primary .mdc-list-item__end {
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
}
html .mat-badge {
  --mat-badge-background-color: #005cbb;
  --mat-badge-text-color: #ffffff;
  --mat-badge-disabled-state-background-color: rgba(0, 92, 187, 0.38);
  --mat-badge-disabled-state-text-color: #ffffff;
}
html .mat-accent {
  --mat-option-selected-state-label-text-color: #00006e;
  --mat-option-selected-state-layer-color: #e0e0ff;
  --mdc-circular-progress-active-indicator-color: #343dff;
  --mat-full-pseudo-checkbox-selected-icon-color: #343dff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #343dff;
  --mat-stepper-header-selected-state-icon-background-color: #343dff;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  --mat-stepper-header-edit-state-icon-background-color: #343dff;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
}
html .mat-accent.mat-icon {
  --mat-icon-color: #343dff;
}
html .mat-accent.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: #343dff;
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #343dff;
  --mdc-checkbox-selected-hover-icon-color: #343dff;
  --mdc-checkbox-selected-icon-color: #343dff;
  --mdc-checkbox-selected-focus-state-layer-color: #343dff;
  --mdc-checkbox-selected-hover-state-layer-color: #343dff;
  --mdc-checkbox-unselected-pressed-state-layer-color: #343dff;
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
}
html .mat-accent.mat-mdc-slider {
  --mdc-slider-handle-color: #343dff;
  --mdc-slider-focus-handle-color: #343dff;
  --mdc-slider-hover-handle-color: #343dff;
  --mdc-slider-active-track-color: #343dff;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-label-container-color: #343dff;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mat-slider-ripple-color: #343dff;
  --mat-slider-hover-state-layer-color: rgba(52, 61, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(52, 61, 255, 0.2);
}
html .mat-accent.mat-mdc-tab-group, html .mat-accent.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #343dff;
  --mat-tab-header-active-focus-indicator-color: #343dff;
  --mat-tab-header-active-hover-indicator-color: #343dff;
}
html .mat-accent.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #343dff;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #343dff;
  --mdc-switch-selected-pressed-state-layer-color: #343dff;
  --mdc-switch-selected-focus-handle-color: #e0e0ff;
  --mdc-switch-selected-hover-handle-color: #e0e0ff;
  --mdc-switch-selected-pressed-handle-color: #e0e0ff;
  --mdc-switch-selected-focus-track-color: #343dff;
  --mdc-switch-selected-hover-track-color: #343dff;
  --mdc-switch-selected-pressed-track-color: #343dff;
  --mdc-switch-selected-track-color: #343dff;
  --mdc-switch-selected-icon-color: #00006e;
  --mat-switch-track-outline-color: #74777f;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
  --mat-switch-label-text-color: #1a1b1f;
}
html .mat-accent.mat-mdc-form-field {
  --mat-select-focused-arrow-color: #343dff;
}
html .mat-accent.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: #343dff;
  --mdc-radio-selected-hover-icon-color: #343dff;
  --mdc-radio-selected-icon-color: #343dff;
  --mdc-radio-selected-pressed-icon-color: #343dff;
  --mat-radio-checked-ripple-color: #343dff;
}
html .mat-accent.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #343dff;
  --mdc-linear-progress-track-color: #e0e0ff;
}
html .mat-accent.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: #343dff;
  --mdc-filled-text-field-focus-active-indicator-color: #343dff;
  --mdc-filled-text-field-focus-label-text-color: #343dff;
  --mdc-outlined-text-field-caret-color: #343dff;
  --mdc-outlined-text-field-focus-outline-color: #343dff;
  --mdc-outlined-text-field-focus-label-text-color: #343dff;
  --mat-form-field-focus-select-arrow-color: #343dff;
}
html .mat-accent.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
  --mat-datepicker-calendar-date-selected-state-background-color: #343dff;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #343dff;
  --mat-datepicker-calendar-date-in-range-state-background-color: #e0e0ff;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #d7e3ff;
  --mat-datepicker-calendar-date-today-outline-color: #343dff;
  --mat-datepicker-calendar-date-preview-state-outline-color: #343dff;
}
html .mat-accent.mat-mdc-button-base {
  --mdc-text-button-label-text-color: #343dff;
  --mdc-protected-button-label-text-color: #343dff;
  --mdc-filled-button-container-color: #343dff;
  --mdc-filled-button-label-text-color: #ffffff;
  --mdc-outlined-button-label-text-color: #343dff;
  --mat-text-button-state-layer-color: #343dff;
  --mat-text-button-ripple-color: rgba(52, 61, 255, 0.12);
  --mat-protected-button-state-layer-color: #343dff;
  --mat-protected-button-ripple-color: rgba(52, 61, 255, 0.12);
  --mat-filled-button-state-layer-color: #ffffff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #343dff;
  --mat-outlined-button-ripple-color: rgba(52, 61, 255, 0.12);
  --mdc-icon-button-icon-color: #343dff;
  --mat-icon-button-state-layer-color: #343dff;
  --mat-icon-button-ripple-color: rgba(52, 61, 255, 0.12);
}
html .mat-accent.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: #e0e0ff;
  --mdc-chip-selected-hover-state-layer-color: #00006e;
  --mdc-chip-selected-focus-state-layer-color: #00006e;
  --mdc-chip-selected-label-text-color: #00006e;
  --mdc-chip-with-icon-selected-icon-color: #00006e;
  --mat-chip-selected-trailing-action-state-layer-color: #00006e;
  --mat-chip-selected-trailing-icon-color: #00006e;
}
html .mat-accent .mdc-list-item__start,
html .mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: #343dff;
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #343dff;
  --mdc-checkbox-selected-hover-icon-color: #343dff;
  --mdc-checkbox-selected-icon-color: #343dff;
  --mdc-checkbox-selected-focus-state-layer-color: #343dff;
  --mdc-checkbox-selected-hover-state-layer-color: #343dff;
  --mdc-checkbox-unselected-pressed-state-layer-color: #343dff;
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
  --mdc-radio-selected-focus-icon-color: #343dff;
  --mdc-radio-selected-hover-icon-color: #343dff;
  --mdc-radio-selected-icon-color: #343dff;
  --mdc-radio-selected-pressed-icon-color: #343dff;
  --mat-radio-checked-ripple-color: #343dff;
}
html .mat-accent.mat-mdc-fab, html .mat-accent.mat-mdc-mini-fab {
  --mdc-fab-container-color: #e0e0ff;
  --mdc-fab-small-container-color: #e0e0ff;
  --mat-fab-foreground-color: #00006e;
  --mat-fab-state-layer-color: #00006e;
  --mat-fab-ripple-color: rgba(0, 0, 110, 0.12);
  --mat-fab-small-foreground-color: #00006e;
  --mat-fab-small-state-layer-color: #00006e;
  --mat-fab-small-ripple-color: rgba(0, 0, 110, 0.12);
}
html .mat-badge-accent {
  --mat-badge-background-color: #343dff;
  --mat-badge-text-color: #ffffff;
  --mat-badge-disabled-state-background-color: rgba(52, 61, 255, 0.38);
  --mat-badge-disabled-state-text-color: #ffffff;
}
html .mat-warn {
  --mat-option-selected-state-label-text-color: #410002;
  --mat-option-selected-state-layer-color: #ffdad6;
  --mdc-circular-progress-active-indicator-color: #ba1a1a;
  --mat-full-pseudo-checkbox-selected-icon-color: #ba1a1a;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ba1a1a;
  --mat-stepper-header-selected-state-icon-background-color: #ba1a1a;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  --mat-stepper-header-edit-state-icon-background-color: #ba1a1a;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
}
html .mat-warn.mat-icon {
  --mat-icon-color: #ba1a1a;
}
html .mat-warn.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: #ba1a1a;
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #ba1a1a;
  --mdc-checkbox-selected-hover-icon-color: #ba1a1a;
  --mdc-checkbox-selected-icon-color: #ba1a1a;
  --mdc-checkbox-selected-focus-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-hover-state-layer-color: #ba1a1a;
  --mdc-checkbox-unselected-pressed-state-layer-color: #ba1a1a;
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
}
html .mat-warn.mat-mdc-slider {
  --mdc-slider-handle-color: #ba1a1a;
  --mdc-slider-focus-handle-color: #ba1a1a;
  --mdc-slider-hover-handle-color: #ba1a1a;
  --mdc-slider-active-track-color: #ba1a1a;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-label-container-color: #ba1a1a;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mat-slider-ripple-color: #ba1a1a;
  --mat-slider-hover-state-layer-color: rgba(186, 26, 26, 0.05);
  --mat-slider-focus-state-layer-color: rgba(186, 26, 26, 0.2);
}
html .mat-warn.mat-mdc-tab-group, html .mat-warn.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ba1a1a;
  --mat-tab-header-active-focus-indicator-color: #ba1a1a;
  --mat-tab-header-active-hover-indicator-color: #ba1a1a;
}
html .mat-warn.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #ba1a1a;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #ba1a1a;
  --mdc-switch-selected-pressed-state-layer-color: #ba1a1a;
  --mdc-switch-selected-focus-handle-color: #ffdad6;
  --mdc-switch-selected-hover-handle-color: #ffdad6;
  --mdc-switch-selected-pressed-handle-color: #ffdad6;
  --mdc-switch-selected-focus-track-color: #ba1a1a;
  --mdc-switch-selected-hover-track-color: #ba1a1a;
  --mdc-switch-selected-pressed-track-color: #ba1a1a;
  --mdc-switch-selected-track-color: #ba1a1a;
  --mdc-switch-selected-icon-color: #410002;
  --mat-switch-track-outline-color: #74777f;
  --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
  --mat-switch-label-text-color: #1a1b1f;
}
html .mat-warn.mat-mdc-form-field {
  --mat-select-focused-arrow-color: #ba1a1a;
}
html .mat-warn.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: #ba1a1a;
  --mdc-radio-selected-hover-icon-color: #ba1a1a;
  --mdc-radio-selected-icon-color: #ba1a1a;
  --mdc-radio-selected-pressed-icon-color: #ba1a1a;
  --mat-radio-checked-ripple-color: #ba1a1a;
}
html .mat-warn.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ba1a1a;
  --mdc-linear-progress-track-color: #ffdad6;
}
html .mat-warn.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: #ba1a1a;
  --mdc-filled-text-field-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-focus-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-caret-color: #ba1a1a;
  --mdc-outlined-text-field-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-focus-label-text-color: #ba1a1a;
  --mat-form-field-focus-select-arrow-color: #ba1a1a;
}
html .mat-warn.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
  --mat-datepicker-calendar-date-selected-state-background-color: #ba1a1a;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #ba1a1a;
  --mat-datepicker-calendar-date-in-range-state-background-color: #ffdad6;
  --mat-datepicker-calendar-date-today-outline-color: #ba1a1a;
  --mat-datepicker-calendar-date-preview-state-outline-color: #ba1a1a;
}
html .mat-warn.mat-mdc-button-base {
  --mdc-text-button-label-text-color: #ba1a1a;
  --mdc-protected-button-label-text-color: #ba1a1a;
  --mdc-filled-button-container-color: #ba1a1a;
  --mdc-filled-button-label-text-color: #ffffff;
  --mdc-outlined-button-label-text-color: #ba1a1a;
  --mat-text-button-state-layer-color: #ba1a1a;
  --mat-text-button-ripple-color: rgba(186, 26, 26, 0.12);
  --mat-protected-button-state-layer-color: #ba1a1a;
  --mat-protected-button-ripple-color: rgba(186, 26, 26, 0.12);
  --mat-filled-button-state-layer-color: #ffffff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #ba1a1a;
  --mat-outlined-button-ripple-color: rgba(186, 26, 26, 0.12);
  --mdc-icon-button-icon-color: #ba1a1a;
  --mat-icon-button-state-layer-color: #ba1a1a;
  --mat-icon-button-ripple-color: rgba(186, 26, 26, 0.12);
}
html .mat-warn.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: #ffdad6;
  --mdc-chip-selected-hover-state-layer-color: #410002;
  --mdc-chip-selected-focus-state-layer-color: #410002;
  --mdc-chip-selected-label-text-color: #410002;
  --mdc-chip-with-icon-selected-icon-color: #410002;
  --mat-chip-selected-trailing-action-state-layer-color: #410002;
  --mat-chip-selected-trailing-icon-color: #410002;
}
html .mat-warn .mdc-list-item__start,
html .mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: #ba1a1a;
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #ba1a1a;
  --mdc-checkbox-selected-hover-icon-color: #ba1a1a;
  --mdc-checkbox-selected-icon-color: #ba1a1a;
  --mdc-checkbox-selected-focus-state-layer-color: #ba1a1a;
  --mdc-checkbox-selected-hover-state-layer-color: #ba1a1a;
  --mdc-checkbox-unselected-pressed-state-layer-color: #ba1a1a;
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
  --mdc-radio-selected-focus-icon-color: #ba1a1a;
  --mdc-radio-selected-hover-icon-color: #ba1a1a;
  --mdc-radio-selected-icon-color: #ba1a1a;
  --mdc-radio-selected-pressed-icon-color: #ba1a1a;
  --mat-radio-checked-ripple-color: #ba1a1a;
}
html .mat-accent.mtx-split-gutter {
  --mtx-split-gutter-hover-state-background-color: #343dff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: #ffffff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #343dff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: #343dff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-hand-background-color: #343dff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #343dff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-text-color: #00006e;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-background-color: #e0e0ff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #343dff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: #00006e;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: #ffdad6;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-outline-color: #343dff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-background-color: #e0e0ff;
}
html .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: #410002;
}
html .mat-accent.ng-dropdown-panel {
  --mtx-select-option-selected-state-background-color: #e0e0ff;
}
html .mat-warn.mtx-split-gutter {
  --mtx-split-gutter-hover-state-background-color: #ba1a1a;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: #ffffff;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #ba1a1a;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: #ba1a1a;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-hand-background-color: #ba1a1a;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #ba1a1a;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-text-color: #410002;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-background-color: #ffdad6;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #ba1a1a;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: #410002;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: #d7e3ff;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-outline-color: #ba1a1a;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-background-color: #ffdad6;
}
html .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: #001b3f;
}
html .mat-warn.ng-dropdown-panel {
  --mtx-select-option-selected-state-background-color: #ffdad6;
}

.theme-dark {
  color-scheme: dark;
  --mat-app-background-color: #101414;
  --mat-app-text-color: #e0e3e2;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-ripple-color: rgba(224, 227, 226, 0.1);
  --mat-option-selected-state-label-text-color: #cce8e7;
  --mat-option-label-text-color: #e0e3e2;
  --mat-option-hover-state-layer-color: rgba(224, 227, 226, 0.08);
  --mat-option-focus-state-layer-color: rgba(224, 227, 226, 0.12);
  --mat-option-selected-state-layer-color: #324b4b;
  --mat-optgroup-label-text-color: #dae5e4;
  --mat-full-pseudo-checkbox-selected-icon-color: #00dddd;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #003737;
  --mat-full-pseudo-checkbox-unselected-icon-color: #dae5e4;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #101414;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(224, 227, 226, 0.38);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(224, 227, 226, 0.38);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00dddd;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(224, 227, 226, 0.38);
  --mdc-elevated-card-container-color: #101414;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #101414;
  --mdc-outlined-card-outline-color: #3f4948;
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #e0e3e2;
  --mdc-linear-progress-active-indicator-color: #00dddd;
  --mdc-linear-progress-track-color: #3f4948;
  --mdc-plain-tooltip-container-color: #e0e3e2;
  --mdc-plain-tooltip-supporting-text-color: #2d3131;
  --mdc-filled-text-field-caret-color: #00dddd;
  --mdc-filled-text-field-focus-active-indicator-color: #00dddd;
  --mdc-filled-text-field-focus-label-text-color: #00dddd;
  --mdc-filled-text-field-container-color: #3f4948;
  --mdc-filled-text-field-disabled-container-color: rgba(224, 227, 226, 0.04);
  --mdc-filled-text-field-label-text-color: #dae5e4;
  --mdc-filled-text-field-hover-label-text-color: #dae5e4;
  --mdc-filled-text-field-disabled-label-text-color: rgba(224, 227, 226, 0.38);
  --mdc-filled-text-field-input-text-color: #e0e3e2;
  --mdc-filled-text-field-disabled-input-text-color: rgba(224, 227, 226, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: #dae5e4;
  --mdc-filled-text-field-error-hover-label-text-color: #ffdad6;
  --mdc-filled-text-field-error-focus-label-text-color: #ffb4ab;
  --mdc-filled-text-field-error-label-text-color: #ffb4ab;
  --mdc-filled-text-field-active-indicator-color: #dae5e4;
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(224, 227, 226, 0.38);
  --mdc-filled-text-field-hover-active-indicator-color: #e0e3e2;
  --mdc-filled-text-field-error-active-indicator-color: #ffb4ab;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ffb4ab;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ffdad6;
  --mdc-outlined-text-field-caret-color: #00dddd;
  --mdc-outlined-text-field-focus-outline-color: #00dddd;
  --mdc-outlined-text-field-focus-label-text-color: #00dddd;
  --mdc-outlined-text-field-label-text-color: #dae5e4;
  --mdc-outlined-text-field-hover-label-text-color: #e0e3e2;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(224, 227, 226, 0.38);
  --mdc-outlined-text-field-input-text-color: #e0e3e2;
  --mdc-outlined-text-field-disabled-input-text-color: rgba(224, 227, 226, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: #dae5e4;
  --mdc-outlined-text-field-error-focus-label-text-color: #ffb4ab;
  --mdc-outlined-text-field-error-label-text-color: #ffb4ab;
  --mdc-outlined-text-field-error-hover-label-text-color: #ffdad6;
  --mdc-outlined-text-field-outline-color: #889392;
  --mdc-outlined-text-field-disabled-outline-color: rgba(224, 227, 226, 0.12);
  --mdc-outlined-text-field-hover-outline-color: #e0e3e2;
  --mdc-outlined-text-field-error-focus-outline-color: #ffb4ab;
  --mdc-outlined-text-field-error-hover-outline-color: #ffdad6;
  --mdc-outlined-text-field-error-outline-color: #ffb4ab;
  --mat-form-field-focus-select-arrow-color: #00dddd;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(224, 227, 226, 0.38);
  --mat-form-field-state-layer-color: #e0e3e2;
  --mat-form-field-error-text-color: #ffb4ab;
  --mat-form-field-select-option-text-color: #191c1c;
  --mat-form-field-select-disabled-option-text-color: rgba(25, 28, 28, 0.38);
  --mat-form-field-leading-icon-color: #dae5e4;
  --mat-form-field-disabled-leading-icon-color: rgba(224, 227, 226, 0.38);
  --mat-form-field-trailing-icon-color: #dae5e4;
  --mat-form-field-disabled-trailing-icon-color: rgba(224, 227, 226, 0.38);
  --mat-form-field-error-focus-trailing-icon-color: #ffb4ab;
  --mat-form-field-error-hover-trailing-icon-color: #ffdad6;
  --mat-form-field-error-trailing-icon-color: #ffb4ab;
  --mat-form-field-enabled-select-arrow-color: #dae5e4;
  --mat-form-field-disabled-select-arrow-color: rgba(224, 227, 226, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-select-panel-background-color: #1c2020;
  --mat-select-enabled-trigger-text-color: #e0e3e2;
  --mat-select-disabled-trigger-text-color: rgba(224, 227, 226, 0.38);
  --mat-select-placeholder-text-color: #dae5e4;
  --mat-select-enabled-arrow-color: #dae5e4;
  --mat-select-disabled-arrow-color: rgba(224, 227, 226, 0.38);
  --mat-select-focused-arrow-color: #00dddd;
  --mat-select-invalid-arrow-color: #ffb4ab;
  --mat-autocomplete-background-color: #1c2020;
  --mdc-dialog-container-color: #101414;
  --mdc-dialog-subhead-color: #e0e3e2;
  --mdc-dialog-supporting-text-color: #dae5e4;
  --mdc-chip-outline-color: #889392;
  --mdc-chip-disabled-outline-color: rgba(224, 227, 226, 0.12);
  --mdc-chip-focus-outline-color: #dae5e4;
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: rgba(224, 227, 226, 0.38);
  --mdc-chip-elevated-selected-container-color: #324b4b;
  --mdc-chip-flat-disabled-selected-container-color: rgba(224, 227, 226, 0.12);
  --mdc-chip-focus-state-layer-color: #dae5e4;
  --mdc-chip-hover-state-layer-color: #dae5e4;
  --mdc-chip-selected-hover-state-layer-color: #cce8e7;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: #cce8e7;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #dae5e4;
  --mdc-chip-selected-label-text-color: #cce8e7;
  --mdc-chip-with-icon-icon-color: #dae5e4;
  --mdc-chip-with-icon-disabled-icon-color: #e0e3e2;
  --mdc-chip-with-icon-selected-icon-color: #cce8e7;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #e0e3e2;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #dae5e4;
  --mat-chip-trailing-action-state-layer-color: #dae5e4;
  --mat-chip-selected-trailing-action-state-layer-color: #cce8e7;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: #e0e3e2;
  --mat-chip-selected-trailing-icon-color: #cce8e7;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: #00dddd;
  --mdc-switch-selected-handle-color: #003737;
  --mdc-switch-selected-hover-state-layer-color: #00dddd;
  --mdc-switch-selected-pressed-state-layer-color: #00dddd;
  --mdc-switch-selected-focus-handle-color: #004f4f;
  --mdc-switch-selected-hover-handle-color: #004f4f;
  --mdc-switch-selected-pressed-handle-color: #004f4f;
  --mdc-switch-selected-focus-track-color: #00dddd;
  --mdc-switch-selected-hover-track-color: #00dddd;
  --mdc-switch-selected-pressed-track-color: #00dddd;
  --mdc-switch-selected-track-color: #00dddd;
  --mdc-switch-disabled-selected-handle-color: #101414;
  --mdc-switch-disabled-selected-icon-color: #e0e3e2;
  --mdc-switch-disabled-selected-track-color: #e0e3e2;
  --mdc-switch-disabled-unselected-handle-color: #e0e3e2;
  --mdc-switch-disabled-unselected-icon-color: #3f4948;
  --mdc-switch-disabled-unselected-track-color: #3f4948;
  --mdc-switch-selected-icon-color: #00fbfb;
  --mdc-switch-unselected-focus-handle-color: #dae5e4;
  --mdc-switch-unselected-focus-state-layer-color: #e0e3e2;
  --mdc-switch-unselected-focus-track-color: #3f4948;
  --mdc-switch-unselected-handle-color: #889392;
  --mdc-switch-unselected-hover-handle-color: #dae5e4;
  --mdc-switch-unselected-hover-state-layer-color: #e0e3e2;
  --mdc-switch-unselected-hover-track-color: #3f4948;
  --mdc-switch-unselected-icon-color: #3f4948;
  --mdc-switch-unselected-pressed-handle-color: #dae5e4;
  --mdc-switch-unselected-pressed-state-layer-color: #e0e3e2;
  --mdc-switch-unselected-pressed-track-color: #3f4948;
  --mdc-switch-unselected-track-color: #3f4948;
  --mat-switch-track-outline-color: #889392;
  --mat-switch-disabled-unselected-track-outline-color: #e0e3e2;
  --mat-switch-label-text-color: #e0e3e2;
  --mdc-radio-disabled-selected-icon-color: #e0e3e2;
  --mdc-radio-disabled-unselected-icon-color: #e0e3e2;
  --mdc-radio-unselected-hover-icon-color: #e0e3e2;
  --mdc-radio-unselected-icon-color: #dae5e4;
  --mdc-radio-unselected-pressed-icon-color: #e0e3e2;
  --mdc-radio-selected-focus-icon-color: #00dddd;
  --mdc-radio-selected-hover-icon-color: #00dddd;
  --mdc-radio-selected-icon-color: #00dddd;
  --mdc-radio-selected-pressed-icon-color: #00dddd;
  --mat-radio-ripple-color: #e0e3e2;
  --mat-radio-checked-ripple-color: #00dddd;
  --mat-radio-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-radio-label-text-color: #e0e3e2;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: #00dddd;
  --mdc-slider-focus-handle-color: #00dddd;
  --mdc-slider-hover-handle-color: #00dddd;
  --mdc-slider-active-track-color: #00dddd;
  --mdc-slider-inactive-track-color: #3f4948;
  --mdc-slider-with-tick-marks-inactive-container-color: #dae5e4;
  --mdc-slider-with-tick-marks-active-container-color: #003737;
  --mdc-slider-disabled-active-track-color: #e0e3e2;
  --mdc-slider-disabled-handle-color: #e0e3e2;
  --mdc-slider-disabled-inactive-track-color: #e0e3e2;
  --mdc-slider-label-container-color: #00dddd;
  --mdc-slider-label-label-text-color: #003737;
  --mdc-slider-with-overlap-handle-outline-color: #003737;
  --mdc-slider-with-tick-marks-disabled-container-color: #e0e3e2;
  --mat-slider-ripple-color: #00dddd;
  --mat-slider-hover-state-layer-color: rgba(0, 221, 221, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 221, 221, 0.2);
  --mat-menu-item-label-text-color: #e0e3e2;
  --mat-menu-item-icon-color: #dae5e4;
  --mat-menu-item-hover-state-layer-color: rgba(224, 227, 226, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(224, 227, 226, 0.12);
  --mat-menu-container-color: #1c2020;
  --mat-menu-divider-color: #3f4948;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: #004f4f;
  --mdc-list-list-item-disabled-state-layer-color: #e0e3e2;
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: #e0e3e2;
  --mdc-list-list-item-supporting-text-color: #dae5e4;
  --mdc-list-list-item-leading-icon-color: #dae5e4;
  --mdc-list-list-item-trailing-supporting-text-color: #dae5e4;
  --mdc-list-list-item-trailing-icon-color: #dae5e4;
  --mdc-list-list-item-selected-trailing-icon-color: #00dddd;
  --mdc-list-list-item-disabled-label-text-color: #e0e3e2;
  --mdc-list-list-item-disabled-leading-icon-color: #e0e3e2;
  --mdc-list-list-item-disabled-trailing-icon-color: #e0e3e2;
  --mdc-list-list-item-hover-label-text-color: #e0e3e2;
  --mdc-list-list-item-focus-label-text-color: #e0e3e2;
  --mdc-list-list-item-hover-state-layer-color: #e0e3e2;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: #e0e3e2;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-list-active-indicator-color: #324b4b;
  --mat-paginator-container-text-color: #e0e3e2;
  --mat-paginator-container-background-color: #101414;
  --mat-paginator-enabled-icon-color: #dae5e4;
  --mat-paginator-disabled-icon-color: rgba(224, 227, 226, 0.38);
  --mdc-tab-indicator-active-indicator-color: #00dddd;
  --mat-tab-header-divider-color: #3f4948;
  --mat-tab-header-pagination-icon-color: #e0e3e2;
  --mat-tab-header-inactive-label-text-color: #e0e3e2;
  --mat-tab-header-active-label-text-color: #e0e3e2;
  --mat-tab-header-active-ripple-color: #e0e3e2;
  --mat-tab-header-inactive-ripple-color: #e0e3e2;
  --mat-tab-header-inactive-focus-label-text-color: #e0e3e2;
  --mat-tab-header-inactive-hover-label-text-color: #e0e3e2;
  --mat-tab-header-active-focus-label-text-color: #e0e3e2;
  --mat-tab-header-active-hover-label-text-color: #e0e3e2;
  --mat-tab-header-active-focus-indicator-color: #00dddd;
  --mat-tab-header-active-hover-indicator-color: #00dddd;
  --mdc-checkbox-disabled-selected-checkmark-color: #101414;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: #00dddd;
  --mdc-checkbox-disabled-selected-icon-color: rgba(224, 227, 226, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(224, 227, 226, 0.38);
  --mdc-checkbox-selected-checkmark-color: #003737;
  --mdc-checkbox-selected-focus-icon-color: #00dddd;
  --mdc-checkbox-selected-hover-icon-color: #00dddd;
  --mdc-checkbox-selected-icon-color: #00dddd;
  --mdc-checkbox-unselected-focus-icon-color: #e0e3e2;
  --mdc-checkbox-unselected-hover-icon-color: #e0e3e2;
  --mdc-checkbox-unselected-icon-color: #dae5e4;
  --mdc-checkbox-selected-focus-state-layer-color: #00dddd;
  --mdc-checkbox-selected-hover-state-layer-color: #00dddd;
  --mdc-checkbox-selected-pressed-state-layer-color: #e0e3e2;
  --mdc-checkbox-unselected-focus-state-layer-color: #e0e3e2;
  --mdc-checkbox-unselected-hover-state-layer-color: #e0e3e2;
  --mdc-checkbox-unselected-pressed-state-layer-color: #00dddd;
  --mat-checkbox-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-checkbox-label-text-color: #e0e3e2;
  --mdc-text-button-label-text-color: #00dddd;
  --mdc-text-button-disabled-label-text-color: rgba(224, 227, 226, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: #101414;
  --mdc-protected-button-label-text-color: #00dddd;
  --mdc-protected-button-disabled-container-color: rgba(224, 227, 226, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(224, 227, 226, 0.38);
  --mdc-filled-button-container-color: #00dddd;
  --mdc-filled-button-label-text-color: #003737;
  --mdc-filled-button-disabled-container-color: rgba(224, 227, 226, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(224, 227, 226, 0.38);
  --mdc-outlined-button-disabled-outline-color: rgba(224, 227, 226, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(224, 227, 226, 0.38);
  --mdc-outlined-button-label-text-color: #00dddd;
  --mdc-outlined-button-outline-color: #889392;
  --mat-text-button-state-layer-color: #00dddd;
  --mat-text-button-disabled-state-layer-color: #dae5e4;
  --mat-text-button-ripple-color: rgba(0, 221, 221, 0.12);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-state-layer-color: #00dddd;
  --mat-protected-button-disabled-state-layer-color: #dae5e4;
  --mat-protected-button-ripple-color: rgba(0, 221, 221, 0.12);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-state-layer-color: #003737;
  --mat-filled-button-disabled-state-layer-color: #dae5e4;
  --mat-filled-button-ripple-color: rgba(0, 55, 55, 0.12);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-state-layer-color: #00dddd;
  --mat-outlined-button-disabled-state-layer-color: #dae5e4;
  --mat-outlined-button-ripple-color: rgba(0, 221, 221, 0.12);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-color: #dae5e4;
  --mdc-icon-button-disabled-icon-color: rgba(224, 227, 226, 0.38);
  --mat-icon-button-state-layer-color: #dae5e4;
  --mat-icon-button-disabled-state-layer-color: #dae5e4;
  --mat-icon-button-ripple-color: rgba(218, 229, 228, 0.12);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: #004f4f;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: #004f4f;
  --mat-fab-foreground-color: #00fbfb;
  --mat-fab-state-layer-color: #00fbfb;
  --mat-fab-ripple-color: rgba(0, 251, 251, 0.12);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(224, 227, 226, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(224, 227, 226, 0.38);
  --mat-fab-small-foreground-color: #00fbfb;
  --mat-fab-small-state-layer-color: #00fbfb;
  --mat-fab-small-ripple-color: rgba(0, 251, 251, 0.12);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(224, 227, 226, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(224, 227, 226, 0.38);
  --mdc-snackbar-container-color: #e0e3e2;
  --mdc-snackbar-supporting-text-color: #2d3131;
  --mat-snack-bar-button-color: #006a6a;
  --mat-table-background-color: #101414;
  --mat-table-header-headline-color: #e0e3e2;
  --mat-table-row-item-label-text-color: #e0e3e2;
  --mat-table-row-item-outline-color: #889392;
  --mdc-circular-progress-active-indicator-color: #00dddd;
  --mat-badge-background-color: #ffb4ab;
  --mat-badge-text-color: #690005;
  --mat-badge-disabled-state-background-color: rgba(255, 180, 171, 0.38);
  --mat-badge-disabled-state-text-color: #690005;
  --mat-bottom-sheet-container-text-color: #e0e3e2;
  --mat-bottom-sheet-container-background-color: #191c1c;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: #e0e3e2;
  --mat-standard-button-toggle-state-layer-color: #e0e3e2;
  --mat-standard-button-toggle-selected-state-background-color: #324b4b;
  --mat-standard-button-toggle-selected-state-text-color: #cce8e7;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(224, 227, 226, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(224, 227, 226, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(224, 227, 226, 0.12);
  --mat-standard-button-toggle-divider-color: #889392;
  --mat-datepicker-calendar-date-selected-state-text-color: #003737;
  --mat-datepicker-calendar-date-selected-state-background-color: #00dddd;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(224, 227, 226, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #00dddd;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(224, 227, 226, 0.12);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(224, 227, 226, 0.08);
  --mat-datepicker-toggle-active-state-icon-color: #dae5e4;
  --mat-datepicker-calendar-date-in-range-state-background-color: #004f4f;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #723600;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #324b4b;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #b0cccb;
  --mat-datepicker-toggle-icon-color: #dae5e4;
  --mat-datepicker-calendar-body-label-text-color: #e0e3e2;
  --mat-datepicker-calendar-period-button-text-color: #dae5e4;
  --mat-datepicker-calendar-period-button-icon-color: #dae5e4;
  --mat-datepicker-calendar-navigation-button-icon-color: #dae5e4;
  --mat-datepicker-calendar-header-text-color: #dae5e4;
  --mat-datepicker-calendar-date-today-outline-color: #00dddd;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(224, 227, 226, 0.38);
  --mat-datepicker-calendar-date-text-color: #e0e3e2;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(224, 227, 226, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: #00dddd;
  --mat-datepicker-range-input-separator-color: #e0e3e2;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(224, 227, 226, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(224, 227, 226, 0.38);
  --mat-datepicker-calendar-container-background-color: #272b2a;
  --mat-datepicker-calendar-container-text-color: #e0e3e2;
  --mat-divider-color: #889392;
  --mat-expansion-container-background-color: #101414;
  --mat-expansion-container-text-color: #e0e3e2;
  --mat-expansion-actions-divider-color: #889392;
  --mat-expansion-header-hover-state-layer-color: rgba(224, 227, 226, 0.08);
  --mat-expansion-header-focus-state-layer-color: rgba(224, 227, 226, 0.12);
  --mat-expansion-header-disabled-state-text-color: rgba(224, 227, 226, 0.38);
  --mat-expansion-header-text-color: #e0e3e2;
  --mat-expansion-header-description-color: #dae5e4;
  --mat-expansion-header-indicator-color: #dae5e4;
  --mat-sidenav-container-background-color: #101414;
  --mat-sidenav-container-text-color: #dae5e4;
  --mat-sidenav-content-background-color: #101414;
  --mat-sidenav-content-text-color: #e0e3e2;
  --mat-sidenav-scrim-color: rgba(41, 50, 50, 0.4);
  --mat-stepper-header-icon-foreground-color: #101414;
  --mat-stepper-header-selected-state-icon-background-color: #00dddd;
  --mat-stepper-header-selected-state-icon-foreground-color: #003737;
  --mat-stepper-header-edit-state-icon-background-color: #00dddd;
  --mat-stepper-header-edit-state-icon-foreground-color: #003737;
  --mat-stepper-container-color: #101414;
  --mat-stepper-line-color: #889392;
  --mat-stepper-header-hover-state-layer-color: rgba(224, 227, 226, 0.08);
  --mat-stepper-header-focus-state-layer-color: rgba(224, 227, 226, 0.12);
  --mat-stepper-header-label-text-color: #dae5e4;
  --mat-stepper-header-optional-label-text-color: #dae5e4;
  --mat-stepper-header-selected-state-label-text-color: #dae5e4;
  --mat-stepper-header-error-state-label-text-color: #ffb4ab;
  --mat-stepper-header-icon-background-color: #dae5e4;
  --mat-stepper-header-error-state-icon-foreground-color: #ffb4ab;
  --mat-sort-arrow-color: #e0e3e2;
  --mat-toolbar-container-background-color: #101414;
  --mat-toolbar-container-text-color: #e0e3e2;
  --mat-tree-container-background-color: #101414;
  --mat-tree-node-text-color: #e0e3e2;
  --body-background-color: #272b2a;
  --link-color: #00dddd;
  --link-hover-color: #00aaaa;
  --code-border-color: rgba(204, 232, 231, 0.08);
  --code-background-color: rgba(204, 232, 231, 0.04);
  --header-background-color: rgba(39, 43, 42, 0.8);
  --user-panel-background-color: #272b2a;
  --user-panel-hover-background-color: #3f4948;
  --sidemenu-heading-hover-background-color: #313635;
  --sidemenu-active-heading-text-color: #00dddd;
  --sidemenu-active-heading-background-color: #004f4f;
  --sidemenu-active-heading-hover-background-color: #004f4f;
  --sidemenu-expanded-background-color: #272b2a;
  --topmenu-text-color: #e0e3e2;
  --topmenu-background-color: rgba(39, 43, 42, 0.8);
  --topmenu-item-active-background-color: #004f4f;
  --topmenu-dropdown-item-active-text-color: #00dddd;
  --toast-info-background-color: #00458f;
  --toast-success-background-color: #026e00;
  --toast-warning-background-color: #964900;
  --toast-error-background-color: #930100;
  --mdc-icon-button-icon-color: rgb(255, 255, 255);
  --mat-sidenav-container-width: 240px;
  --mat-card-title-text-weight: 500;
  --mat-mdc-focus-indicator-border-radius: 0;
}
.theme-dark {
  --mtx-alert-outline-color: #3f4948;
}
.theme-dark {
  --mtx-alert-background-color: #1c2020;
}
.theme-dark {
  --mtx-alert-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-alert-info-outline-color: #005cbb;
}
.theme-dark {
  --mtx-alert-info-background-color: #002f65;
}
.theme-dark {
  --mtx-alert-info-text-color: #d7e3ff;
}
.theme-dark {
  --mtx-alert-success-outline-color: #026e00;
}
.theme-dark {
  --mtx-alert-success-background-color: #013a00;
}
.theme-dark {
  --mtx-alert-success-text-color: #cbffb8;
}
.theme-dark {
  --mtx-alert-warning-outline-color: #626200;
}
.theme-dark {
  --mtx-alert-warning-background-color: #323200;
}
.theme-dark {
  --mtx-alert-warning-text-color: #fffeac;
}
.theme-dark {
  --mtx-alert-danger-outline-color: #c00100;
}
.theme-dark {
  --mtx-alert-danger-background-color: #690100;
}
.theme-dark {
  --mtx-alert-danger-text-color: #ffdad4;
}
.theme-dark {
  --mtx-colorpicker-toggle-active-state-icon-color: #bec9c8;
}
.theme-dark {
  --mtx-colorpicker-toggle-icon-color: #bec9c8;
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: #003737;
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #00dddd;
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-selected-disabled-state-background-color: rgba(224, 227, 226, 0.38);
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: #00dddd;
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-focus-state-background-color: rgba(224, 227, 226, 0.12);
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-hover-state-background-color: rgba(224, 227, 226, 0.08);
}
.theme-dark {
  --mtx-datetimepicker-clock-hand-background-color: #00dddd;
}
.theme-dark {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #00dddd;
}
.theme-dark {
  --mtx-datetimepicker-time-input-active-state-text-color: #00fbfb;
}
.theme-dark {
  --mtx-datetimepicker-time-input-active-state-background-color: #004f4f;
}
.theme-dark {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #00dddd;
}
.theme-dark {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: #00fbfb;
}
.theme-dark {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: #723600;
}
.theme-dark {
  --mtx-datetimepicker-toggle-active-state-icon-color: #bec9c8;
}
.theme-dark {
  --mtx-datetimepicker-toggle-icon-color: #bec9c8;
}
.theme-dark {
  --mtx-datetimepicker-calendar-body-label-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-datetimepicker-calendar-header-text-color: #bec9c8;
}
.theme-dark {
  --mtx-datetimepicker-calendar-header-divider-color: #3f4948;
}
.theme-dark {
  --mtx-datetimepicker-calendar-table-header-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-today-outline-color: #00dddd;
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-datetimepicker-calendar-date-disabled-state-text-color: rgba(224, 227, 226, 0.38);
}
.theme-dark {
  --mtx-datetimepicker-container-background-color: #272b2a;
}
.theme-dark {
  --mtx-datetimepicker-container-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-datetimepicker-clock-dial-background-color: #313635;
}
.theme-dark {
  --mtx-datetimepicker-clock-cell-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-datetimepicker-clock-cell-hover-state-background-color: rgba(224, 227, 226, 0.08);
}
.theme-dark {
  --mtx-datetimepicker-clock-cell-disabled-state-text-color: rgba(224, 227, 226, 0.38);
}
.theme-dark {
  --mtx-datetimepicker-time-input-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-datetimepicker-time-input-background-color: #313635;
}
.theme-dark {
  --mtx-datetimepicker-time-input-focus-state-background-color: #004f4f;
}
.theme-dark {
  --mtx-datetimepicker-time-input-warn-state-outline-color: #ffb4ab;
}
.theme-dark {
  --mtx-datetimepicker-time-ampm-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-datetimepicker-time-ampm-outline-color: #889392;
}
.theme-dark {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: #ffdcc7;
}
.theme-dark {
  --mtx-drawer-container-background-color: #191c1c;
}
.theme-dark {
  --mtx-drawer-container-text-color: #bec9c8;
}
.theme-dark {
  --mtx-grid-outline-color: #3f4948;
}
.theme-dark {
  --mtx-grid-column-menu-text-color: #bec9c8;
}
.theme-dark {
  --mtx-grid-column-menu-divider-color: #3f4948;
}
.theme-dark {
  --mtx-grid-table-footer-background-color: #1c2020;
}
.theme-dark {
  --mtx-grid-table-row-striped-background-color: #1c2020;
}
.theme-dark {
  --mtx-grid-table-row-hover-background-color: #324b4b;
}
.theme-dark {
  --mtx-grid-table-row-selected-background-color: #324b4b;
}
.theme-dark {
  --mtx-grid-table-row-selected-hover-background-color: #004f4f;
}
.theme-dark {
  --mtx-grid-table-cell-selected-outline-color: #00dddd;
}
.theme-dark {
  --mtx-grid-resizable-handle-active-background-color: #00dddd;
}
.theme-dark {
  --mtx-grid-resizable-handle-hover-background-color: #00dddd;
}
.theme-dark {
  --mtx-grid-resizable-handle-disabled-background-color: #3f4948;
}
.theme-dark {
  --mtx-loader-backdrop-background-color: rgba(25, 28, 28, 0.75);
}
.theme-dark {
  --mtx-popover-outline-color: #1c2020;
}
.theme-dark {
  --mtx-popover-background-color: #1c2020;
}
.theme-dark {
  --mtx-popover-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-progress-track-color: #1c2020;
}
.theme-dark {
  --mtx-progress-indicator-color: #3f4948;
}
.theme-dark {
  --mtx-progress-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-progress-info-indicator-color: #5a64ff;
}
.theme-dark {
  --mtx-progress-success-indicator-color: #038b00;
}
.theme-dark {
  --mtx-progress-warning-indicator-color: #bc5d00;
}
.theme-dark {
  --mtx-progress-danger-indicator-color: #ef0000;
}
.theme-dark {
  --mtx-select-option-selected-state-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-select-container-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-select-placeholder-text-color: rgba(224, 227, 226, 0.38);
}
.theme-dark {
  --mtx-select-disabled-text-color: rgba(224, 227, 226, 0.38);
}
.theme-dark {
  --mtx-select-multiple-value-outline-color: #889392;
}
.theme-dark {
  --mtx-select-multiple-value-icon-hover-background-color: #3f4948;
}
.theme-dark {
  --mtx-select-clear-icon-color: #e0e3e2;
}
.theme-dark {
  --mtx-select-clear-icon-hover-color: #ffb4ab;
}
.theme-dark {
  --mtx-select-enabled-arrow-color: #e0e3e2;
}
.theme-dark {
  --mtx-select-disabled-arrow-color: rgba(224, 227, 226, 0.38);
}
.theme-dark {
  --mtx-select-invalid-arrow-color: #ffb4ab;
}
.theme-dark {
  --mtx-select-panel-background-color: #1c2020;
}
.theme-dark {
  --mtx-select-panel-divider-color: #889392;
}
.theme-dark {
  --mtx-select-optgroup-label-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-select-option-label-text-color: #e0e3e2;
}
.theme-dark {
  --mtx-select-option-selected-state-background-color: #324b4b;
}
.theme-dark {
  --mtx-select-option-hover-state-background-color: rgba(224, 227, 226, 0.08);
}
.theme-dark {
  --mtx-select-option-disabled-state-text-color: rgba(224, 227, 226, 0.38);
}
.theme-dark {
  --mtx-split-gutter-hover-state-background-color: #00dddd;
}
.theme-dark {
  --mtx-split-gutter-background-color: #3f4948;
}
.theme-dark {
  --mdc-plain-tooltip-container-color: #e0e3e2;
}
.theme-dark {
  --mdc-plain-tooltip-supporting-text-color: #2d3131;
}
.theme-dark .mat-primary {
  --mat-stepper-header-selected-state-icon-background-color: #00dddd;
  --mat-stepper-header-selected-state-icon-foreground-color: #003737;
  --mat-stepper-header-edit-state-icon-background-color: #00dddd;
  --mat-stepper-header-edit-state-icon-foreground-color: #003737;
}
.theme-dark .mat-primary.mat-icon {
  --mat-icon-color: #00dddd;
}
.theme-dark .mat-primary.mat-mdc-checkbox {
  --mat-checkbox-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-checkbox-label-text-color: #e0e3e2;
}
.theme-dark .mat-primary.mat-mdc-slide-toggle {
  --mat-switch-track-outline-color: #889392;
  --mat-switch-disabled-unselected-track-outline-color: #e0e3e2;
  --mat-switch-label-text-color: #e0e3e2;
}
.theme-dark .mat-primary.mat-mdc-button-base {
  --mdc-icon-button-icon-color: #00dddd;
  --mat-icon-button-state-layer-color: #00dddd;
  --mat-icon-button-ripple-color: rgba(0, 221, 221, 0.12);
}
.theme-dark .mat-primary .mdc-list-item__start,
.theme-dark .mat-primary .mdc-list-item__end {
  --mat-checkbox-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-checkbox-label-text-color: #e0e3e2;
}
.theme-dark .mat-badge {
  --mat-badge-background-color: #00dddd;
  --mat-badge-text-color: #003737;
  --mat-badge-disabled-state-background-color: rgba(0, 221, 221, 0.38);
  --mat-badge-disabled-state-text-color: #003737;
}
.theme-dark .mat-accent {
  --mat-option-selected-state-label-text-color: #ffdcc7;
  --mat-option-selected-state-layer-color: #723600;
  --mdc-circular-progress-active-indicator-color: #ffb787;
  --mat-full-pseudo-checkbox-selected-icon-color: #ffb787;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #502400;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ffb787;
  --mat-stepper-header-selected-state-icon-background-color: #ffb787;
  --mat-stepper-header-selected-state-icon-foreground-color: #502400;
  --mat-stepper-header-edit-state-icon-background-color: #ffb787;
  --mat-stepper-header-edit-state-icon-foreground-color: #502400;
}
.theme-dark .mat-accent.mat-icon {
  --mat-icon-color: #ffb787;
}
.theme-dark .mat-accent.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: #ffb787;
  --mdc-checkbox-selected-checkmark-color: #502400;
  --mdc-checkbox-selected-focus-icon-color: #ffb787;
  --mdc-checkbox-selected-hover-icon-color: #ffb787;
  --mdc-checkbox-selected-icon-color: #ffb787;
  --mdc-checkbox-selected-focus-state-layer-color: #ffb787;
  --mdc-checkbox-selected-hover-state-layer-color: #ffb787;
  --mdc-checkbox-unselected-pressed-state-layer-color: #ffb787;
  --mat-checkbox-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-checkbox-label-text-color: #e0e3e2;
}
.theme-dark .mat-accent.mat-mdc-slider {
  --mdc-slider-handle-color: #ffb787;
  --mdc-slider-focus-handle-color: #ffb787;
  --mdc-slider-hover-handle-color: #ffb787;
  --mdc-slider-active-track-color: #ffb787;
  --mdc-slider-with-tick-marks-active-container-color: #502400;
  --mdc-slider-label-container-color: #ffb787;
  --mdc-slider-label-label-text-color: #502400;
  --mdc-slider-with-overlap-handle-outline-color: #502400;
  --mat-slider-ripple-color: #ffb787;
  --mat-slider-hover-state-layer-color: rgba(255, 183, 135, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 183, 135, 0.2);
}
.theme-dark .mat-accent.mat-mdc-tab-group, .theme-dark .mat-accent.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ffb787;
  --mat-tab-header-active-focus-indicator-color: #ffb787;
  --mat-tab-header-active-hover-indicator-color: #ffb787;
}
.theme-dark .mat-accent.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #ffb787;
  --mdc-switch-selected-handle-color: #502400;
  --mdc-switch-selected-hover-state-layer-color: #ffb787;
  --mdc-switch-selected-pressed-state-layer-color: #ffb787;
  --mdc-switch-selected-focus-handle-color: #723600;
  --mdc-switch-selected-hover-handle-color: #723600;
  --mdc-switch-selected-pressed-handle-color: #723600;
  --mdc-switch-selected-focus-track-color: #ffb787;
  --mdc-switch-selected-hover-track-color: #ffb787;
  --mdc-switch-selected-pressed-track-color: #ffb787;
  --mdc-switch-selected-track-color: #ffb787;
  --mdc-switch-selected-icon-color: #ffdcc7;
  --mat-switch-track-outline-color: #889392;
  --mat-switch-disabled-unselected-track-outline-color: #e0e3e2;
  --mat-switch-label-text-color: #e0e3e2;
}
.theme-dark .mat-accent.mat-mdc-form-field {
  --mat-select-focused-arrow-color: #ffb787;
}
.theme-dark .mat-accent.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: #ffb787;
  --mdc-radio-selected-hover-icon-color: #ffb787;
  --mdc-radio-selected-icon-color: #ffb787;
  --mdc-radio-selected-pressed-icon-color: #ffb787;
  --mat-radio-checked-ripple-color: #ffb787;
}
.theme-dark .mat-accent.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ffb787;
  --mdc-linear-progress-track-color: #723600;
}
.theme-dark .mat-accent.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: #ffb787;
  --mdc-filled-text-field-focus-active-indicator-color: #ffb787;
  --mdc-filled-text-field-focus-label-text-color: #ffb787;
  --mdc-outlined-text-field-caret-color: #ffb787;
  --mdc-outlined-text-field-focus-outline-color: #ffb787;
  --mdc-outlined-text-field-focus-label-text-color: #ffb787;
  --mat-form-field-focus-select-arrow-color: #ffb787;
}
.theme-dark .mat-accent.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: #502400;
  --mat-datepicker-calendar-date-selected-state-background-color: #ffb787;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #ffb787;
  --mat-datepicker-calendar-date-in-range-state-background-color: #723600;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #004f4f;
  --mat-datepicker-calendar-date-today-outline-color: #ffb787;
  --mat-datepicker-calendar-date-preview-state-outline-color: #ffb787;
}
.theme-dark .mat-accent.mat-mdc-button-base {
  --mdc-text-button-label-text-color: #ffb787;
  --mdc-protected-button-label-text-color: #ffb787;
  --mdc-filled-button-container-color: #ffb787;
  --mdc-filled-button-label-text-color: #502400;
  --mdc-outlined-button-label-text-color: #ffb787;
  --mat-text-button-state-layer-color: #ffb787;
  --mat-text-button-ripple-color: rgba(255, 183, 135, 0.12);
  --mat-protected-button-state-layer-color: #ffb787;
  --mat-protected-button-ripple-color: rgba(255, 183, 135, 0.12);
  --mat-filled-button-state-layer-color: #502400;
  --mat-filled-button-ripple-color: rgba(80, 36, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ffb787;
  --mat-outlined-button-ripple-color: rgba(255, 183, 135, 0.12);
  --mdc-icon-button-icon-color: #ffb787;
  --mat-icon-button-state-layer-color: #ffb787;
  --mat-icon-button-ripple-color: rgba(255, 183, 135, 0.12);
}
.theme-dark .mat-accent.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: #723600;
  --mdc-chip-selected-hover-state-layer-color: #ffdcc7;
  --mdc-chip-selected-focus-state-layer-color: #ffdcc7;
  --mdc-chip-selected-label-text-color: #ffdcc7;
  --mdc-chip-with-icon-selected-icon-color: #ffdcc7;
  --mat-chip-selected-trailing-action-state-layer-color: #ffdcc7;
  --mat-chip-selected-trailing-icon-color: #ffdcc7;
}
.theme-dark .mat-accent .mdc-list-item__start,
.theme-dark .mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: #ffb787;
  --mdc-checkbox-selected-checkmark-color: #502400;
  --mdc-checkbox-selected-focus-icon-color: #ffb787;
  --mdc-checkbox-selected-hover-icon-color: #ffb787;
  --mdc-checkbox-selected-icon-color: #ffb787;
  --mdc-checkbox-selected-focus-state-layer-color: #ffb787;
  --mdc-checkbox-selected-hover-state-layer-color: #ffb787;
  --mdc-checkbox-unselected-pressed-state-layer-color: #ffb787;
  --mat-checkbox-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-checkbox-label-text-color: #e0e3e2;
  --mdc-radio-selected-focus-icon-color: #ffb787;
  --mdc-radio-selected-hover-icon-color: #ffb787;
  --mdc-radio-selected-icon-color: #ffb787;
  --mdc-radio-selected-pressed-icon-color: #ffb787;
  --mat-radio-checked-ripple-color: #ffb787;
}
.theme-dark .mat-accent.mat-mdc-fab, .theme-dark .mat-accent.mat-mdc-mini-fab {
  --mdc-fab-container-color: #723600;
  --mdc-fab-small-container-color: #723600;
  --mat-fab-foreground-color: #ffdcc7;
  --mat-fab-state-layer-color: #ffdcc7;
  --mat-fab-ripple-color: rgba(255, 220, 199, 0.12);
  --mat-fab-small-foreground-color: #ffdcc7;
  --mat-fab-small-state-layer-color: #ffdcc7;
  --mat-fab-small-ripple-color: rgba(255, 220, 199, 0.12);
}
.theme-dark .mat-badge-accent {
  --mat-badge-background-color: #ffb787;
  --mat-badge-text-color: #502400;
  --mat-badge-disabled-state-background-color: rgba(255, 183, 135, 0.38);
  --mat-badge-disabled-state-text-color: #502400;
}
.theme-dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ffdad6;
  --mat-option-selected-state-layer-color: #93000a;
  --mdc-circular-progress-active-indicator-color: #ffb4ab;
  --mat-full-pseudo-checkbox-selected-icon-color: #ffb4ab;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #690005;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ffb4ab;
  --mat-stepper-header-selected-state-icon-background-color: #ffb4ab;
  --mat-stepper-header-selected-state-icon-foreground-color: #690005;
  --mat-stepper-header-edit-state-icon-background-color: #ffb4ab;
  --mat-stepper-header-edit-state-icon-foreground-color: #690005;
}
.theme-dark .mat-warn.mat-icon {
  --mat-icon-color: #ffb4ab;
}
.theme-dark .mat-warn.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: #ffb4ab;
  --mdc-checkbox-selected-checkmark-color: #690005;
  --mdc-checkbox-selected-focus-icon-color: #ffb4ab;
  --mdc-checkbox-selected-hover-icon-color: #ffb4ab;
  --mdc-checkbox-selected-icon-color: #ffb4ab;
  --mdc-checkbox-selected-focus-state-layer-color: #ffb4ab;
  --mdc-checkbox-selected-hover-state-layer-color: #ffb4ab;
  --mdc-checkbox-unselected-pressed-state-layer-color: #ffb4ab;
  --mat-checkbox-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-checkbox-label-text-color: #e0e3e2;
}
.theme-dark .mat-warn.mat-mdc-slider {
  --mdc-slider-handle-color: #ffb4ab;
  --mdc-slider-focus-handle-color: #ffb4ab;
  --mdc-slider-hover-handle-color: #ffb4ab;
  --mdc-slider-active-track-color: #ffb4ab;
  --mdc-slider-with-tick-marks-active-container-color: #690005;
  --mdc-slider-label-container-color: #ffb4ab;
  --mdc-slider-label-label-text-color: #690005;
  --mdc-slider-with-overlap-handle-outline-color: #690005;
  --mat-slider-ripple-color: #ffb4ab;
  --mat-slider-hover-state-layer-color: rgba(255, 180, 171, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 180, 171, 0.2);
}
.theme-dark .mat-warn.mat-mdc-tab-group, .theme-dark .mat-warn.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ffb4ab;
  --mat-tab-header-active-focus-indicator-color: #ffb4ab;
  --mat-tab-header-active-hover-indicator-color: #ffb4ab;
}
.theme-dark .mat-warn.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #ffb4ab;
  --mdc-switch-selected-handle-color: #690005;
  --mdc-switch-selected-hover-state-layer-color: #ffb4ab;
  --mdc-switch-selected-pressed-state-layer-color: #ffb4ab;
  --mdc-switch-selected-focus-handle-color: #93000a;
  --mdc-switch-selected-hover-handle-color: #93000a;
  --mdc-switch-selected-pressed-handle-color: #93000a;
  --mdc-switch-selected-focus-track-color: #ffb4ab;
  --mdc-switch-selected-hover-track-color: #ffb4ab;
  --mdc-switch-selected-pressed-track-color: #ffb4ab;
  --mdc-switch-selected-track-color: #ffb4ab;
  --mdc-switch-selected-icon-color: #ffdad6;
  --mat-switch-track-outline-color: #889392;
  --mat-switch-disabled-unselected-track-outline-color: #e0e3e2;
  --mat-switch-label-text-color: #e0e3e2;
}
.theme-dark .mat-warn.mat-mdc-form-field {
  --mat-select-focused-arrow-color: #ffb4ab;
}
.theme-dark .mat-warn.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: #ffb4ab;
  --mdc-radio-selected-hover-icon-color: #ffb4ab;
  --mdc-radio-selected-icon-color: #ffb4ab;
  --mdc-radio-selected-pressed-icon-color: #ffb4ab;
  --mat-radio-checked-ripple-color: #ffb4ab;
}
.theme-dark .mat-warn.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ffb4ab;
  --mdc-linear-progress-track-color: #93000a;
}
.theme-dark .mat-warn.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: #ffb4ab;
  --mdc-filled-text-field-focus-active-indicator-color: #ffb4ab;
  --mdc-filled-text-field-focus-label-text-color: #ffb4ab;
  --mdc-outlined-text-field-caret-color: #ffb4ab;
  --mdc-outlined-text-field-focus-outline-color: #ffb4ab;
  --mdc-outlined-text-field-focus-label-text-color: #ffb4ab;
  --mat-form-field-focus-select-arrow-color: #ffb4ab;
}
.theme-dark .mat-warn.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: #690005;
  --mat-datepicker-calendar-date-selected-state-background-color: #ffb4ab;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #ffb4ab;
  --mat-datepicker-calendar-date-in-range-state-background-color: #93000a;
  --mat-datepicker-calendar-date-today-outline-color: #ffb4ab;
  --mat-datepicker-calendar-date-preview-state-outline-color: #ffb4ab;
}
.theme-dark .mat-warn.mat-mdc-button-base {
  --mdc-text-button-label-text-color: #ffb4ab;
  --mdc-protected-button-label-text-color: #ffb4ab;
  --mdc-filled-button-container-color: #ffb4ab;
  --mdc-filled-button-label-text-color: #690005;
  --mdc-outlined-button-label-text-color: #ffb4ab;
  --mat-text-button-state-layer-color: #ffb4ab;
  --mat-text-button-ripple-color: rgba(255, 180, 171, 0.12);
  --mat-protected-button-state-layer-color: #ffb4ab;
  --mat-protected-button-ripple-color: rgba(255, 180, 171, 0.12);
  --mat-filled-button-state-layer-color: #690005;
  --mat-filled-button-ripple-color: rgba(105, 0, 5, 0.12);
  --mat-outlined-button-state-layer-color: #ffb4ab;
  --mat-outlined-button-ripple-color: rgba(255, 180, 171, 0.12);
  --mdc-icon-button-icon-color: #ffb4ab;
  --mat-icon-button-state-layer-color: #ffb4ab;
  --mat-icon-button-ripple-color: rgba(255, 180, 171, 0.12);
}
.theme-dark .mat-warn.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: #93000a;
  --mdc-chip-selected-hover-state-layer-color: #ffdad6;
  --mdc-chip-selected-focus-state-layer-color: #ffdad6;
  --mdc-chip-selected-label-text-color: #ffdad6;
  --mdc-chip-with-icon-selected-icon-color: #ffdad6;
  --mat-chip-selected-trailing-action-state-layer-color: #ffdad6;
  --mat-chip-selected-trailing-icon-color: #ffdad6;
}
.theme-dark .mat-warn .mdc-list-item__start,
.theme-dark .mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: #ffb4ab;
  --mdc-checkbox-selected-checkmark-color: #690005;
  --mdc-checkbox-selected-focus-icon-color: #ffb4ab;
  --mdc-checkbox-selected-hover-icon-color: #ffb4ab;
  --mdc-checkbox-selected-icon-color: #ffb4ab;
  --mdc-checkbox-selected-focus-state-layer-color: #ffb4ab;
  --mdc-checkbox-selected-hover-state-layer-color: #ffb4ab;
  --mdc-checkbox-unselected-pressed-state-layer-color: #ffb4ab;
  --mat-checkbox-disabled-label-color: rgba(224, 227, 226, 0.38);
  --mat-checkbox-label-text-color: #e0e3e2;
  --mdc-radio-selected-focus-icon-color: #ffb4ab;
  --mdc-radio-selected-hover-icon-color: #ffb4ab;
  --mdc-radio-selected-icon-color: #ffb4ab;
  --mdc-radio-selected-pressed-icon-color: #ffb4ab;
  --mat-radio-checked-ripple-color: #ffb4ab;
}
.theme-dark .mat-accent.mtx-split-gutter {
  --mtx-split-gutter-hover-state-background-color: #ffb787;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: #502400;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #ffb787;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: #ffb787;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-hand-background-color: #ffb787;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #ffb787;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-text-color: #ffdcc7;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-background-color: #723600;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #ffb787;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: #ffdcc7;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: #93000a;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-outline-color: #ffb787;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-background-color: #723600;
}
.theme-dark .mat-accent.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: #ffdad6;
}
.theme-dark .mat-accent.ng-dropdown-panel {
  --mtx-select-option-selected-state-background-color: #723600;
}
.theme-dark .mat-warn.mtx-split-gutter {
  --mtx-split-gutter-hover-state-background-color: #ffb4ab;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: #690005;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #ffb4ab;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: #ffb4ab;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-hand-background-color: #ffb4ab;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #ffb4ab;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-text-color: #ffdad6;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-active-state-background-color: #93000a;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #ffb4ab;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: #ffdad6;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: #004f4f;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-calendar-date-today-outline-color: #ffb4ab;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-input-focus-state-background-color: #93000a;
}
.theme-dark .mat-warn.mtx-datetimepicker-content {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: #00fbfb;
}
.theme-dark .mat-warn.ng-dropdown-panel {
  --mtx-select-option-selected-state-background-color: #93000a;
}

.text-red-0 {
  color: #000000 !important;
}

.text-red-10 {
  color: #410000 !important;
}

.text-red-20 {
  color: #690100 !important;
}

.text-red-25 {
  color: #7e0100 !important;
}

.text-red-30 {
  color: #930100 !important;
}

.text-red-35 {
  color: #a90100 !important;
}

.text-red-40 {
  color: #c00100 !important;
}

.text-red-50 {
  color: #ef0000 !important;
}

.text-red-60 {
  color: #ff5540 !important;
}

.text-red-70 {
  color: #ff8a78 !important;
}

.text-red-80 {
  color: #ffb4a8 !important;
}

.text-red-90 {
  color: #ffdad4 !important;
}

.text-red-95 {
  color: #ffedea !important;
}

.text-red-98 {
  color: #fff8f6 !important;
}

.text-red-99 {
  color: #fffbff !important;
}

.text-red-100 {
  color: #ffffff !important;
}

.text-green-0 {
  color: #000000 !important;
}

.text-green-10 {
  color: #002200 !important;
}

.text-green-20 {
  color: #013a00 !important;
}

.text-green-25 {
  color: #014600 !important;
}

.text-green-30 {
  color: #015300 !important;
}

.text-green-35 {
  color: #026100 !important;
}

.text-green-40 {
  color: #026e00 !important;
}

.text-green-50 {
  color: #038b00 !important;
}

.text-green-60 {
  color: #03a800 !important;
}

.text-green-70 {
  color: #03c700 !important;
}

.text-green-80 {
  color: #02e600 !important;
}

.text-green-90 {
  color: #77ff61 !important;
}

.text-green-95 {
  color: #cbffb8 !important;
}

.text-green-98 {
  color: #edffe1 !important;
}

.text-green-99 {
  color: #f7ffee !important;
}

.text-green-100 {
  color: #ffffff !important;
}

.text-blue-0 {
  color: #000000 !important;
}

.text-blue-10 {
  color: #00006e !important;
}

.text-blue-20 {
  color: #0001ac !important;
}

.text-blue-25 {
  color: #0001cd !important;
}

.text-blue-30 {
  color: #0000ef !important;
}

.text-blue-35 {
  color: #1a21ff !important;
}

.text-blue-40 {
  color: #343dff !important;
}

.text-blue-50 {
  color: #5a64ff !important;
}

.text-blue-60 {
  color: #7c84ff !important;
}

.text-blue-70 {
  color: #9da3ff !important;
}

.text-blue-80 {
  color: #bec2ff !important;
}

.text-blue-90 {
  color: #e0e0ff !important;
}

.text-blue-95 {
  color: #f1efff !important;
}

.text-blue-98 {
  color: #fbf8ff !important;
}

.text-blue-99 {
  color: #fffbff !important;
}

.text-blue-100 {
  color: #ffffff !important;
}

.text-yellow-0 {
  color: #000000 !important;
}

.text-yellow-10 {
  color: #1d1d00 !important;
}

.text-yellow-20 {
  color: #323200 !important;
}

.text-yellow-25 {
  color: #3e3e00 !important;
}

.text-yellow-30 {
  color: #494900 !important;
}

.text-yellow-35 {
  color: #555500 !important;
}

.text-yellow-40 {
  color: #626200 !important;
}

.text-yellow-50 {
  color: #7b7b00 !important;
}

.text-yellow-60 {
  color: #969600 !important;
}

.text-yellow-70 {
  color: #b1b100 !important;
}

.text-yellow-80 {
  color: #cdcd00 !important;
}

.text-yellow-90 {
  color: #eaea00 !important;
}

.text-yellow-95 {
  color: #f9f900 !important;
}

.text-yellow-98 {
  color: #fffeac !important;
}

.text-yellow-99 {
  color: #fffbff !important;
}

.text-yellow-100 {
  color: #ffffff !important;
}

.text-cyan-0 {
  color: #000000 !important;
}

.text-cyan-10 {
  color: #002020 !important;
}

.text-cyan-20 {
  color: #003737 !important;
}

.text-cyan-25 {
  color: #004343 !important;
}

.text-cyan-30 {
  color: #004f4f !important;
}

.text-cyan-35 {
  color: #005c5c !important;
}

.text-cyan-40 {
  color: #006a6a !important;
}

.text-cyan-50 {
  color: #008585 !important;
}

.text-cyan-60 {
  color: #00a1a1 !important;
}

.text-cyan-70 {
  color: #00bebe !important;
}

.text-cyan-80 {
  color: #00dddd !important;
}

.text-cyan-90 {
  color: #00fbfb !important;
}

.text-cyan-95 {
  color: #adfffe !important;
}

.text-cyan-98 {
  color: #e2fffe !important;
}

.text-cyan-99 {
  color: #f1fffe !important;
}

.text-cyan-100 {
  color: #ffffff !important;
}

.text-magenta-0 {
  color: #000000 !important;
}

.text-magenta-10 {
  color: #380038 !important;
}

.text-magenta-20 {
  color: #5b005b !important;
}

.text-magenta-25 {
  color: #6e006e !important;
}

.text-magenta-30 {
  color: #810081 !important;
}

.text-magenta-35 {
  color: #950094 !important;
}

.text-magenta-40 {
  color: #a900a9 !important;
}

.text-magenta-50 {
  color: #d200d2 !important;
}

.text-magenta-60 {
  color: #fe00fe !important;
}

.text-magenta-70 {
  color: #ff76f6 !important;
}

.text-magenta-80 {
  color: #ffabf3 !important;
}

.text-magenta-90 {
  color: #ffd7f5 !important;
}

.text-magenta-95 {
  color: #ffebf8 !important;
}

.text-magenta-98 {
  color: #fff7f9 !important;
}

.text-magenta-99 {
  color: #fffbff !important;
}

.text-magenta-100 {
  color: #ffffff !important;
}

.text-orange-0 {
  color: #000000 !important;
}

.text-orange-10 {
  color: #311300 !important;
}

.text-orange-20 {
  color: #502400 !important;
}

.text-orange-25 {
  color: #612d00 !important;
}

.text-orange-30 {
  color: #723600 !important;
}

.text-orange-35 {
  color: #843f00 !important;
}

.text-orange-40 {
  color: #964900 !important;
}

.text-orange-50 {
  color: #bc5d00 !important;
}

.text-orange-60 {
  color: #e37100 !important;
}

.text-orange-70 {
  color: #ff8e36 !important;
}

.text-orange-80 {
  color: #ffb787 !important;
}

.text-orange-90 {
  color: #ffdcc7 !important;
}

.text-orange-95 {
  color: #ffede4 !important;
}

.text-orange-98 {
  color: #fff8f5 !important;
}

.text-orange-99 {
  color: #fffbff !important;
}

.text-orange-100 {
  color: #ffffff !important;
}

.text-chartreuse-0 {
  color: #000000 !important;
}

.text-chartreuse-10 {
  color: #0b2000 !important;
}

.text-chartreuse-20 {
  color: #173800 !important;
}

.text-chartreuse-25 {
  color: #1e4400 !important;
}

.text-chartreuse-30 {
  color: #245100 !important;
}

.text-chartreuse-35 {
  color: #2b5e00 !important;
}

.text-chartreuse-40 {
  color: #326b00 !important;
}

.text-chartreuse-50 {
  color: #418700 !important;
}

.text-chartreuse-60 {
  color: #50a400 !important;
}

.text-chartreuse-70 {
  color: #60c100 !important;
}

.text-chartreuse-80 {
  color: #70e000 !important;
}

.text-chartreuse-90 {
  color: #82ff10 !important;
}

.text-chartreuse-95 {
  color: #cfffa9 !important;
}

.text-chartreuse-98 {
  color: #eeffdc !important;
}

.text-chartreuse-99 {
  color: #f8ffeb !important;
}

.text-chartreuse-100 {
  color: #ffffff !important;
}

.text-spring-green-0 {
  color: #000000 !important;
}

.text-spring-green-10 {
  color: #00210b !important;
}

.text-spring-green-20 {
  color: #003917 !important;
}

.text-spring-green-25 {
  color: #00461e !important;
}

.text-spring-green-30 {
  color: #005225 !important;
}

.text-spring-green-35 {
  color: #00602c !important;
}

.text-spring-green-40 {
  color: #006d33 !important;
}

.text-spring-green-50 {
  color: #008942 !important;
}

.text-spring-green-60 {
  color: #00a751 !important;
}

.text-spring-green-70 {
  color: #00c561 !important;
}

.text-spring-green-80 {
  color: #00e472 !important;
}

.text-spring-green-90 {
  color: #63ff94 !important;
}

.text-spring-green-95 {
  color: #c4ffcb !important;
}

.text-spring-green-98 {
  color: #eaffe9 !important;
}

.text-spring-green-99 {
  color: #f5fff2 !important;
}

.text-spring-green-100 {
  color: #ffffff !important;
}

.text-azure-0 {
  color: #000000 !important;
}

.text-azure-10 {
  color: #001b3f !important;
}

.text-azure-20 {
  color: #002f65 !important;
}

.text-azure-25 {
  color: #003a7a !important;
}

.text-azure-30 {
  color: #00458f !important;
}

.text-azure-35 {
  color: #0050a5 !important;
}

.text-azure-40 {
  color: #005cbb !important;
}

.text-azure-50 {
  color: #0074e9 !important;
}

.text-azure-60 {
  color: #438fff !important;
}

.text-azure-70 {
  color: #7cabff !important;
}

.text-azure-80 {
  color: #abc7ff !important;
}

.text-azure-90 {
  color: #d7e3ff !important;
}

.text-azure-95 {
  color: #ecf0ff !important;
}

.text-azure-98 {
  color: #f9f9ff !important;
}

.text-azure-99 {
  color: #fdfbff !important;
}

.text-azure-100 {
  color: #ffffff !important;
}

.text-violet-0 {
  color: #000000 !important;
}

.text-violet-10 {
  color: #270057 !important;
}

.text-violet-20 {
  color: #42008a !important;
}

.text-violet-25 {
  color: #5000a4 !important;
}

.text-violet-30 {
  color: #5f00c0 !important;
}

.text-violet-35 {
  color: #6e00dc !important;
}

.text-violet-40 {
  color: #7d00fa !important;
}

.text-violet-50 {
  color: #944aff !important;
}

.text-violet-60 {
  color: #a974ff !important;
}

.text-violet-70 {
  color: #bf98ff !important;
}

.text-violet-80 {
  color: #d5baff !important;
}

.text-violet-90 {
  color: #ecdcff !important;
}

.text-violet-95 {
  color: #f7edff !important;
}

.text-violet-98 {
  color: #fef7ff !important;
}

.text-violet-99 {
  color: #fffbff !important;
}

.text-violet-100 {
  color: #ffffff !important;
}

.text-rose-0 {
  color: #000000 !important;
}

.text-rose-10 {
  color: #3f001b !important;
}

.text-rose-20 {
  color: #65002f !important;
}

.text-rose-25 {
  color: #7a003a !important;
}

.text-rose-30 {
  color: #8f0045 !important;
}

.text-rose-35 {
  color: #a40050 !important;
}

.text-rose-40 {
  color: #ba005c !important;
}

.text-rose-50 {
  color: #e80074 !important;
}

.text-rose-60 {
  color: #ff4a8e !important;
}

.text-rose-70 {
  color: #ff84a9 !important;
}

.text-rose-80 {
  color: #ffb1c5 !important;
}

.text-rose-90 {
  color: #ffd9e1 !important;
}

.text-rose-95 {
  color: #ffecef !important;
}

.text-rose-98 {
  color: #fff8f8 !important;
}

.text-rose-99 {
  color: #fffbff !important;
}

.text-rose-100 {
  color: #ffffff !important;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}

.text-light {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark {
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-0 {
  background-color: #000000 !important;
}

.bg-red-10 {
  background-color: #410000 !important;
}

.bg-red-20 {
  background-color: #690100 !important;
}

.bg-red-25 {
  background-color: #7e0100 !important;
}

.bg-red-30 {
  background-color: #930100 !important;
}

.bg-red-35 {
  background-color: #a90100 !important;
}

.bg-red-40 {
  background-color: #c00100 !important;
}

.bg-red-50 {
  background-color: #ef0000 !important;
}

.bg-red-60 {
  background-color: #ff5540 !important;
}

.bg-red-70 {
  background-color: #ff8a78 !important;
}

.bg-red-80 {
  background-color: #ffb4a8 !important;
}

.bg-red-90 {
  background-color: #ffdad4 !important;
}

.bg-red-95 {
  background-color: #ffedea !important;
}

.bg-red-98 {
  background-color: #fff8f6 !important;
}

.bg-red-99 {
  background-color: #fffbff !important;
}

.bg-red-100 {
  background-color: #ffffff !important;
}

.bg-green-0 {
  background-color: #000000 !important;
}

.bg-green-10 {
  background-color: #002200 !important;
}

.bg-green-20 {
  background-color: #013a00 !important;
}

.bg-green-25 {
  background-color: #014600 !important;
}

.bg-green-30 {
  background-color: #015300 !important;
}

.bg-green-35 {
  background-color: #026100 !important;
}

.bg-green-40 {
  background-color: #026e00 !important;
}

.bg-green-50 {
  background-color: #038b00 !important;
}

.bg-green-60 {
  background-color: #03a800 !important;
}

.bg-green-70 {
  background-color: #03c700 !important;
}

.bg-green-80 {
  background-color: #02e600 !important;
}

.bg-green-90 {
  background-color: #77ff61 !important;
}

.bg-green-95 {
  background-color: #cbffb8 !important;
}

.bg-green-98 {
  background-color: #edffe1 !important;
}

.bg-green-99 {
  background-color: #f7ffee !important;
}

.bg-green-100 {
  background-color: #ffffff !important;
}

.bg-blue-0 {
  background-color: #000000 !important;
}

.bg-blue-10 {
  background-color: #00006e !important;
}

.bg-blue-20 {
  background-color: #0001ac !important;
}

.bg-blue-25 {
  background-color: #0001cd !important;
}

.bg-blue-30 {
  background-color: #0000ef !important;
}

.bg-blue-35 {
  background-color: #1a21ff !important;
}

.bg-blue-40 {
  background-color: #343dff !important;
}

.bg-blue-50 {
  background-color: #5a64ff !important;
}

.bg-blue-60 {
  background-color: #7c84ff !important;
}

.bg-blue-70 {
  background-color: #9da3ff !important;
}

.bg-blue-80 {
  background-color: #bec2ff !important;
}

.bg-blue-90 {
  background-color: #e0e0ff !important;
}

.bg-blue-95 {
  background-color: #f1efff !important;
}

.bg-blue-98 {
  background-color: #fbf8ff !important;
}

.bg-blue-99 {
  background-color: #fffbff !important;
}

.bg-blue-100 {
  background-color: #ffffff !important;
}

.bg-yellow-0 {
  background-color: #000000 !important;
}

.bg-yellow-10 {
  background-color: #1d1d00 !important;
}

.bg-yellow-20 {
  background-color: #323200 !important;
}

.bg-yellow-25 {
  background-color: #3e3e00 !important;
}

.bg-yellow-30 {
  background-color: #494900 !important;
}

.bg-yellow-35 {
  background-color: #555500 !important;
}

.bg-yellow-40 {
  background-color: #626200 !important;
}

.bg-yellow-50 {
  background-color: #7b7b00 !important;
}

.bg-yellow-60 {
  background-color: #969600 !important;
}

.bg-yellow-70 {
  background-color: #b1b100 !important;
}

.bg-yellow-80 {
  background-color: #cdcd00 !important;
}

.bg-yellow-90 {
  background-color: #eaea00 !important;
}

.bg-yellow-95 {
  background-color: #f9f900 !important;
}

.bg-yellow-98 {
  background-color: #fffeac !important;
}

.bg-yellow-99 {
  background-color: #fffbff !important;
}

.bg-yellow-100 {
  background-color: #ffffff !important;
}

.bg-cyan-0 {
  background-color: #000000 !important;
}

.bg-cyan-10 {
  background-color: #002020 !important;
}

.bg-cyan-20 {
  background-color: #003737 !important;
}

.bg-cyan-25 {
  background-color: #004343 !important;
}

.bg-cyan-30 {
  background-color: #004f4f !important;
}

.bg-cyan-35 {
  background-color: #005c5c !important;
}

.bg-cyan-40 {
  background-color: #006a6a !important;
}

.bg-cyan-50 {
  background-color: #008585 !important;
}

.bg-cyan-60 {
  background-color: #00a1a1 !important;
}

.bg-cyan-70 {
  background-color: #00bebe !important;
}

.bg-cyan-80 {
  background-color: #00dddd !important;
}

.bg-cyan-90 {
  background-color: #00fbfb !important;
}

.bg-cyan-95 {
  background-color: #adfffe !important;
}

.bg-cyan-98 {
  background-color: #e2fffe !important;
}

.bg-cyan-99 {
  background-color: #f1fffe !important;
}

.bg-cyan-100 {
  background-color: #ffffff !important;
}

.bg-magenta-0 {
  background-color: #000000 !important;
}

.bg-magenta-10 {
  background-color: #380038 !important;
}

.bg-magenta-20 {
  background-color: #5b005b !important;
}

.bg-magenta-25 {
  background-color: #6e006e !important;
}

.bg-magenta-30 {
  background-color: #810081 !important;
}

.bg-magenta-35 {
  background-color: #950094 !important;
}

.bg-magenta-40 {
  background-color: #a900a9 !important;
}

.bg-magenta-50 {
  background-color: #d200d2 !important;
}

.bg-magenta-60 {
  background-color: #fe00fe !important;
}

.bg-magenta-70 {
  background-color: #ff76f6 !important;
}

.bg-magenta-80 {
  background-color: #ffabf3 !important;
}

.bg-magenta-90 {
  background-color: #ffd7f5 !important;
}

.bg-magenta-95 {
  background-color: #ffebf8 !important;
}

.bg-magenta-98 {
  background-color: #fff7f9 !important;
}

.bg-magenta-99 {
  background-color: #fffbff !important;
}

.bg-magenta-100 {
  background-color: #ffffff !important;
}

.bg-orange-0 {
  background-color: #000000 !important;
}

.bg-orange-10 {
  background-color: #311300 !important;
}

.bg-orange-20 {
  background-color: #502400 !important;
}

.bg-orange-25 {
  background-color: #612d00 !important;
}

.bg-orange-30 {
  background-color: #723600 !important;
}

.bg-orange-35 {
  background-color: #843f00 !important;
}

.bg-orange-40 {
  background-color: #964900 !important;
}

.bg-orange-50 {
  background-color: #bc5d00 !important;
}

.bg-orange-60 {
  background-color: #e37100 !important;
}

.bg-orange-70 {
  background-color: #ff8e36 !important;
}

.bg-orange-80 {
  background-color: #ffb787 !important;
}

.bg-orange-90 {
  background-color: #ffdcc7 !important;
}

.bg-orange-95 {
  background-color: #ffede4 !important;
}

.bg-orange-98 {
  background-color: #fff8f5 !important;
}

.bg-orange-99 {
  background-color: #fffbff !important;
}

.bg-orange-100 {
  background-color: #ffffff !important;
}

.bg-chartreuse-0 {
  background-color: #000000 !important;
}

.bg-chartreuse-10 {
  background-color: #0b2000 !important;
}

.bg-chartreuse-20 {
  background-color: #173800 !important;
}

.bg-chartreuse-25 {
  background-color: #1e4400 !important;
}

.bg-chartreuse-30 {
  background-color: #245100 !important;
}

.bg-chartreuse-35 {
  background-color: #2b5e00 !important;
}

.bg-chartreuse-40 {
  background-color: #326b00 !important;
}

.bg-chartreuse-50 {
  background-color: #418700 !important;
}

.bg-chartreuse-60 {
  background-color: #50a400 !important;
}

.bg-chartreuse-70 {
  background-color: #60c100 !important;
}

.bg-chartreuse-80 {
  background-color: #70e000 !important;
}

.bg-chartreuse-90 {
  background-color: #82ff10 !important;
}

.bg-chartreuse-95 {
  background-color: #cfffa9 !important;
}

.bg-chartreuse-98 {
  background-color: #eeffdc !important;
}

.bg-chartreuse-99 {
  background-color: #f8ffeb !important;
}

.bg-chartreuse-100 {
  background-color: #ffffff !important;
}

.bg-spring-green-0 {
  background-color: #000000 !important;
}

.bg-spring-green-10 {
  background-color: #00210b !important;
}

.bg-spring-green-20 {
  background-color: #003917 !important;
}

.bg-spring-green-25 {
  background-color: #00461e !important;
}

.bg-spring-green-30 {
  background-color: #005225 !important;
}

.bg-spring-green-35 {
  background-color: #00602c !important;
}

.bg-spring-green-40 {
  background-color: #006d33 !important;
}

.bg-spring-green-50 {
  background-color: #008942 !important;
}

.bg-spring-green-60 {
  background-color: #00a751 !important;
}

.bg-spring-green-70 {
  background-color: #00c561 !important;
}

.bg-spring-green-80 {
  background-color: #00e472 !important;
}

.bg-spring-green-90 {
  background-color: #63ff94 !important;
}

.bg-spring-green-95 {
  background-color: #c4ffcb !important;
}

.bg-spring-green-98 {
  background-color: #eaffe9 !important;
}

.bg-spring-green-99 {
  background-color: #f5fff2 !important;
}

.bg-spring-green-100 {
  background-color: #ffffff !important;
}

.bg-azure-0 {
  background-color: #000000 !important;
}

.bg-azure-10 {
  background-color: #001b3f !important;
}

.bg-azure-20 {
  background-color: #002f65 !important;
}

.bg-azure-25 {
  background-color: #003a7a !important;
}

.bg-azure-30 {
  background-color: #00458f !important;
}

.bg-azure-35 {
  background-color: #0050a5 !important;
}

.bg-azure-40 {
  background-color: #005cbb !important;
}

.bg-azure-50 {
  background-color: #0074e9 !important;
}

.bg-azure-60 {
  background-color: #438fff !important;
}

.bg-azure-70 {
  background-color: #7cabff !important;
}

.bg-azure-80 {
  background-color: #abc7ff !important;
}

.bg-azure-90 {
  background-color: #d7e3ff !important;
}

.bg-azure-95 {
  background-color: #ecf0ff !important;
}

.bg-azure-98 {
  background-color: #f9f9ff !important;
}

.bg-azure-99 {
  background-color: #fdfbff !important;
}

.bg-azure-100 {
  background-color: #ffffff !important;
}

.bg-violet-0 {
  background-color: #000000 !important;
}

.bg-violet-10 {
  background-color: #270057 !important;
}

.bg-violet-20 {
  background-color: #42008a !important;
}

.bg-violet-25 {
  background-color: #5000a4 !important;
}

.bg-violet-30 {
  background-color: #5f00c0 !important;
}

.bg-violet-35 {
  background-color: #6e00dc !important;
}

.bg-violet-40 {
  background-color: #7d00fa !important;
}

.bg-violet-50 {
  background-color: #944aff !important;
}

.bg-violet-60 {
  background-color: #a974ff !important;
}

.bg-violet-70 {
  background-color: #bf98ff !important;
}

.bg-violet-80 {
  background-color: #d5baff !important;
}

.bg-violet-90 {
  background-color: #ecdcff !important;
}

.bg-violet-95 {
  background-color: #f7edff !important;
}

.bg-violet-98 {
  background-color: #fef7ff !important;
}

.bg-violet-99 {
  background-color: #fffbff !important;
}

.bg-violet-100 {
  background-color: #ffffff !important;
}

.bg-rose-0 {
  background-color: #000000 !important;
}

.bg-rose-10 {
  background-color: #3f001b !important;
}

.bg-rose-20 {
  background-color: #65002f !important;
}

.bg-rose-25 {
  background-color: #7a003a !important;
}

.bg-rose-30 {
  background-color: #8f0045 !important;
}

.bg-rose-35 {
  background-color: #a40050 !important;
}

.bg-rose-40 {
  background-color: #ba005c !important;
}

.bg-rose-50 {
  background-color: #e80074 !important;
}

.bg-rose-60 {
  background-color: #ff4a8e !important;
}

.bg-rose-70 {
  background-color: #ff84a9 !important;
}

.bg-rose-80 {
  background-color: #ffb1c5 !important;
}

.bg-rose-90 {
  background-color: #ffd9e1 !important;
}

.bg-rose-95 {
  background-color: #ffecef !important;
}

.bg-rose-98 {
  background-color: #fff8f8 !important;
}

.bg-rose-99 {
  background-color: #fffbff !important;
}

.bg-rose-100 {
  background-color: #ffffff !important;
}

.bg-white {
  background-color: white !important;
}

.bg-black {
  background-color: black !important;
}

.bg-light {
  background-color: rgba(255, 255, 255, 0.87) !important;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.border-red-0 {
  border-color: #000000 !important;
}

.border-red-10 {
  border-color: #410000 !important;
}

.border-red-20 {
  border-color: #690100 !important;
}

.border-red-25 {
  border-color: #7e0100 !important;
}

.border-red-30 {
  border-color: #930100 !important;
}

.border-red-35 {
  border-color: #a90100 !important;
}

.border-red-40 {
  border-color: #c00100 !important;
}

.border-red-50 {
  border-color: #ef0000 !important;
}

.border-red-60 {
  border-color: #ff5540 !important;
}

.border-red-70 {
  border-color: #ff8a78 !important;
}

.border-red-80 {
  border-color: #ffb4a8 !important;
}

.border-red-90 {
  border-color: #ffdad4 !important;
}

.border-red-95 {
  border-color: #ffedea !important;
}

.border-red-98 {
  border-color: #fff8f6 !important;
}

.border-red-99 {
  border-color: #fffbff !important;
}

.border-red-100 {
  border-color: #ffffff !important;
}

.border-green-0 {
  border-color: #000000 !important;
}

.border-green-10 {
  border-color: #002200 !important;
}

.border-green-20 {
  border-color: #013a00 !important;
}

.border-green-25 {
  border-color: #014600 !important;
}

.border-green-30 {
  border-color: #015300 !important;
}

.border-green-35 {
  border-color: #026100 !important;
}

.border-green-40 {
  border-color: #026e00 !important;
}

.border-green-50 {
  border-color: #038b00 !important;
}

.border-green-60 {
  border-color: #03a800 !important;
}

.border-green-70 {
  border-color: #03c700 !important;
}

.border-green-80 {
  border-color: #02e600 !important;
}

.border-green-90 {
  border-color: #77ff61 !important;
}

.border-green-95 {
  border-color: #cbffb8 !important;
}

.border-green-98 {
  border-color: #edffe1 !important;
}

.border-green-99 {
  border-color: #f7ffee !important;
}

.border-green-100 {
  border-color: #ffffff !important;
}

.border-blue-0 {
  border-color: #000000 !important;
}

.border-blue-10 {
  border-color: #00006e !important;
}

.border-blue-20 {
  border-color: #0001ac !important;
}

.border-blue-25 {
  border-color: #0001cd !important;
}

.border-blue-30 {
  border-color: #0000ef !important;
}

.border-blue-35 {
  border-color: #1a21ff !important;
}

.border-blue-40 {
  border-color: #343dff !important;
}

.border-blue-50 {
  border-color: #5a64ff !important;
}

.border-blue-60 {
  border-color: #7c84ff !important;
}

.border-blue-70 {
  border-color: #9da3ff !important;
}

.border-blue-80 {
  border-color: #bec2ff !important;
}

.border-blue-90 {
  border-color: #e0e0ff !important;
}

.border-blue-95 {
  border-color: #f1efff !important;
}

.border-blue-98 {
  border-color: #fbf8ff !important;
}

.border-blue-99 {
  border-color: #fffbff !important;
}

.border-blue-100 {
  border-color: #ffffff !important;
}

.border-yellow-0 {
  border-color: #000000 !important;
}

.border-yellow-10 {
  border-color: #1d1d00 !important;
}

.border-yellow-20 {
  border-color: #323200 !important;
}

.border-yellow-25 {
  border-color: #3e3e00 !important;
}

.border-yellow-30 {
  border-color: #494900 !important;
}

.border-yellow-35 {
  border-color: #555500 !important;
}

.border-yellow-40 {
  border-color: #626200 !important;
}

.border-yellow-50 {
  border-color: #7b7b00 !important;
}

.border-yellow-60 {
  border-color: #969600 !important;
}

.border-yellow-70 {
  border-color: #b1b100 !important;
}

.border-yellow-80 {
  border-color: #cdcd00 !important;
}

.border-yellow-90 {
  border-color: #eaea00 !important;
}

.border-yellow-95 {
  border-color: #f9f900 !important;
}

.border-yellow-98 {
  border-color: #fffeac !important;
}

.border-yellow-99 {
  border-color: #fffbff !important;
}

.border-yellow-100 {
  border-color: #ffffff !important;
}

.border-cyan-0 {
  border-color: #000000 !important;
}

.border-cyan-10 {
  border-color: #002020 !important;
}

.border-cyan-20 {
  border-color: #003737 !important;
}

.border-cyan-25 {
  border-color: #004343 !important;
}

.border-cyan-30 {
  border-color: #004f4f !important;
}

.border-cyan-35 {
  border-color: #005c5c !important;
}

.border-cyan-40 {
  border-color: #006a6a !important;
}

.border-cyan-50 {
  border-color: #008585 !important;
}

.border-cyan-60 {
  border-color: #00a1a1 !important;
}

.border-cyan-70 {
  border-color: #00bebe !important;
}

.border-cyan-80 {
  border-color: #00dddd !important;
}

.border-cyan-90 {
  border-color: #00fbfb !important;
}

.border-cyan-95 {
  border-color: #adfffe !important;
}

.border-cyan-98 {
  border-color: #e2fffe !important;
}

.border-cyan-99 {
  border-color: #f1fffe !important;
}

.border-cyan-100 {
  border-color: #ffffff !important;
}

.border-magenta-0 {
  border-color: #000000 !important;
}

.border-magenta-10 {
  border-color: #380038 !important;
}

.border-magenta-20 {
  border-color: #5b005b !important;
}

.border-magenta-25 {
  border-color: #6e006e !important;
}

.border-magenta-30 {
  border-color: #810081 !important;
}

.border-magenta-35 {
  border-color: #950094 !important;
}

.border-magenta-40 {
  border-color: #a900a9 !important;
}

.border-magenta-50 {
  border-color: #d200d2 !important;
}

.border-magenta-60 {
  border-color: #fe00fe !important;
}

.border-magenta-70 {
  border-color: #ff76f6 !important;
}

.border-magenta-80 {
  border-color: #ffabf3 !important;
}

.border-magenta-90 {
  border-color: #ffd7f5 !important;
}

.border-magenta-95 {
  border-color: #ffebf8 !important;
}

.border-magenta-98 {
  border-color: #fff7f9 !important;
}

.border-magenta-99 {
  border-color: #fffbff !important;
}

.border-magenta-100 {
  border-color: #ffffff !important;
}

.border-orange-0 {
  border-color: #000000 !important;
}

.border-orange-10 {
  border-color: #311300 !important;
}

.border-orange-20 {
  border-color: #502400 !important;
}

.border-orange-25 {
  border-color: #612d00 !important;
}

.border-orange-30 {
  border-color: #723600 !important;
}

.border-orange-35 {
  border-color: #843f00 !important;
}

.border-orange-40 {
  border-color: #964900 !important;
}

.border-orange-50 {
  border-color: #bc5d00 !important;
}

.border-orange-60 {
  border-color: #e37100 !important;
}

.border-orange-70 {
  border-color: #ff8e36 !important;
}

.border-orange-80 {
  border-color: #ffb787 !important;
}

.border-orange-90 {
  border-color: #ffdcc7 !important;
}

.border-orange-95 {
  border-color: #ffede4 !important;
}

.border-orange-98 {
  border-color: #fff8f5 !important;
}

.border-orange-99 {
  border-color: #fffbff !important;
}

.border-orange-100 {
  border-color: #ffffff !important;
}

.border-chartreuse-0 {
  border-color: #000000 !important;
}

.border-chartreuse-10 {
  border-color: #0b2000 !important;
}

.border-chartreuse-20 {
  border-color: #173800 !important;
}

.border-chartreuse-25 {
  border-color: #1e4400 !important;
}

.border-chartreuse-30 {
  border-color: #245100 !important;
}

.border-chartreuse-35 {
  border-color: #2b5e00 !important;
}

.border-chartreuse-40 {
  border-color: #326b00 !important;
}

.border-chartreuse-50 {
  border-color: #418700 !important;
}

.border-chartreuse-60 {
  border-color: #50a400 !important;
}

.border-chartreuse-70 {
  border-color: #60c100 !important;
}

.border-chartreuse-80 {
  border-color: #70e000 !important;
}

.border-chartreuse-90 {
  border-color: #82ff10 !important;
}

.border-chartreuse-95 {
  border-color: #cfffa9 !important;
}

.border-chartreuse-98 {
  border-color: #eeffdc !important;
}

.border-chartreuse-99 {
  border-color: #f8ffeb !important;
}

.border-chartreuse-100 {
  border-color: #ffffff !important;
}

.border-spring-green-0 {
  border-color: #000000 !important;
}

.border-spring-green-10 {
  border-color: #00210b !important;
}

.border-spring-green-20 {
  border-color: #003917 !important;
}

.border-spring-green-25 {
  border-color: #00461e !important;
}

.border-spring-green-30 {
  border-color: #005225 !important;
}

.border-spring-green-35 {
  border-color: #00602c !important;
}

.border-spring-green-40 {
  border-color: #006d33 !important;
}

.border-spring-green-50 {
  border-color: #008942 !important;
}

.border-spring-green-60 {
  border-color: #00a751 !important;
}

.border-spring-green-70 {
  border-color: #00c561 !important;
}

.border-spring-green-80 {
  border-color: #00e472 !important;
}

.border-spring-green-90 {
  border-color: #63ff94 !important;
}

.border-spring-green-95 {
  border-color: #c4ffcb !important;
}

.border-spring-green-98 {
  border-color: #eaffe9 !important;
}

.border-spring-green-99 {
  border-color: #f5fff2 !important;
}

.border-spring-green-100 {
  border-color: #ffffff !important;
}

.border-azure-0 {
  border-color: #000000 !important;
}

.border-azure-10 {
  border-color: #001b3f !important;
}

.border-azure-20 {
  border-color: #002f65 !important;
}

.border-azure-25 {
  border-color: #003a7a !important;
}

.border-azure-30 {
  border-color: #00458f !important;
}

.border-azure-35 {
  border-color: #0050a5 !important;
}

.border-azure-40 {
  border-color: #005cbb !important;
}

.border-azure-50 {
  border-color: #0074e9 !important;
}

.border-azure-60 {
  border-color: #438fff !important;
}

.border-azure-70 {
  border-color: #7cabff !important;
}

.border-azure-80 {
  border-color: #abc7ff !important;
}

.border-azure-90 {
  border-color: #d7e3ff !important;
}

.border-azure-95 {
  border-color: #ecf0ff !important;
}

.border-azure-98 {
  border-color: #f9f9ff !important;
}

.border-azure-99 {
  border-color: #fdfbff !important;
}

.border-azure-100 {
  border-color: #ffffff !important;
}

.border-violet-0 {
  border-color: #000000 !important;
}

.border-violet-10 {
  border-color: #270057 !important;
}

.border-violet-20 {
  border-color: #42008a !important;
}

.border-violet-25 {
  border-color: #5000a4 !important;
}

.border-violet-30 {
  border-color: #5f00c0 !important;
}

.border-violet-35 {
  border-color: #6e00dc !important;
}

.border-violet-40 {
  border-color: #7d00fa !important;
}

.border-violet-50 {
  border-color: #944aff !important;
}

.border-violet-60 {
  border-color: #a974ff !important;
}

.border-violet-70 {
  border-color: #bf98ff !important;
}

.border-violet-80 {
  border-color: #d5baff !important;
}

.border-violet-90 {
  border-color: #ecdcff !important;
}

.border-violet-95 {
  border-color: #f7edff !important;
}

.border-violet-98 {
  border-color: #fef7ff !important;
}

.border-violet-99 {
  border-color: #fffbff !important;
}

.border-violet-100 {
  border-color: #ffffff !important;
}

.border-rose-0 {
  border-color: #000000 !important;
}

.border-rose-10 {
  border-color: #3f001b !important;
}

.border-rose-20 {
  border-color: #65002f !important;
}

.border-rose-25 {
  border-color: #7a003a !important;
}

.border-rose-30 {
  border-color: #8f0045 !important;
}

.border-rose-35 {
  border-color: #a40050 !important;
}

.border-rose-40 {
  border-color: #ba005c !important;
}

.border-rose-50 {
  border-color: #e80074 !important;
}

.border-rose-60 {
  border-color: #ff4a8e !important;
}

.border-rose-70 {
  border-color: #ff84a9 !important;
}

.border-rose-80 {
  border-color: #ffb1c5 !important;
}

.border-rose-90 {
  border-color: #ffd9e1 !important;
}

.border-rose-95 {
  border-color: #ffecef !important;
}

.border-rose-98 {
  border-color: #fff8f8 !important;
}

.border-rose-99 {
  border-color: #fffbff !important;
}

.border-rose-100 {
  border-color: #ffffff !important;
}

.border-white {
  border-color: white !important;
}

.border-black {
  border-color: black !important;
}

.border-light {
  border-color: rgba(255, 255, 255, 0.87) !important;
}

.border-dark {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-sm-12 {
    margin-left: 100%;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-lg-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1920px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-xl-12 {
    margin-left: 100%;
  }
}
.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.w-0 {
  width: 0 !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-full {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-0 {
  height: 0 !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-full {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: 0.125rem !important;
}

.m-4 {
  margin: 0.25rem !important;
}

.m-8 {
  margin: 0.5rem !important;
}

.m-12 {
  margin: 0.75rem !important;
}

.m-16 {
  margin: 1rem !important;
}

.m-24 {
  margin: 1.5rem !important;
}

.m-32 {
  margin: 2rem !important;
}

.m-48 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.m-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-x-2 {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}

.m-x-4 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.m-x-8 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.m-x-12 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.m-x-16 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.m-x-24 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.m-x-32 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.m-x-48 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-y-2 {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}

.m-y-4 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-y-8 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-y-12 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.m-y-16 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-y-24 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-y-32 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.m-y-48 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-2 {
  margin-top: 0.125rem !important;
}

.m-t-4 {
  margin-top: 0.25rem !important;
}

.m-t-8 {
  margin-top: 0.5rem !important;
}

.m-t-12 {
  margin-top: 0.75rem !important;
}

.m-t-16 {
  margin-top: 1rem !important;
}

.m-t-24 {
  margin-top: 1.5rem !important;
}

.m-t-32 {
  margin-top: 2rem !important;
}

.m-t-48 {
  margin-top: 3rem !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-2 {
  margin-right: 0.125rem !important;
}

.m-r-4 {
  margin-right: 0.25rem !important;
}

.m-r-8 {
  margin-right: 0.5rem !important;
}

.m-r-12 {
  margin-right: 0.75rem !important;
}

.m-r-16 {
  margin-right: 1rem !important;
}

.m-r-24 {
  margin-right: 1.5rem !important;
}

.m-r-32 {
  margin-right: 2rem !important;
}

.m-r-48 {
  margin-right: 3rem !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-2 {
  margin-bottom: 0.125rem !important;
}

.m-b-4 {
  margin-bottom: 0.25rem !important;
}

.m-b-8 {
  margin-bottom: 0.5rem !important;
}

.m-b-12 {
  margin-bottom: 0.75rem !important;
}

.m-b-16 {
  margin-bottom: 1rem !important;
}

.m-b-24 {
  margin-bottom: 1.5rem !important;
}

.m-b-32 {
  margin-bottom: 2rem !important;
}

.m-b-48 {
  margin-bottom: 3rem !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-2 {
  margin-left: 0.125rem !important;
}

.m-l-4 {
  margin-left: 0.25rem !important;
}

.m-l-8 {
  margin-left: 0.5rem !important;
}

.m-l-12 {
  margin-left: 0.75rem !important;
}

.m-l-16 {
  margin-left: 1rem !important;
}

.m-l-24 {
  margin-left: 1.5rem !important;
}

.m-l-32 {
  margin-left: 2rem !important;
}

.m-l-48 {
  margin-left: 3rem !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m--2 {
  margin: -0.125rem !important;
}

.m--4 {
  margin: -0.25rem !important;
}

.m--8 {
  margin: -0.5rem !important;
}

.m--12 {
  margin: -0.75rem !important;
}

.m--16 {
  margin: -1rem !important;
}

.m--24 {
  margin: -1.5rem !important;
}

.m--32 {
  margin: -2rem !important;
}

.m--48 {
  margin: -3rem !important;
}

.m-x--2 {
  margin-left: -0.125rem !important;
  margin-right: -0.125rem !important;
}

.m-x--4 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.m-x--8 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.m-x--12 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.m-x--16 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.m-x--24 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.m-x--32 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.m-x--48 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.m-y--2 {
  margin-top: -0.125rem !important;
  margin-bottom: -0.125rem !important;
}

.m-y--4 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.m-y--8 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.m-y--12 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.m-y--16 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.m-y--24 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.m-y--32 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.m-y--48 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.m-t--2 {
  margin-top: -0.125rem !important;
}

.m-t--4 {
  margin-top: -0.25rem !important;
}

.m-t--8 {
  margin-top: -0.5rem !important;
}

.m-t--12 {
  margin-top: -0.75rem !important;
}

.m-t--16 {
  margin-top: -1rem !important;
}

.m-t--24 {
  margin-top: -1.5rem !important;
}

.m-t--32 {
  margin-top: -2rem !important;
}

.m-t--48 {
  margin-top: -3rem !important;
}

.m-r--2 {
  margin-right: -0.125rem !important;
}

.m-r--4 {
  margin-right: -0.25rem !important;
}

.m-r--8 {
  margin-right: -0.5rem !important;
}

.m-r--12 {
  margin-right: -0.75rem !important;
}

.m-r--16 {
  margin-right: -1rem !important;
}

.m-r--24 {
  margin-right: -1.5rem !important;
}

.m-r--32 {
  margin-right: -2rem !important;
}

.m-r--48 {
  margin-right: -3rem !important;
}

.m-b--2 {
  margin-bottom: -0.125rem !important;
}

.m-b--4 {
  margin-bottom: -0.25rem !important;
}

.m-b--8 {
  margin-bottom: -0.5rem !important;
}

.m-b--12 {
  margin-bottom: -0.75rem !important;
}

.m-b--16 {
  margin-bottom: -1rem !important;
}

.m-b--24 {
  margin-bottom: -1.5rem !important;
}

.m-b--32 {
  margin-bottom: -2rem !important;
}

.m-b--48 {
  margin-bottom: -3rem !important;
}

.m-l--2 {
  margin-left: -0.125rem !important;
}

.m-l--4 {
  margin-left: -0.25rem !important;
}

.m-l--8 {
  margin-left: -0.5rem !important;
}

.m-l--12 {
  margin-left: -0.75rem !important;
}

.m-l--16 {
  margin-left: -1rem !important;
}

.m-l--24 {
  margin-left: -1.5rem !important;
}

.m-l--32 {
  margin-left: -2rem !important;
}

.m-l--48 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 0.125rem !important;
}

.p-4 {
  padding: 0.25rem !important;
}

.p-8 {
  padding: 0.5rem !important;
}

.p-12 {
  padding: 0.75rem !important;
}

.p-16 {
  padding: 1rem !important;
}

.p-24 {
  padding: 1.5rem !important;
}

.p-32 {
  padding: 2rem !important;
}

.p-48 {
  padding: 3rem !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-x-2 {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}

.p-x-4 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.p-x-8 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.p-x-12 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.p-x-16 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.p-x-24 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.p-x-32 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.p-x-48 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-y-2 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.p-y-4 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-y-8 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-y-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.p-y-16 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-y-24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-y-32 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.p-y-48 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-2 {
  padding-top: 0.125rem !important;
}

.p-t-4 {
  padding-top: 0.25rem !important;
}

.p-t-8 {
  padding-top: 0.5rem !important;
}

.p-t-12 {
  padding-top: 0.75rem !important;
}

.p-t-16 {
  padding-top: 1rem !important;
}

.p-t-24 {
  padding-top: 1.5rem !important;
}

.p-t-32 {
  padding-top: 2rem !important;
}

.p-t-48 {
  padding-top: 3rem !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-r-2 {
  padding-right: 0.125rem !important;
}

.p-r-4 {
  padding-right: 0.25rem !important;
}

.p-r-8 {
  padding-right: 0.5rem !important;
}

.p-r-12 {
  padding-right: 0.75rem !important;
}

.p-r-16 {
  padding-right: 1rem !important;
}

.p-r-24 {
  padding-right: 1.5rem !important;
}

.p-r-32 {
  padding-right: 2rem !important;
}

.p-r-48 {
  padding-right: 3rem !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-2 {
  padding-bottom: 0.125rem !important;
}

.p-b-4 {
  padding-bottom: 0.25rem !important;
}

.p-b-8 {
  padding-bottom: 0.5rem !important;
}

.p-b-12 {
  padding-bottom: 0.75rem !important;
}

.p-b-16 {
  padding-bottom: 1rem !important;
}

.p-b-24 {
  padding-bottom: 1.5rem !important;
}

.p-b-32 {
  padding-bottom: 2rem !important;
}

.p-b-48 {
  padding-bottom: 3rem !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-l-2 {
  padding-left: 0.125rem !important;
}

.p-l-4 {
  padding-left: 0.25rem !important;
}

.p-l-8 {
  padding-left: 0.5rem !important;
}

.p-l-12 {
  padding-left: 0.75rem !important;
}

.p-l-16 {
  padding-left: 1rem !important;
}

.p-l-24 {
  padding-left: 1.5rem !important;
}

.p-l-32 {
  padding-left: 2rem !important;
}

.p-l-48 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-2 {
  gap: 0.125rem !important;
}

.gap-4 {
  gap: 0.25rem !important;
}

.gap-8 {
  gap: 0.5rem !important;
}

.gap-12 {
  gap: 0.75rem !important;
}

.gap-16 {
  gap: 1rem !important;
}

.gap-24 {
  gap: 1.5rem !important;
}

.gap-32 {
  gap: 2rem !important;
}

.gap-48 {
  gap: 3rem !important;
}

.b-0 {
  border: 0 !important;
}

.b-1 {
  border: 1px solid var(--mat-divider-color) !important;
}

.b-2 {
  border: 2px solid var(--mat-divider-color) !important;
}

.b-4 {
  border: 4px solid var(--mat-divider-color) !important;
}

.b-8 {
  border: 8px solid var(--mat-divider-color) !important;
}

.b-t-0 {
  border-top: 0 !important;
}

.b-t-1 {
  border-top: 1px solid var(--mat-divider-color) !important;
}

.b-t-2 {
  border-top: 2px solid var(--mat-divider-color) !important;
}

.b-t-4 {
  border-top: 4px solid var(--mat-divider-color) !important;
}

.b-t-8 {
  border-top: 8px solid var(--mat-divider-color) !important;
}

.b-b-0 {
  border-bottom: 0 !important;
}

.b-b-1 {
  border-bottom: 1px solid var(--mat-divider-color) !important;
}

.b-b-2 {
  border-bottom: 2px solid var(--mat-divider-color) !important;
}

.b-b-4 {
  border-bottom: 4px solid var(--mat-divider-color) !important;
}

.b-b-8 {
  border-bottom: 8px solid var(--mat-divider-color) !important;
}

.b-l-0 {
  border-left: 0 !important;
}

.b-l-1 {
  border-left: 1px solid var(--mat-divider-color) !important;
}

.b-l-2 {
  border-left: 2px solid var(--mat-divider-color) !important;
}

.b-l-4 {
  border-left: 4px solid var(--mat-divider-color) !important;
}

.b-l-8 {
  border-left: 8px solid var(--mat-divider-color) !important;
}

.b-r-0 {
  border-right: 0 !important;
}

.b-r-1 {
  border-right: 1px solid var(--mat-divider-color) !important;
}

.b-r-2 {
  border-right: 2px solid var(--mat-divider-color) !important;
}

.b-r-4 {
  border-right: 4px solid var(--mat-divider-color) !important;
}

.b-r-8 {
  border-right: 8px solid var(--mat-divider-color) !important;
}

.b-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.b-y-1 {
  border-top: 1px solid var(--mat-divider-color) !important;
  border-bottom: 1px solid var(--mat-divider-color) !important;
}

.b-y-2 {
  border-top: 2px solid var(--mat-divider-color) !important;
  border-bottom: 2px solid var(--mat-divider-color) !important;
}

.b-y-4 {
  border-top: 4px solid var(--mat-divider-color) !important;
  border-bottom: 4px solid var(--mat-divider-color) !important;
}

.b-y-8 {
  border-top: 8px solid var(--mat-divider-color) !important;
  border-bottom: 8px solid var(--mat-divider-color) !important;
}

.b-x-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}

.b-x-1 {
  border-left: 1px solid var(--mat-divider-color) !important;
  border-right: 1px solid var(--mat-divider-color) !important;
}

.b-x-2 {
  border-left: 2px solid var(--mat-divider-color) !important;
  border-right: 2px solid var(--mat-divider-color) !important;
}

.b-x-4 {
  border-left: 4px solid var(--mat-divider-color) !important;
  border-right: 4px solid var(--mat-divider-color) !important;
}

.b-x-8 {
  border-left: 8px solid var(--mat-divider-color) !important;
  border-right: 8px solid var(--mat-divider-color) !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.border-t-1 {
  border-top-width: 1px !important;
}

.border-t-2 {
  border-top-width: 2px !important;
}

.border-t-4 {
  border-top-width: 4px !important;
}

.border-t-8 {
  border-top-width: 8px !important;
}

.border-b-0 {
  border-bottom-width: 0 !important;
}

.border-b-1 {
  border-bottom-width: 1px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-b-4 {
  border-bottom-width: 4px !important;
}

.border-b-8 {
  border-bottom-width: 8px !important;
}

.border-l-0 {
  border-left-width: 0 !important;
}

.border-l-1 {
  border-left-width: 1px !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.border-l-4 {
  border-left-width: 4px !important;
}

.border-l-8 {
  border-left-width: 8px !important;
}

.border-r-0 {
  border-right-width: 0 !important;
}

.border-r-1 {
  border-right-width: 1px !important;
}

.border-r-2 {
  border-right-width: 2px !important;
}

.border-r-4 {
  border-right-width: 4px !important;
}

.border-r-8 {
  border-right-width: 8px !important;
}

.border-y-0 {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

.border-y-1 {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.border-y-2 {
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.border-y-4 {
  border-top-width: 4px !important;
  border-bottom-width: 4px !important;
}

.border-y-8 {
  border-top-width: 8px !important;
  border-bottom-width: 8px !important;
}

.border-x-0 {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.border-x-1 {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.border-x-2 {
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.border-x-4 {
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}

.border-x-8 {
  border-left-width: 8px !important;
  border-right-width: 8px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

.border-hidden {
  border-style: hidden !important;
}

.border-none {
  border-style: none !important;
}

.border-t-solid {
  border-top-style: solid !important;
}

.border-t-dashed {
  border-top-style: dashed !important;
}

.border-t-dotted {
  border-top-style: dotted !important;
}

.border-t-double {
  border-top-style: double !important;
}

.border-t-hidden {
  border-top-style: hidden !important;
}

.border-t-none {
  border-top-style: none !important;
}

.border-b-solid {
  border-bottom-style: solid !important;
}

.border-b-dashed {
  border-bottom-style: dashed !important;
}

.border-b-dotted {
  border-bottom-style: dotted !important;
}

.border-b-double {
  border-bottom-style: double !important;
}

.border-b-hidden {
  border-bottom-style: hidden !important;
}

.border-b-none {
  border-bottom-style: none !important;
}

.border-l-solid {
  border-left-style: solid !important;
}

.border-l-dashed {
  border-left-style: dashed !important;
}

.border-l-dotted {
  border-left-style: dotted !important;
}

.border-l-double {
  border-left-style: double !important;
}

.border-l-hidden {
  border-left-style: hidden !important;
}

.border-l-none {
  border-left-style: none !important;
}

.border-r-solid {
  border-right-style: solid !important;
}

.border-r-dashed {
  border-right-style: dashed !important;
}

.border-r-dotted {
  border-right-style: dotted !important;
}

.border-r-double {
  border-right-style: double !important;
}

.border-r-hidden {
  border-right-style: hidden !important;
}

.border-r-none {
  border-right-style: none !important;
}

.border-y-solid {
  border-top-style: solid !important;
  border-bottom-style: solid !important;
}

.border-y-dashed {
  border-top-style: dashed !important;
  border-bottom-style: dashed !important;
}

.border-y-dotted {
  border-top-style: dotted !important;
  border-bottom-style: dotted !important;
}

.border-y-double {
  border-top-style: double !important;
  border-bottom-style: double !important;
}

.border-y-hidden {
  border-top-style: hidden !important;
  border-bottom-style: hidden !important;
}

.border-y-none {
  border-top-style: none !important;
  border-bottom-style: none !important;
}

.border-x-solid {
  border-left-style: solid !important;
  border-right-style: solid !important;
}

.border-x-dashed {
  border-left-style: dashed !important;
  border-right-style: dashed !important;
}

.border-x-dotted {
  border-left-style: dotted !important;
  border-right-style: dotted !important;
}

.border-x-double {
  border-left-style: double !important;
  border-right-style: double !important;
}

.border-x-hidden {
  border-left-style: hidden !important;
  border-right-style: hidden !important;
}

.border-x-none {
  border-left-style: none !important;
  border-right-style: none !important;
}

.r-0 {
  border-radius: 0 !important;
}

.r-4 {
  border-radius: 0.25rem !important;
}

.r-8 {
  border-radius: 0.5rem !important;
}

.r-12 {
  border-radius: 0.75rem !important;
}

.r-16 {
  border-radius: 1rem !important;
}

.r-full {
  border-radius: 9999px !important;
}

.r-t-l-0 {
  border-top-left-radius: 0 !important;
}

.r-t-l-4 {
  border-top-left-radius: 0.25rem !important;
}

.r-t-l-8 {
  border-top-left-radius: 0.5rem !important;
}

.r-t-l-12 {
  border-top-left-radius: 0.75rem !important;
}

.r-t-l-16 {
  border-top-left-radius: 1rem !important;
}

.r-t-l-full {
  border-top-left-radius: 9999px !important;
}

.r-t-r-0 {
  border-top-right-radius: 0 !important;
}

.r-t-r-4 {
  border-top-right-radius: 0.25rem !important;
}

.r-t-r-8 {
  border-top-right-radius: 0.5rem !important;
}

.r-t-r-12 {
  border-top-right-radius: 0.75rem !important;
}

.r-t-r-16 {
  border-top-right-radius: 1rem !important;
}

.r-t-r-full {
  border-top-right-radius: 9999px !important;
}

.r-b-r-0 {
  border-bottom-right-radius: 0 !important;
}

.r-b-r-4 {
  border-bottom-right-radius: 0.25rem !important;
}

.r-b-r-8 {
  border-bottom-right-radius: 0.5rem !important;
}

.r-b-r-12 {
  border-bottom-right-radius: 0.75rem !important;
}

.r-b-r-16 {
  border-bottom-right-radius: 1rem !important;
}

.r-b-r-full {
  border-bottom-right-radius: 9999px !important;
}

.r-b-l-0 {
  border-bottom-left-radius: 0 !important;
}

.r-b-l-4 {
  border-bottom-left-radius: 0.25rem !important;
}

.r-b-l-8 {
  border-bottom-left-radius: 0.5rem !important;
}

.r-b-l-12 {
  border-bottom-left-radius: 0.75rem !important;
}

.r-b-l-16 {
  border-bottom-left-radius: 1rem !important;
}

.r-b-l-full {
  border-bottom-left-radius: 9999px !important;
}

.r-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.r-t-4 {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.r-t-8 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.r-t-12 {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.r-t-16 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.r-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.r-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.r-b-4 {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.r-b-8 {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.r-b-12 {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.r-b-16 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.r-b-full {
  border-bottom-left-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.r-l-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.r-l-4 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.r-l-8 {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.r-l-12 {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.r-l-16 {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.r-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.r-r-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.r-r-4 {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.r-r-8 {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.r-r-12 {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.r-r-16 {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.r-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.f-s-0 {
  font-size: 0 !important;
}

.f-s-10 {
  font-size: 0.625rem !important;
}

.f-s-12 {
  font-size: 0.75rem !important;
}

.f-s-14 {
  font-size: 0.875rem !important;
}

.f-s-16 {
  font-size: 1rem !important;
}

.f-s-18 {
  font-size: 1.125rem !important;
}

.f-s-20 {
  font-size: 1.25rem !important;
}

.f-w-100 {
  font-weight: 100 !important;
}

.f-w-200 {
  font-weight: 200 !important;
}

.f-w-300 {
  font-weight: 300 !important;
}

.f-w-400 {
  font-weight: 400 !important;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-700 {
  font-weight: 700 !important;
}

.f-w-800 {
  font-weight: 800 !important;
}

.f-w-900 {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-none {
  text-decoration: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.text-reset {
  color: inherit !important;
}

.text-current {
  color: currentColor !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-copy {
  cursor: copy !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.select-none {
  user-select: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.object-contain {
  object-fit: contain !important;
}

.object-cover {
  object-fit: cover !important;
}

.object-fill {
  object-fit: fill !important;
}

.object-none {
  object-fit: none !important;
}

.object-scale-down {
  object-fit: scale-down !important;
}

.object-top {
  object-position: top !important;
}

.object-bottom {
  object-position: bottom !important;
}

.object-center {
  object-position: center !important;
}

.object-left {
  object-position: left !important;
}

.object-left-top {
  object-position: left top !important;
}

.object-left-bottom {
  object-position: left bottom !important;
}

.object-right {
  object-position: right !important;
}

.object-right-top {
  object-position: right top !important;
}

.object-right-bottom {
  object-position: right bottom !important;
}

formly-wrapper-mat-form-field .mat-mdc-form-field {
  width: 100%;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.toast-container .ngx-toastr {
  min-height: 3rem;
  padding: 0.875rem 1rem;
  color: #fff;
  background-color: #333;
  background-position: 1rem center;
  background-size: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toast-container .toast-success,
.toast-container .toast-info,
.toast-container .toast-warning,
.toast-container .toast-error {
  padding-left: 3rem;
  color: #fff;
}
.toast-container .toast-info {
  background-color: var(--toast-info-background-color);
}
.toast-container .toast-success {
  background-color: var(--toast-success-background-color);
}
.toast-container .toast-warning {
  background-color: var(--toast-warning-background-color);
}
.toast-container .toast-error {
  background-color: var(--toast-error-background-color);
}
.toast-container .toast-close-button {
  top: -0.5rem;
  right: -0.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.toast-container .toast-close-button:hover {
  color: inherit;
  opacity: 0.6;
}

.photoviewer-modal {
  position: absolute;
  z-index: 1090;
  width: 320px;
  height: 320px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.3);
  outline: none;
}
.photoviewer-modal:focus-visible {
  outline: thick solid rgba(0, 0, 0, 0.2);
}

.photoviewer-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.photoviewer-maximized {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.photoviewer-maximized.photoviewer-modal {
  border-width: 0;
  border-radius: 0;
}
.photoviewer-maximized .photoviewer-header {
  border-radius: 0;
}
.photoviewer-maximized .photoviewer-resizable-handle {
  display: none;
}

.photoviewer-button {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1;
  background: none;
  border-width: 0;
  color: inherit;
  cursor: pointer;
  outline: none;
}
.photoviewer-button:hover {
  color: #111;
}
.photoviewer-button:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.photoviewer-button svg {
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.photoviewer-header {
  position: relative;
  z-index: 2;
  height: 30px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.photoviewer-header .photoviewer-toolbar {
  float: right;
}
[dir=rtl] .photoviewer-header .photoviewer-toolbar {
  float: left;
}
.photoviewer-header .photoviewer-button {
  height: 30px;
}
.photoviewer-header .photoviewer-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.photoviewer-header .photoviewer-button-close:hover {
  color: #fff;
  background-color: #ff4545;
}

.photoviewer-title {
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  overflow: hidden;
}

.photoviewer-stage {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 40px;
  left: 0;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  background-color: #fff;
  overflow: hidden;
}

.photoviewer-image {
  position: absolute;
  display: inline-block;
  min-width: auto;
  max-width: none;
}

.photoviewer-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  text-align: center;
}

.photoviewer-align-center {
  text-align: center;
}
.photoviewer-align-center::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}
.photoviewer-align-center .photoviewer-image {
  position: static;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.photoviewer-image-error {
  display: none;
}

.photoviewer-error-msg {
  vertical-align: middle;
}

[dir=rtl] .photoviewer-button-prev,
[dir=rtl] .photoviewer-button-next {
  transform: rotate(180deg);
}

.photoviewer-resizable-handle {
  position: absolute;
  z-index: 10;
}

.photoviewer-resizable-handle-e {
  top: 0;
  right: -5px;
  bottom: 0;
  left: auto;
  width: 10px;
  cursor: e-resize;
}

.photoviewer-resizable-handle-s {
  top: auto;
  right: 0;
  bottom: -5px;
  left: 0;
  height: 10px;
  cursor: s-resize;
}

.photoviewer-resizable-handle-w {
  top: 0;
  right: auto;
  bottom: 0;
  left: -5px;
  width: 10px;
  cursor: w-resize;
}

.photoviewer-resizable-handle-n {
  top: -5px;
  right: 0;
  bottom: auto;
  left: 0;
  height: 10px;
  cursor: n-resize;
}

.photoviewer-resizable-handle-se {
  top: auto;
  right: -5px;
  bottom: -5px;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: se-resize;
}

.photoviewer-resizable-handle-sw {
  top: auto;
  right: auto;
  bottom: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: sw-resize;
}

.photoviewer-resizable-handle-nw {
  top: -5px;
  right: auto;
  bottom: auto;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: nw-resize;
}

.photoviewer-resizable-handle-ne {
  top: -5px;
  right: -5px;
  bottom: auto;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: ne-resize;
}

.photoviewer-modal:fullscreen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: black;
  border-width: 0;
  border-radius: 0;
}
.photoviewer-modal:fullscreen .photoviewer-header,
.photoviewer-modal:fullscreen .photoviewer-footer,
.photoviewer-modal:fullscreen .photoviewer-resizable-handle {
  display: none;
}
.photoviewer-modal:fullscreen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: transparent;
}

.is-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.is-grabbing {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.photoviewer-loader {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 2;
  text-align: center;
  color: #333;
}
.photoviewer-loader::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
  animation: photoviewerLoading 1s infinite linear;
}
.photoviewer-loader::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

@keyframes photoviewerLoading {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}
/** Custom Material styles */
mat-card {
  margin-bottom: var(--gutter);
}

mat-card-header + mat-card-content {
  margin-top: var(--gutter);
}

.form-field-full .mat-mdc-form-field {
  width: 100%;
}