@import "../variables";

// ==================================================
// LAYOUT
// ==================================================

.matero-container-wrap,
.matero-container {
  height: 100%;
}

.matero-content {
  position: relative;
  padding: $gutter;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - #{$toolbar-height-desktop});
  @media (max-width: 599px) {
     max-height: calc(100vh - #{$toolbar-height-mobile});
  }
}

.mat-drawer-content {
  overflow:hidden;
}

.matero-no-padding-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - #{$toolbar-height-desktop});
  @media (max-width: 599px) {
     max-height: calc(100vh - #{$toolbar-height-mobile});
  }
}

// TODO: Grid row
.matero-row {
  margin-right: -$gutter/2;
  margin-left: -$gutter/2;
}

// TODO: Grid col
.matero-col {
  padding-right: $gutter/2;
  padding-left: $gutter/2;
}

// Layout control
.matero-header-above {
  .matero-container {
    height: calc(100% - #{$toolbar-height-desktop}) !important;
  }

  .matero-sidebar-main {
    height: 100% !important;
  }
}

// Fix the init content width
.matero-content-width-fix {
  .matero-content-wrap {
    margin-left: $sidenav-width !important;

    [dir='rtl'] & {
      margin-left: auto !important;
      margin-right: $sidenav-width !important;
    }
  }
}

//
// Auth
//
.matero-auth-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #212121;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .05) 25%,
    transparent 25%,
    transparent 75%,
    rgba(255, 255, 255, .05) 75%,
    rgba(255, 255, 255, .05)
  ),
    linear-gradient(
    -45deg,
    rgba(255, 255, 255, .05) 25%,
    transparent 25%,
    transparent 75%,
    rgba(255, 255, 255, .05) 75%,
    rgba(255, 255, 255, .05)
  );
  background-size: 60px 60px;
}
