@use '@angular/material' as mat;

@include mat.core();

// ==================================================
// REBOOT
// ==================================================

$font-family-sans-serif: Roboto, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
$link-color: map-get(mat.$m2-blue-palette, A400) !default;
$link-decoration: none !default;
$code-color: map-get(mat.$m2-red-palette, A400);
$font-size-base: 1rem !default;
$small-font-size: .875em !default;
$code-font-size: $small-font-size !default;
$boder-color: rgba(0, 0, 0, .12);
$font-family-monospace: 'Roboto Mono', monospace !default;

html,
body {
  height: 100%;
  min-height: 100%;
  line-height: normal;
  font-family: $font-family-base;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
}

body {
  position: relative;
  margin: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

code {
  font-size: $code-font-size;
  color: $code-color;
  word-break: break-word;
}
